import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';

import ServiceTypeSelectionComponent from './ServiceTypeSelectionComponent';
import BarLineChart from '../../common/chart/BarLineChart';
import { AppContext, CONSTANT } from '../../../AppContext';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
  loadingIcon: {
    color: theme.palette.gray3,
  },
});

class WastageOverTimeLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Get chart title based on the selected group by
   */
  getWastageTitleForLineChart(classes) {
    const { selectedGroupBy } = this.context;
    const { t } = this.props;
    let chartTitle = '';

    if (selectedGroupBy === CONSTANT.groupByDay) {
      chartTitle = t('breakdown.wastageByDayHeader');
    } else if (selectedGroupBy === CONSTANT.groupByWeek) {
      chartTitle = t('breakdown.wastageByWeekHeader');
    } else {
      chartTitle = t('breakdown.wastageByMonthHeader');
    }

    return (
      <Typography className={`wastageByTitle ${classes.title}`} variant="h1">
        {chartTitle}
      </Typography>
    );
  }

  render() {
    const { currency } = this.context;
    const {
      classes,
      toggleValueForWeightCost,
      selectedLevelWasteAnalysisForHighlights,
      mapPastelColorByLocationName,
      isWastageOverTimeLineChartLoading,
      arrAllServiceType,
      arrSelectedServiceType,
      updateSelectedServiceTypes,
      t,
    } = this.props;

    const { wasteAnalysisForBarLineChart } = selectedLevelWasteAnalysisForHighlights;
    return (
      wasteAnalysisForBarLineChart && (
        <Box id="wastage-over-time-chart">
          <Grid container justifyContent="center" alignItems="center">
            {this.getWastageTitleForLineChart(classes)}
          </Grid>
          <ServiceTypeSelectionComponent
            arrAllServiceType={arrAllServiceType}
            arrSelectedServiceType={arrSelectedServiceType}
            updateSelectedServiceTypes={updateSelectedServiceTypes}
          />
          {isWastageOverTimeLineChartLoading ? (
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{
                height:
                  toggleValueForWeightCost === 'weight'
                    ? 2 *
                      (CONSTANT.defaultBarLineChartHeight +
                        CONSTANT.defaultBarLineChartLegendHeight)
                    : CONSTANT.defaultBarLineChartHeight + CONSTANT.defaultBarLineChartLegendHeight,
              }}
            >
              <CircularProgress className={classes.loadingIcon} size="1.3rem" />
            </Grid>
          ) : (
            <>
              <BarLineChart
                xAxisData={wasteAnalysisForBarLineChart.arrDate}
                yAxisBarData={
                  toggleValueForWeightCost === 'weight'
                    ? wasteAnalysisForBarLineChart.arrArrWeight
                    : wasteAnalysisForBarLineChart.arrArrCost
                }
                yAxisTotalBarData={
                  toggleValueForWeightCost === 'weight'
                    ? wasteAnalysisForBarLineChart.arrTotalWeight
                    : wasteAnalysisForBarLineChart.arrTotalCost
                }
                yAxisLineData={wasteAnalysisForBarLineChart.arrCover}
                yAxisBarLabel={
                  toggleValueForWeightCost === 'weight'
                    ? `${t('chart.weightAxisLabel')} (KG)`
                    : `${t('chart.costAxisLabel')} (${currency})`
                }
                yAxisLineLabel={t('chart.coverAxisLabel')}
                barLabelData={
                  toggleValueForWeightCost === 'weight'
                    ? t('chart.weightText')
                    : t('chart.costText')
                }
                lineLabelData={t('chart.coverText')}
                legendData={wasteAnalysisForBarLineChart.arrLegend}
                mapPastelColorByLocationName={mapPastelColorByLocationName}
              />
              {toggleValueForWeightCost === 'weight' && (
                <BarLineChart
                  xAxisData={wasteAnalysisForBarLineChart.arrDate}
                  yAxisBarData={wasteAnalysisForBarLineChart.arrArrWastePerCover}
                  yAxisTotalBarData={wasteAnalysisForBarLineChart.arrTotalWastePerCover}
                  yAxisLineData={wasteAnalysisForBarLineChart.arrCover}
                  yAxisBarLabel={`${t('chart.wastePerCoverAxisLabel')} (grams)`}
                  yAxisLineLabel={t('chart.coverAxisLabel')}
                  barLabelData={t('chart.wastePerCoverText')}
                  lineLabelData={t('chart.coverText')}
                  legendData={wasteAnalysisForBarLineChart.arrLegendExcludingProduction}
                  mapPastelColorByLocationName={mapPastelColorByLocationName}
                />
              )}
            </>
          )}
        </Box>
      )
    );
  }
}

WastageOverTimeLineChart.contextType = AppContext;

export default withTranslation()(withStyles(styles)(WastageOverTimeLineChart));
