import {
  differenceInCalendarDays,
  differenceInCalendarMonths,
  endOfMonth,
  format,
  startOfMonth,
  sub,
} from 'date-fns';
import { DateTime } from 'luxon';
import { CONSTANT } from '../../AppContext';

/**
 * This function as created to convert large numbers (with no. of digits greater than 6) into an abbreviated form with 2dp.
 * This is to ensure that numbers with number of digits larger than 6 do not end up creating a extremely long
 * and hard to read number that is not user friendly in terms of UX for the end user.
 * If value passed in is '-' or 'NA', returns back the value itself.
 * Look at "arrAbbreviatedSymbolReference" to see abbreviations created for different numbers of digits.
 * @param {number} value - Number to be formatted
 * @returns {string} - Number in string form that has been formatted accordingly
 */
const convertToAbbreviatedNumber = (value) => {
  if (value === '-' || value === 'NA') {
    return value;
  }

  const arrAbbreviatedSymbolReference = [
    { value: 1e9, divisor: 1e9, symbol: 'B' },
    { value: 1e6, divisor: 1e6, symbol: 'M' },
    { value: 1e3, divisor: 1e3, symbol: '' },
    { value: 0, divisor: 1, symbol: '' },
  ];
  // Number between 1000 and 1000000 is handled differently as every 3 digits before the decimal point is
  // to be separated by a comma
  const absoluteValue = Math.abs(value);
  const matchedAbbreviatedSymbolReference = arrAbbreviatedSymbolReference.find(
    (abbreviatedSymbolReference) => absoluteValue >= abbreviatedSymbolReference.value
  );
  // No abbreviation for number between 1000 and 1000000
  // Currently, use round off instead of truncate to align better with MC monthly report
  if (matchedAbbreviatedSymbolReference.divisor === 1e3) {
    return Math.round(value).toLocaleString('en-US');
  }
  if (matchedAbbreviatedSymbolReference.divisor === 1) {
    return parseFloat(value.toFixed(2)).toString();
  }
  return matchedAbbreviatedSymbolReference
    ? (value / matchedAbbreviatedSymbolReference.divisor).toFixed(2) +
        matchedAbbreviatedSymbolReference.symbol
    : '0';
};

/**
 * This function checks if the station to be added passes requirement for allowed characters and format
 * The allowable characters are only alphabets and spaces
 * If a non-allowable character exists, error message is updated and loop is broken to return isValid as false and the error message
 * If the station is valid, an updated station string is created removing extra spaces, and proper casing the string
 * @param {string} station - Takes in input station and checks if naming convention matches with standards
 * @returns {Array<boolean, string>} - Returns isValid and if string is valid, return updatedStation modified to the right format, else if string is
 * not valid, return errorMessage string
 */
const checkIsStationValid = (station) => {
  let errorMessage;
  let updatedStation = '';
  let isValid = true;
  let previousCharacter;
  for (let stringIndex = 0; stringIndex < station.length; stringIndex += 1) {
    const character = station[stringIndex];
    // Test if allowable characters only
    if (!/[a-zA-Z\s]/.test(character)) {
      isValid = false;
      errorMessage = `Only alphabets are allowed`;
      break;
    }

    // Remove extra spaces
    if (!(character === ' ' && (!previousCharacter || previousCharacter === ' '))) {
      // If previous character is a space or is the first letter of the string, uppercase it. Else, lowercase it.
      if (previousCharacter === ' ' || !previousCharacter) {
        updatedStation += character.toUpperCase();
      } else {
        updatedStation += character.toLowerCase();
      }
    }
    previousCharacter = character;
  }

  return [isValid, isValid ? updatedStation : errorMessage];
};

/**
 * This function checks if the menuItemName to be added passes requirement for allowed characters and format
 * While this function was initially written in pure regex, there was difficulty in forming one with the below checks, hence a custom function is created
 * This function checks for
 * 1. Valid characters (only alphabets, dashes, parentheses, single quotation and double quotations are allowed)
 * 2. Same symbols cannot appear in succession
 * 3. Must contain at least 1 alphabet
 * 4. Balanced parentheses and double quotation marks
 * If the menuItemName is valid, an updated menuItemName string is created removing extra spaces, and proper casing the string
 * @param {string} menuItemName - Takes in input menuItemName and checks if naming convention matches with standards
 * @returns {Array<boolean, string>} - Returns isValid and if string is valid, return updatedMenuItemName modified to the right format, else if string is
 * not valid, return errorMessage string
 */
const checkIsMenuItemNameValid = (menuItemName) => {
  const stackSymbol = [];
  let prevNonSymbolCharacter;
  let updatedMenuItemName = '';
  let isValid = true;
  let errorMessage;
  for (let stringIndex = 0; stringIndex < menuItemName.length; stringIndex += 1) {
    const character = menuItemName[stringIndex];
    const prevUpdatedCharacter = updatedMenuItemName.charAt(updatedMenuItemName.length - 1);
    const nextCharacter =
      stringIndex === menuItemName.length - 1 ? null : menuItemName[stringIndex + 1];
    // Test if allowable characters only
    if (!/[a-zA-Z'()"-\s]/.test(character)) {
      isValid = false;
      errorMessage = `Only alphabets, parentheses, dashes and quotations are allowed`;
      break;
    }

    // Test if last character of updated string is same as current character, if it is a symbol
    if (/['()"-]/.test(character) && updatedMenuItemName && prevUpdatedCharacter === character) {
      isValid = false;
      errorMessage = 'Same symbols cannot appear in succession';
      break;
    }

    // Remove extra spaces
    if (!(character === ' ' && (!prevNonSymbolCharacter || prevNonSymbolCharacter === ' '))) {
      // Push and pop parentheses and double quotation marks from stackSymbol
      if (character === '(' || (character === '"' && stackSymbol[stackSymbol.length - 1] !== '"')) {
        stackSymbol.push(character);
      } else if (
        (character === ')' && stackSymbol[stackSymbol.length - 1] === '(') ||
        (character === '"' && stackSymbol[stackSymbol.length - 1] === '"')
      ) {
        stackSymbol.pop();
      } else if (
        (character === ')' && stackSymbol[stackSymbol.length - 1] !== '(') ||
        (character === '"' && stackSymbol[stackSymbol.length - 1] !== '"')
      ) {
        isValid = false;
        errorMessage =
          errorMessage || `Please ensure parentheses () and double quotations "" are balanced`;
        break;
      }

      // The following conditions determine if the current character is to be in uppercase:
      // 1. If the previous character is a space
      // 2. If the previous character does not exist (i.e. start of the string)
      // 3. If the previous character is a symbol, except single quotation
      // 4. If the previous character is a single quotation and (current character is not 's' or if it is 's' the following character
      // should not be a space or end of string)
      // Otherwise, current character is to be in lowercase
      if (
        prevNonSymbolCharacter === ' ' ||
        !prevNonSymbolCharacter ||
        /[()"-]/.test(prevUpdatedCharacter) ||
        (prevUpdatedCharacter === "'" &&
          (character !== 's' ||
            (character === 's' && nextCharacter !== ' ' && nextCharacter !== null)))
      ) {
        updatedMenuItemName += character.toUpperCase();
      } else {
        updatedMenuItemName += character.toLowerCase();
      }

      // Ignore double quotation and open parenthesis for prevNonSymbolCharacter
      if (!/['()"-]/.test(character)) {
        prevNonSymbolCharacter = character;
      }
    }
  }
  // If prevNonSymbolCharacter does not exist, there is no alphabet present
  if (!prevNonSymbolCharacter) {
    isValid = false;
    errorMessage = errorMessage || `Must contain at least 1 alphabet`;
  }

  // If stackSymbol is not empty, parentheses and double quotations are not balanced
  if (stackSymbol.length !== 0) {
    isValid = false;
    errorMessage =
      errorMessage || `Please ensure parentheses () and double quotations "" are balanced`;
  }

  return [isValid, isValid ? updatedMenuItemName : errorMessage];
};

/**
 * This function checks if the costPerKilogram to be added is a valid positive number.
 * @param {string} costPerKilogram - Takes in input costPerKilogram and checks if is a valid positive number
 * @returns {boolean} - Returns true if costPerKilogram is a valid positive number, returns false if it is not
 */
const checkIsCostPerKilogramValid = (costPerKilogram) => {
  const costPerKilogramInNumberFormat = Number(costPerKilogram);
  return !Number.isNaN(costPerKilogramInNumberFormat) && costPerKilogramInNumberFormat > 0;
};

/**
 * Format value of waste per cover, which can be '-', 'NA' or a number. Format to a rounded off whole number string if it is a number.
 * @param {number | string} value - Value of waste per cover to be formatted. It can be '-', 'NA' or a number, format to a rounded off whole number string if it is a number
 * @returns {string} Value - '-', 'NA' or a rounded off whole number in string
 */
const formatWastePerCoverValue = (value) => {
  if (value !== '-' && value !== 'NA') {
    return Math.round(value);
  }
  return value;
};

/**
 * Get the date range as a string that is used for comparison with the current set of data
 * @param {string} selectedStartDate - Selected start date in 'YYYY-MM-DD' format
 * @param {string} selectedEndDate - Selected end date in 'YYYY-MM-DD' format
 * @param {string} selectedGroupBy - Selected group by - 'groupByDay', 'groupByWeek' or 'groupByMonth'
 */
const getPreviousDateRangeString = (selectedStartDate, selectedEndDate, selectedGroupBy) => {
  let previousStartDate;
  let previousEndDate;
  const selectedStartDateInDateFormat = new Date(selectedStartDate);
  const selectedEndDateInDateFormat = new Date(selectedEndDate);
  if (selectedGroupBy === CONSTANT.groupByMonth) {
    const monthsDiff =
      differenceInCalendarMonths(selectedEndDateInDateFormat, selectedStartDateInDateFormat) + 1;
    previousStartDate = startOfMonth(
      sub(selectedStartDateInDateFormat, {
        months: monthsDiff,
      })
    );
    previousEndDate = endOfMonth(
      sub(selectedEndDateInDateFormat, {
        months: monthsDiff,
      })
    );
  } else {
    const daysDiff =
      differenceInCalendarDays(selectedEndDateInDateFormat, selectedStartDateInDateFormat) + 1;
    previousStartDate = sub(selectedStartDateInDateFormat, {
      days: daysDiff,
    });

    previousEndDate = sub(selectedEndDateInDateFormat, {
      days: daysDiff,
    });
  }

  return ` ${format(previousStartDate, 'dd-MM-yyyy')} — ${format(previousEndDate, 'dd-MM-yyyy')}`;
};

/**
 * This function finds the last day luxon dateTime to display waste analysis, according to the value indicated by the process.env.REACT_APP_WEEKDAY_TIME and process.env.REACT_APP_TIME_ZONE.
 * If both environment values are valid, the last day luxon DateTime to display waste analysis will be either the last day of the previous week or previous previous week.
 * Else, the last day luxon dateTime to display waste analysis will be today.
 * @returns lastDayLuxonToDisplayWasteAnalysis - Luxon dateTime object that indicates the last day to display the waste analysis
 */
const getLastDayLuxonToDisplayWasteAnalysis = () => {
  const todayLuxon = DateTime.now();
  let lastDayLuxonToDisplayWasteAnalysis;
  if (process.env.REACT_APP_WEEKDAY_TIME && process.env.REACT_APP_TIME_ZONE) {
    const dayBoundaryLuxon = DateTime.fromFormat(process.env.REACT_APP_WEEKDAY_TIME, 'cccc H:mm', {
      zone: process.env.REACT_APP_TIME_ZONE,
    });
    if (todayLuxon >= dayBoundaryLuxon) {
      lastDayLuxonToDisplayWasteAnalysis = todayLuxon.minus({ weeks: 1 }).endOf('week');
    } else {
      lastDayLuxonToDisplayWasteAnalysis = todayLuxon.minus({ weeks: 2 }).endOf('week');
    }
  } else {
    lastDayLuxonToDisplayWasteAnalysis = todayLuxon;
  }
  return lastDayLuxonToDisplayWasteAnalysis;
};

export default {
  checkIsCostPerKilogramValid,
  checkIsMenuItemNameValid,
  checkIsStationValid,
  convertToAbbreviatedNumber,
  formatWastePerCoverValue,
  getLastDayLuxonToDisplayWasteAnalysis,
  getPreviousDateRangeString,
};
