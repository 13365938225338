import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { Box, Tooltip, Typography } from '@material-ui/core';

import CommonFunctions from './CommonFunctions';
import { AppContext } from '../../AppContext';

const { convertToAbbreviatedNumber } = CommonFunctions;

const styles = () => ({
  tooltipContainer: {
    maxWidth: 'none',
    fontSize: '10px',
    backgroundColor: 'rgba(97, 97, 97, 0.96)',
    boxShadow: '2px 2px 5px 1px rgba(0,0,0,0.5)',
  },
  tooltipText: {
    fontSize: '16px',
    lineHeight: '22px',
    textAlign: 'center',
  },
  tooltipImageContainer: {
    backgroundColor: 'rgba(97, 97, 97)',
    padding: '10px',
    margin: '6px',
    borderRadius: '8px',
    color: 'rgba(160, 160, 160)',
  },
  wasteImage: {
    maxWidth: '100%',
  },
});

const TABLE_LABEL = {
  menuItemName: 'menuItemName',
  numberOfThrows: 'numberOfThrows',
  arrWasteImageDetailByWeight: 'arrWasteImageDetailByWeight',
  arrWasteImageDetailByCost: 'arrWasteImageDetailByCost',
  weight: 'weight',
  cost: 'cost',
};

class MenuItemImageToolTip extends PureComponent {
  render() {
    const {
      classes,
      tableRow,
      toggleValueForWeightCost,
      selectedStartDate,
      selectedEndDate,
      cellContent,
      t,
    } = this.props;
    const { currency } = this.context;

    return (
      <Tooltip
        arrow
        interactive
        placement="top-start"
        onClick={(e) => {
          e.stopPropagation();
        }}
        classes={{ tooltip: classes.tooltipContainer }}
        title={
          <Box onClick={(e) => e.stopPropagation()}>
            <Box
              display="flex"
              gridGap={2}
              alignItems="center"
              sx={{
                flexDirection: {
                  xs: 'column',
                  md: 'row',
                },
              }}
            >
              <Box
                width={140}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                className={classes.tooltipText}
              >
                <span>
                  <b>{tableRow[TABLE_LABEL.menuItemName]}</b>
                </span>
                <span>{`${convertToAbbreviatedNumber(tableRow[TABLE_LABEL.weight])} KG`}</span>
                <span>{`${convertToAbbreviatedNumber(
                  tableRow[TABLE_LABEL.cost]
                )} ${currency}`}</span>
                <span>{`${convertToAbbreviatedNumber(tableRow[TABLE_LABEL.numberOfThrows])} ${t(
                  'menuItemImage.throwsHeader'
                )}`}</span>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: '220px',
                    md: '768px',
                  },
                  height: {
                    xs: '175px',
                    md: '153px',
                  },
                  overflow: 'scroll',
                }}
                display="flex"
                className={classes.tooltipImageContainer}
              >
                {tableRow[
                  toggleValueForWeightCost === TABLE_LABEL.weight
                    ? TABLE_LABEL.arrWasteImageDetailByWeight
                    : TABLE_LABEL.arrWasteImageDetailByCost
                ].length > 0 ? (
                  <Box
                    display="flex"
                    sx={{
                      flexDirection: {
                        xs: 'column',
                        md: 'row',
                      },
                    }}
                    gridGap={7}
                  >
                    {tableRow[
                      toggleValueForWeightCost === TABLE_LABEL.weight
                        ? TABLE_LABEL.arrWasteImageDetailByWeight
                        : TABLE_LABEL.arrWasteImageDetailByCost
                    ]?.map((image) => {
                      return (
                        <Box
                          sx={{
                            width: {
                              md: '25%',
                            },
                          }}
                          key={image.wasteId}
                        >
                          <img src={image.signedUrl} alt="" className={classes.wasteImage} />
                          <Box
                            display="flex"
                            width="100%"
                            justifyContent="center"
                            gridGap={6}
                            color="white"
                          >
                            <span>{image.date}</span>
                            {toggleValueForWeightCost === TABLE_LABEL.weight ? (
                              <span>{`${convertToAbbreviatedNumber(
                                image.weightInKilogram
                              )} KG`}</span>
                            ) : (
                              <span>{`${convertToAbbreviatedNumber(image.cost)} ${currency}`}</span>
                            )}
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                ) : (
                  <Box display="flex" alignItems="center" flexGrow={1} justifyContent="center">
                    <Typography align="center" variant="body2">
                      {t('menuItemImage.noPreviewForCurrentPeriod')}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
            <Box display="flex" justifyContent="end" px={1} py={0}>
              {t('menuItemImage.footnote', { selectedStartDate, selectedEndDate })}
            </Box>
          </Box>
        }
      >
        <Box>{cellContent}</Box>
      </Tooltip>
    );
  }
}

MenuItemImageToolTip.contextType = AppContext;

export default withTranslation()(withStyles(styles)(MenuItemImageToolTip));
