import React, { useContext, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Box, Toolbar, Grid } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { AppContext } from '../../AppContext';
import Logo from '../../imgs/logo.png';

const HEADER_HEIGHT = 57;
const styles = (theme) => ({
  /**
   * App bar: menu icon, title logo, notifications
   */
  root: {
    flexGrow: 1,
    backgroundImage: theme.palette.primaryGradient,
    height: '100%',
  },
  appBar: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      backgroundImage: theme.palette.primaryGradient,
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      backgroundImage: theme.palette.primaryGradient,
      paddingLeft: theme.main.paddingLeftRight,
      paddingRight: theme.main.paddingLeftRight,
    },
  },
  toolBar: {
    // Force height of app bar to be exactly {HEADER_HEIGHT}px
    minHeight: `${HEADER_HEIGHT}px`,
    maxHeight: `${HEADER_HEIGHT}px`,
  },
  logoStyle: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      maxHeight: '45px',
      verticalAlign: 'middle',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md - 1)]: {
      maxHeight: '45px',
      position: 'absolute',
      top: '-22px',
      left: '-59px',
    },
  },
  logoItem: {
    position: 'relative',
  },
});

const CallbackPage = ({ classes, history, t }) => {
  const { isAuthenticated, isLoading, user } = useAuth0();
  const context = useContext(AppContext);

  useEffect(() => {
    const { renderLoaderAnimation } = context;
    renderLoaderAnimation(true);
  }, []);

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      const { openSnackbar } = context;

      openSnackbar(t('home.loginSuccessSnackbarText'), 'success');
      if (!user.isAdmin) {
        history.push('/');
      } else {
        history.push('/impersonator');
      }
    }
  }, [isLoading]);

  return (
    <div>
      <Box className={`${classes.root}`}>
        <AppBar position="relative" className={classes.appBar} elevation={0}>
          <Toolbar classes={{ regular: classes.toolBar }}>
            <Grid item className={classes.logoItem}>
              <img src={Logo} className={classes.logoStyle} alt="Lumitics" />
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </div>
  );
};

export default withTranslation()(withRouter(withTheme(withStyles(styles)(CallbackPage))));
