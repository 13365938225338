import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';

import CommonFunctions from '../../common/CommonFunctions';
import KeyOverviewTable from '../../common/KeyOverviewTable';
import { AppContext, CONSTANT } from '../../../AppContext';

const { convertToAbbreviatedNumber } = CommonFunctions;
class ReductionShowMore extends PureComponent {
  render() {
    const {
      classes,
      formatValue,
      imageIcon,
      isTooltipOpened,
      showMoreValue,
      SHOW_MORE_VALUE,
      onClickAwayTooltip,
      onClickInfoIcon,
      onCloseTooltip,
      wasteReductionAnalysisForKeyOverview,
      t,
    } = this.props;
    const { currency } = this.context;

    let titleCategory = '';
    let title = '';
    let tooltipText;
    let unit = '';
    if (showMoreValue === SHOW_MORE_VALUE.reductionInWeight) {
      titleCategory = t('keyOverview.weightHeader');
      title = t('keyOverview.changeInWeightHeader');
      tooltipText = <Trans i18nKey="keyOverview.changeInWeightTooltipText" />;
      unit = CONSTANT.weightKGUnit;
    } else if (showMoreValue === SHOW_MORE_VALUE.reductionInCost) {
      titleCategory = t('keyOverview.costHeader');
      title = t('keyOverview.changeInCostHeader');
      tooltipText = <Trans i18nKey="keyOverview.changeInCostTooltipText" />;
      unit = currency;
    } else {
      titleCategory = t('keyOverview.wastePerCover');
      title = t('keyOverview.changeInWastePerCoverHeader');
      tooltipText = <Trans i18nKey="keyOverview.changeInWastePerCoverTooltipText" />;
      unit = CONSTANT.weightGUnit;
    }

    return (
      <Grid container alignItems="center" spacing={2} className={classes.showMoreCard}>
        <Grid container direction="row" spacing={1} alignItems="baseline">
          <Grid item>
            <Typography variant="h1">{title}</Typography>
          </Grid>
          <Grid item>
            <ClickAwayListener onClickAway={onClickAwayTooltip}>
              <Tooltip
                arrow
                onClose={onCloseTooltip}
                open={isTooltipOpened}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Typography variant="caption" className={classes.tooltiptext}>
                    {tooltipText}
                  </Typography>
                }
              >
                <InfoIcon onClick={onClickInfoIcon} className={classes.tooltipIcon} />
              </Tooltip>
            </ClickAwayListener>
          </Grid>
        </Grid>
        <Grid item>
          <img src={imageIcon} className={classes.imageWeightCost} alt="reductionIcon" />
          <>
            <Typography variant="subtitle1" display="inline" className="reductionValue">
              {wasteReductionAnalysisForKeyOverview.valueForCompany === '-' && <span> </span>}
              {formatValue(showMoreValue, wasteReductionAnalysisForKeyOverview.valueForCompany)}
            </Typography>
            {wasteReductionAnalysisForKeyOverview.valueForCompany !== '-' && (
              <Typography
                variant="h6"
                display="inline"
                className={`reductionValueUnit ${classes.totalUnitGridItem}`}
              >
                {unit}
              </Typography>
            )}
          </>
        </Grid>
        <Grid item>
          <Typography
            className={`reductionPercentage ${
              wasteReductionAnalysisForKeyOverview.valueInPercentageForCompany >= 0
                ? classes.trendIncrease
                : classes.trendDecrease
            }`}
            variant="h5"
          >
            {`${wasteReductionAnalysisForKeyOverview.valueInPercentageForCompany > 0 ? '+' : ''}${
              wasteReductionAnalysisForKeyOverview.valueInPercentageForCompany === '-'
                ? '-'
                : convertToAbbreviatedNumber(
                    wasteReductionAnalysisForKeyOverview.valueInPercentageForCompany
                  )
            }`}
            <Typography variant="body2" display="inline" className="reductionPercentageUnit">
              %
            </Typography>
          </Typography>
        </Grid>
        <Grid item>
          <KeyOverviewTable
            key={`reduction_${showMoreValue}_${JSON.stringify(
              wasteReductionAnalysisForKeyOverview
            )}`}
            showMoreValue={showMoreValue}
            formatValue={formatValue}
            tableHeaderCategory={`${titleCategory} (${unit})`}
            arrTableRow={wasteReductionAnalysisForKeyOverview.arrWasteReductionAnalysisForBreakdown}
          />
        </Grid>
      </Grid>
    );
  }
}
ReductionShowMore.contextType = AppContext;

export default withTranslation()(ReductionShowMore);
