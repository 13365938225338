import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import CustomTable from '../../common/CustomTable';

const styles = (theme) => ({
  componentTitle: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
});

class WastageByLevelTable extends PureComponent {
  render() {
    const {
      classes,
      toggleValueForWeightCost,
      selectedLevelWasteAnalysisForHighlights,
      isCompanyUser,
      arrLevelName,
      t,
    } = this.props;

    const levelCategory = isCompanyUser
      ? t('breakdown.locationHeader')
      : t('breakdown.serviceHeader');
    const weightCostHeader =
      toggleValueForWeightCost === 'weight'
        ? t('breakdown.weightHeader').toUpperCase()
        : t('breakdown.costHeader').toUpperCase();
    const arrTableHeader = [
      { id: 'parentName', label: levelCategory },
      { id: toggleValueForWeightCost, label: weightCostHeader },
    ];

    let arrTableRow = [];
    if (selectedLevelWasteAnalysisForHighlights) {
      if (toggleValueForWeightCost === 'weight') {
        arrTableRow = selectedLevelWasteAnalysisForHighlights.wasteAnalysisForLevelTable.byWeight;
      } else {
        arrTableRow = selectedLevelWasteAnalysisForHighlights.wasteAnalysisForLevelTable.byCost;
      }
    }

    const arrTableAlignment = ['left', 'right'];
    const arrTableWrap = [true, false];
    const arrIdKey = ['name'];

    return (
      <Box className="wastageByLevelTable" id="wastage-by-level-table">
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Typography className={classes.componentTitle} display="inline" variant="h1">
            {isCompanyUser
              ? t('breakdown.wastageByLocationHeader')
              : t('breakdown.wastageByServiceHeader')}
          </Typography>
        </Grid>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          arrIdKey={arrIdKey}
          arrLevelName={arrLevelName}
          isSortFirstRowByAlphabeticalOrder={isCompanyUser}
        />
      </Box>
    );
  }
}

export default withTranslation()(withStyles(styles)(WastageByLevelTable));
