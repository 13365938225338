import React, { PureComponent } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Trans, withTranslation } from 'react-i18next';
import Media from 'react-media';
import { Link } from 'react-router-dom';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';

import AddToWatchlistModal from './AddToWatchlistModal';
import WatchlistRow from './WatchlistRow';
import ConfirmationDialog from '../../common/ConfirmationDialog';
// eslint-disable-next-line no-unused-vars
import typedefs from '../../typedefs';
import { AppContext, CONSTANT } from '../../../AppContext';

/**
 * Custom styles for the Watchlist component.
 */
const styles = (theme) => ({
  // Styling related to the general Watchlist card
  root: {
    margin: 'auto',
  },
  spacedTitle: theme.typography.spacedTitle,
  expansionPanel: {
    ...theme.card.home,
  },
  expansionPanelHeaderGrid: {
    marginTop: '-4px',
  },
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
  },
  titleContainer: {
    padding: '4px 4px 4px 0px',
  },
  toolTipContainer: {
    padding: '4px 0px 4px 4px',
  },

  // Styling related to the empty Watchlist view
  greyText: {
    color: theme.palette.gray3,
    display: 'block',
    marginTop: 10,
  },

  // Styling related to the "Add Button" in the Watchlist
  addButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    maxHeight: '26px',
    minWidth: '40px',
    minHeight: '26px',
    borderRadius: '10px',
    marginTop: '10px',
  },
  buttonText: {
    textAlign: 'center',
    margin: 'auto',
  },

  // Styling related to the AddToWatchlistModal
  dialogPaperAddToWatchList: {
    borderRadius: '6px',
    margin: '20px',
    width: 'calc(100% - 40px)',
  },
  dialogTitleAddToWatchList: {
    paddingTop: '24px',
  },
  dialogContentAddToWatchList: {
    paddingTop: '4px',
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  modalCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  closeIcon: {
    ...theme.typography.h4,
  },

  // Styling related to the Watchlist Table
  smallContainer: {
    padding: '0 0 0 0',
  },
  tableHeaderCell: {
    padding: '0px 4px 4px 4px',
    whiteSpace: 'nowrap',
  },
  tableHeaderFont: {
    color: theme.palette.primary2,
  },
  tablePadding: {
    paddingLeft: '16px',
    paddingRight: '16px',
  },
  linkToWatchlistBox: {
    textAlign: 'center',
    padding: '10px 0px 5px 0px',
  },
  linkToWatchlist: {
    color: theme.palette.primary3,
    ...theme.typography.caption,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
});

/**
 * A PureComponent that renders an expandable card containing Watchlist data and functionality.
 */
class Watchlist extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isExpanded: props.arrWatchlistItemMenuItemRestaurantWasteAnalysis.length > 0,
      isTooltipOpened: false,
      isAddToWatchlistModalOpen: false,

      arrWatchlistItemIdWithIsWatchlistItemCollapsed: [], // Array of object containing watchlistItemId and isWatchlistItemCollapsed (boolean to indicate if the watchlist item is collapsed or expanded)
      duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed: [], // Temporary storage for arrWatchlistItemIdWithIsWatchlistItemCollapsed (for drag and drop purpose)

      // Add to watchlist
      searchValue: '',
      arrFilteredMenuItemRestaurant: [],
      arrSelectedMenuItemRestaurant: [],

      // Delete from watchlist
      isConfirmationDialogOpen: false,
      menuItemIdToBeDelete: 0,
      menuItemNameToBeDeleted: '',
      watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted: {},

      // Current style for expansion panel details container
      expansionPanelDetailsStyle: {},

      // Cache of whether the window is currently in desktop view
      isDesktopView: null,
    };
  }

  // Populates key-value store of watchlist items and sets their expanded view to false
  componentDidMount() {
    // Handle window resize events
    window.addEventListener('resize', () => this.changeWatchlistDisplayWhenResized());
    this.changeWatchlistDisplayWhenResized();
  }

  componentDidUpdate(prevProps) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;
    // Update arrWatchlistItemIdWithIsWatchlistItemCollapsed when there is an update to arrWatchlistItemMenuItemRestaurantWasteAnalysis
    if (
      prevProps.arrWatchlistItemMenuItemRestaurantWasteAnalysis !==
      arrWatchlistItemMenuItemRestaurantWasteAnalysis
    ) {
      // Update arrWatchlistItemIdWithIsWatchlistItemCollapsed and collapse all watchlist items when there is a change in the number of
      // watchlist items
      if (
        prevProps.arrWatchlistItemMenuItemRestaurantWasteAnalysis.length !==
        arrWatchlistItemMenuItemRestaurantWasteAnalysis.length
      ) {
        this.updateArrWatchlistItemIdWithIsWatchlistItemCollapsed(true);
      }
    }

    this.changeWatchlistDisplayWhenResized();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.changeWatchlistDisplayWhenResized());
  }

  /**
   * Toggle whether the expansion panel is expanded, if in mobile view. It is always expanded when in desktop view.
   */
  onChangeExpansionPanel() {
    const { isExpanded } = this.state;

    this.setState({
      isExpanded: this.checkIsDesktopView() || !isExpanded,
    });
  }

  onClickAwayTooltip() {
    this.closeTooltip();
  }

  onCloseTooltip() {
    this.closeTooltip();
  }

  onClickInfoIconForWatchlistInformation(event) {
    const { isTooltipOpened } = this.state;

    this.setState({ isTooltipOpened: !isTooltipOpened });
    event.stopPropagation();
  }

  onClickCancelConfirmationDialog() {
    this.closeConfirmationDialog();
  }

  onCloseConfirmationDialog() {
    this.closeConfirmationDialog();
  }

  onClickSubmitConfirmationDialog() {
    const { deleteFromWatchlist } = this.props;
    const {
      menuItemIdToBeDelete,
      watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted,
    } = this.state;

    deleteFromWatchlist(
      menuItemIdToBeDelete,
      watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted
    );
    this.closeConfirmationDialog();
  }

  /**
   * Filter the menu items based on the search value, and then set state with the new array of filtered menu items
   */
  onChangeSearchbar(event) {
    const { value } = event.target;
    const arrFilteredMenuItemRestaurant = this.filterMenuItemsBasedOnSearchValue(value);
    this.setState({ searchValue: value, arrFilteredMenuItemRestaurant });
  }

  /**
   * Selects/deselects a menu item based on whether it was selected before
   */
  onClickMenuItemCard(selectedMenuItemRestaurant, isSelected) {
    if (isSelected) {
      this.deselectMenuItemRestaurant(selectedMenuItemRestaurant);
    } else {
      this.selectMenuItemRestaurant(selectedMenuItemRestaurant);
    }
  }

  /**
   * Add the selected menu item(s) to the watchlist and closes the modal
   */
  onClickSubmitAddToWatchlist() {
    const { addToWatchlist } = this.props;
    const { arrSelectedMenuItemRestaurant } = this.state;

    addToWatchlist(arrSelectedMenuItemRestaurant);
    this.closeAddToWatchlistModal();
  }

  onClickCloseAddToWatchlistModal() {
    this.closeAddToWatchlistModal();
  }

  /**
   * Change the collapse/expand status of the watchlist item
   */
  onClickInfoIconToChangeWatchlistItemCollapseStatus(watchlistItemId) {
    const { arrWatchlistItemIdWithIsWatchlistItemCollapsed } = this.state;

    const arrUpdatedWatchlistItemIdWithIsWatchlistItemCollapsed =
      arrWatchlistItemIdWithIsWatchlistItemCollapsed.map(
        (watchlistItemIdWithIsWatchlistItemCollapsed) => {
          if (watchlistItemIdWithIsWatchlistItemCollapsed.watchlistItemId === watchlistItemId) {
            return {
              watchlistItemId,
              isWatchlistItemCollapsed:
                !watchlistItemIdWithIsWatchlistItemCollapsed.isWatchlistItemCollapsed,
            };
          }
          return watchlistItemIdWithIsWatchlistItemCollapsed;
        }
      );
    this.setState({
      arrWatchlistItemIdWithIsWatchlistItemCollapsed:
        arrUpdatedWatchlistItemIdWithIsWatchlistItemCollapsed,
    });
  }

  onClickOpenConfirmationDialog(
    menuItemId,
    menuItemName,
    watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted
  ) {
    this.setState({
      isConfirmationDialogOpen: true,
      menuItemIdToBeDelete: menuItemId,
      menuItemNameToBeDeleted: menuItemName,
      watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted,
    });
  }

  // Note: This is to open the AddToWatchlistModal
  onClickAddButton() {
    this.setState({
      isAddToWatchlistModalOpen: true,
    });
  }

  /**
   * Update the ranks for watchlist items. When the drag event is completed or when the row is dropped outside of the droppable area,
   * arrWatchlistItemIdWithIsWatchlistItemCollapsed will be reverted back to its original order
   */
  onDragEndUpdateRanksForWatchlistItems(result) {
    const { updateWatchlist } = this.props;
    const { duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed } = this.state;

    // Restore to the original collapsed/expanded order
    this.setState({
      arrWatchlistItemIdWithIsWatchlistItemCollapsed:
        duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed,
    });

    // If card drops outside Droppable component
    if (!result.destination) {
      return;
    }

    const arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis = this.reorder(
      result.source.index,
      result.destination.index
    );

    updateWatchlist(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis);
  }

  /**
   * Collapses all menu item cards before drag event starts. The original copy of the collapsed/expanded sequence will be saved
   * in duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed so that the collapsed/expanded state can be retrieved after the
   * reordering is completed.
   */
  onBeforeCaptureCollapseAllWatchlistItems() {
    const { arrWatchlistItemIdWithIsWatchlistItemCollapsed } = this.state;

    const duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed =
      arrWatchlistItemIdWithIsWatchlistItemCollapsed.map(
        (watchlistItemIdWithIsWatchlistItemCollapsed) => watchlistItemIdWithIsWatchlistItemCollapsed
      );

    const newArrWatchlistItemIdWithIsWatchlistItemCollapsed =
      arrWatchlistItemIdWithIsWatchlistItemCollapsed.map(
        (watchlistItemIdWithIsWatchlistItemCollapsed) => {
          return {
            watchlistItemId: watchlistItemIdWithIsWatchlistItemCollapsed.watchlistItemId,
            isWatchlistItemCollapsed: true,
          };
        }
      );

    this.setState({
      duplicatedArrWatchlistItemIdWithIsWatchlistItemCollapsed,
      arrWatchlistItemIdWithIsWatchlistItemCollapsed:
        newArrWatchlistItemIdWithIsWatchlistItemCollapsed,
    });
  }

  closeAddToWatchlistModal() {
    this.setState({
      isAddToWatchlistModalOpen: false,
      arrFilteredMenuItemRestaurant: [],
      arrSelectedMenuItemRestaurant: [],
      searchValue: '',
    });
  }

  /**
   * Check if a menu item is already on the watchlist
   * @param {typedefs.MenuItemServiceWasteAnalysis} menuItemServiceWasteAnalysis - Menu item to be verified if it is already on the watchlist
   * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
   */
  isMenuItemAlreadyOnWatchlist(menuItemRestaurant) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;

    const matchedWatchlistItemMenuItemRestaurantWasteAnalysis =
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.find(
        (watchlistItemMenuItemRestaurantWasteAnalysis) =>
          menuItemRestaurant.menuItemName ===
          watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis[0]
            .menuItemName
      );

    if (!matchedWatchlistItemMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    const matchedMenuItemRestaurantWasteAnalysis =
      matchedWatchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId === menuItemRestaurant.menuItemId
      );

    if (!matchedMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    return true;
  }

  /**
   * Filters out menu items that 1) match the search criteria and 2) are not in the watchlist, from the array of all menuItemRestaurant
   * @param {string} newSearchValue - Search value input by the user
   */
  filterMenuItemsBasedOnSearchValue(newSearchValue) {
    const { arrMenuItemRestaurantWasteAnalysis } = this.props;

    if (newSearchValue !== '') {
      const arrFilteredMenuItemRestaurant = arrMenuItemRestaurantWasteAnalysis.filter(
        (menuItemRestaurantWasteAnalysis) => {
          const searchValueInLowerCase = newSearchValue.toLowerCase();
          const menuItemRestaurantNameString = `${menuItemRestaurantWasteAnalysis.menuItemName} ${menuItemRestaurantWasteAnalysis.restaurantName} ${menuItemRestaurantWasteAnalysis.locationName} ${menuItemRestaurantWasteAnalysis.station} ${menuItemRestaurantWasteAnalysis.serviceName}`;
          const menuItemRestaurantNameStringInLowerCase =
            menuItemRestaurantNameString.toLowerCase();

          return (
            menuItemRestaurantNameStringInLowerCase.includes(searchValueInLowerCase) &&
            !this.isMenuItemAlreadyOnWatchlist(menuItemRestaurantWasteAnalysis)
          );
        }
      );

      return arrFilteredMenuItemRestaurant;
    }
    return [];
  }

  /**
   * Deselect a menu item
   * @param {typedefs.MenuItemRestaurant} menuItemRestaurant - Object containing menu item with its company, restaurant, location and service
   */
  deselectMenuItemRestaurant(menuItemRestaurant) {
    const { arrSelectedMenuItemRestaurant } = this.state;

    const arrUpdatedSelectedMenuItemRestaurant = arrSelectedMenuItemRestaurant.filter(
      (selectedMenuItemRestaurant) => {
        if (
          selectedMenuItemRestaurant.menuItemId === menuItemRestaurant.menuItemId &&
          selectedMenuItemRestaurant.serviceId === menuItemRestaurant.serviceId
        ) {
          return false;
        }
        return true;
      }
    );

    this.setState({
      arrSelectedMenuItemRestaurant: arrUpdatedSelectedMenuItemRestaurant,
    });
  }

  /**
   * Select a menu item
   * @param {typedefs.MenuItemRestaurant} menuItemRestaurant - Object containing menu item with its company, restaurant, location and service
   */
  selectMenuItemRestaurant(menuItemRestaurant) {
    const { arrSelectedMenuItemRestaurant } = this.state;

    this.setState({
      arrSelectedMenuItemRestaurant: arrSelectedMenuItemRestaurant.concat(menuItemRestaurant),
    });
  }

  /**
   * When window is resized, check if app is now in desktop view
   */
  changeWatchlistDisplayWhenResized() {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;

    // Get previousIsDesktopView in 2 steps because eslint is giving "Must use destructuring" error
    // if it were written as a single line code: "const previousIsDesktopView = isDesktopView"
    const { isDesktopView } = this.state;
    const previousIsDesktopView = isDesktopView;
    const currentIsDesktopView = this.checkIsDesktopView();

    // Not scrollable in view all watchlist items page
    let isScrollable = true;
    if (window.location.pathname === '/watchlist') {
      isScrollable = false;
    }

    // If window switches from desktop->mobile or mobile->desktop,
    if (previousIsDesktopView !== currentIsDesktopView) {
      // Contract or expand all watchlist item details
      const arrWatchlistItemIdWithIsWatchlistItemCollapsed = [];
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
        (watchlistItemMenuItemRestaurantWasteAnalysis) => {
          const watchlistItemIdWithIsWatchlistItemCollapsed = {
            watchlistItemId: watchlistItemMenuItemRestaurantWasteAnalysis.watchlistItemId,
            isWatchlistItemCollapsed: currentIsDesktopView,
          };
          arrWatchlistItemIdWithIsWatchlistItemCollapsed.push(
            watchlistItemIdWithIsWatchlistItemCollapsed
          );
        }
      );

      this.setState({
        isDesktopView: currentIsDesktopView,
        arrWatchlistItemIdWithIsWatchlistItemCollapsed,
        // Expansion panel is always expanded in desktop view
        isExpanded:
          currentIsDesktopView || arrWatchlistItemMenuItemRestaurantWasteAnalysis.length > 0,
        // Expansion panel details should be scrollable in desktop view
        expansionPanelDetailsStyle:
          currentIsDesktopView && isScrollable
            ? {
                overflow: 'auto',
                maxHeight: `${window.innerHeight - 240}px`,
              }
            : {},
      });
    }
  }

  /**
   * To update the arrWatchlistItemIdWithIsWatchlistItemCollapsed when there is an addition or deletion
   * of watchlist items
   * @param {boolean} isWatchlistItemCollapsed - To indicate if the watchlist item is collapsed
   */
  updateArrWatchlistItemIdWithIsWatchlistItemCollapsed(isWatchlistItemCollapsed) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;
    const arrWatchlistItemIdWithIsWatchlistItemCollapsed = [];
    if (isWatchlistItemCollapsed) {
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
        (watchlistItemMenuItemRestaurantWasteAnalysis) => {
          const watchlistItemIdWithIsWatchlistItemCollapsed = {
            watchlistItemId: watchlistItemMenuItemRestaurantWasteAnalysis.watchlistItemId,
            isWatchlistItemCollapsed,
          };
          arrWatchlistItemIdWithIsWatchlistItemCollapsed.push(
            watchlistItemIdWithIsWatchlistItemCollapsed
          );
        }
      );
      this.setState({
        arrWatchlistItemIdWithIsWatchlistItemCollapsed,
      });
    }
  }

  closeTooltip() {
    this.setState({ isTooltipOpened: false });
  }

  closeConfirmationDialog() {
    this.setState({ isConfirmationDialogOpen: false });
  }

  checkIsDesktopView() {
    const { theme } = this.props;

    return window.matchMedia(`(min-width: ${theme.breakpoints.values.md}px)`).matches;
  }

  openAddToWatchlistModalButton() {
    const { classes, t } = this.props;

    return (
      <Button
        variant="contained"
        size="small"
        className={classes.addButton}
        onClick={() => this.onClickAddButton()}
      >
        <Typography className={classes.buttonText} variant="h6">
          {t('watchlist.addButtonLabel')}
        </Typography>
      </Button>
    );
  }

  /**
   * Reorder watchlist items after a watchlist item is dragged
   */
  reorder(indexBeforeDragging, indexAfterDragging) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;
    const arrUpdatedWatchlistItemMenuItemRestaurantWasteAnalysis = Array.from(
      arrWatchlistItemMenuItemRestaurantWasteAnalysis
    );

    const [draggedWatchlistItemMenuItemRestaurantWasteAnalysis] =
      arrUpdatedWatchlistItemMenuItemRestaurantWasteAnalysis.splice(indexBeforeDragging, 1);
    arrUpdatedWatchlistItemMenuItemRestaurantWasteAnalysis.splice(
      indexAfterDragging,
      0,
      draggedWatchlistItemMenuItemRestaurantWasteAnalysis
    );

    return arrUpdatedWatchlistItemMenuItemRestaurantWasteAnalysis;
  }

  renderEmptyWatchlist() {
    const { classes, t } = this.props;

    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        {this.openAddToWatchlistModalButton()}
        <Typography variant="h3" className={classes.greyText}>
          {t('watchlist.watchlistIsEmptyText')}
        </Typography>
      </Grid>
    );
  }

  renderWatchlistData() {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length === 0) {
      return this.renderEmptyWatchlist();
    }

    return this.renderNonEmptyWatchlist();
  }

  renderNonEmptyWatchlist() {
    const { classes, theme, t } = this.props;
    let { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;
    const { arrWatchlistItemIdWithIsWatchlistItemCollapsed } = this.state;
    const { currency } = this.context;

    let toShowMaxItems = true;
    if (window.location.pathname === '/watchlist') {
      toShowMaxItems = false;
    }

    // Show maximum of 20 watchlist items
    const numberOfWatchlistItems = arrWatchlistItemMenuItemRestaurantWasteAnalysis.length;
    if (toShowMaxItems && numberOfWatchlistItems > 20) {
      arrWatchlistItemMenuItemRestaurantWasteAnalysis =
        arrWatchlistItemMenuItemRestaurantWasteAnalysis.slice(0, 20);
    }

    return (
      <DragDropContext
        onDragEnd={(result) => this.onDragEndUpdateRanksForWatchlistItems(result)}
        onBeforeCapture={() => this.onBeforeCaptureCollapseAllWatchlistItems()}
      >
        <Container className={classes.smallContainer}>
          <TableContainer>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell width="5%" size="small" padding="none" />
                  <TableCell width="50%" className={classes.tableHeaderCell} align="left">
                    <Typography variant="h5" className={classes.tableHeaderFont}>
                      {t('watchlist.nameHeader')}
                    </Typography>
                  </TableCell>
                  <TableCell width="20%" className={classes.tableHeaderCell} align="right">
                    <Typography variant="h5" className={classes.tableHeaderFont}>
                      {t('watchlist.weightHeader')} (KG)
                    </Typography>
                  </TableCell>
                  <TableCell width="20%" className={classes.tableHeaderCell} align="right">
                    <Typography variant="h5" className={classes.tableHeaderFont}>
                      {t('watchlist.costHeader')} ({currency})
                    </Typography>
                  </TableCell>
                  <TableCell width="5%" padding="none" size="small" />
                </TableRow>
              </TableHead>
              <Droppable droppableId="table">
                {(droppableProvided) => (
                  <TableBody
                    ref={(ref) => {
                      droppableProvided.innerRef(ref);
                    }}
                    {...droppableProvided.droppableProps}
                  >
                    {arrWatchlistItemMenuItemRestaurantWasteAnalysis.map(
                      (watchlistItemMenuItemRestaurantWasteAnalysis, index) => (
                        <Draggable
                          draggableId={
                            watchlistItemMenuItemRestaurantWasteAnalysis
                              .arrMenuItemRestaurantWasteAnalysis[0].menuItemName
                          }
                          index={index}
                          key={
                            watchlistItemMenuItemRestaurantWasteAnalysis
                              .arrMenuItemRestaurantWasteAnalysis[0].menuItemName
                          }
                        >
                          {(provided, snapshot) => (
                            <WatchlistRow
                              provided={provided}
                              snapshot={snapshot}
                              key={
                                watchlistItemMenuItemRestaurantWasteAnalysis
                                  .arrMenuItemRestaurantWasteAnalysis[0].menuItemName
                              }
                              watchlistItemMenuItemRestaurantWasteAnalysis={
                                watchlistItemMenuItemRestaurantWasteAnalysis
                              }
                              onClickInfoIcon={(watchlistItemId) =>
                                this.onClickInfoIconToChangeWatchlistItemCollapseStatus(
                                  watchlistItemId
                                )
                              }
                              isWatchlistItemCollapsed={
                                arrWatchlistItemIdWithIsWatchlistItemCollapsed.find(
                                  (watchlistItemIdWithIsWatchlistItemCollapsed) =>
                                    watchlistItemIdWithIsWatchlistItemCollapsed.watchlistItemId ===
                                    watchlistItemMenuItemRestaurantWasteAnalysis.watchlistItemId
                                )
                                  ? arrWatchlistItemIdWithIsWatchlistItemCollapsed.find(
                                      (watchlistItemIdWithIsWatchlistItemCollapsed) =>
                                        watchlistItemIdWithIsWatchlistItemCollapsed.watchlistItemId ===
                                        watchlistItemMenuItemRestaurantWasteAnalysis.watchlistItemId
                                    ).isWatchlistItemCollapsed
                                  : undefined
                              }
                              onClickOpenConfirmationDialog={(
                                menuItemId,
                                menuItemName,
                                watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted
                              ) =>
                                this.onClickOpenConfirmationDialog(
                                  menuItemId,
                                  menuItemName,
                                  watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted
                                )
                              }
                            />
                          )}
                        </Draggable>
                      )
                    )}
                    {droppableProvided.placeholder}
                  </TableBody>
                )}
              </Droppable>
            </Table>
          </TableContainer>

          {/* View all link */}
          {toShowMaxItems && (
            <Box className={classes.linkToWatchlistBox}>
              <Link to="/watchlist" className={classes.linkToWatchlist}>
                {t('watchlist.viewAllButtonLabel')}
              </Link>
            </Box>
          )}

          {/* Render 'Add' button in at bottom of watchlist for mobile view */}
          <Media
            query={`(max-width: ${theme.breakpoints.values.md - 1}px)`}
            render={() => (
              <Box component="div" align="right">
                {this.openAddToWatchlistModalButton()}
              </Box>
            )}
          />
        </Container>
      </DragDropContext>
    );
  }

  renderAddToWatchlistModal() {
    const { classes, arrWatchlistItemMenuItemRestaurantWasteAnalysis, t } = this.props;
    const {
      isAddToWatchlistModalOpen,
      arrFilteredMenuItemRestaurant,
      arrSelectedMenuItemRestaurant,
      searchValue,
    } = this.state;

    return (
      <Dialog
        open={isAddToWatchlistModalOpen}
        onClose={(event, reason) => {
          if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            this.closeAddToWatchlistModal();
          }
        }}
        fullWidth
        classes={{ paper: classes.dialogPaperAddToWatchList }}
      >
        <DialogTitle className={classes.dialogTitleAddToWatchList}>
          <Typography align="center" variant="h1" color="primary">
            {t('watchlist.watchlistModalHeader')}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => this.onClickCloseAddToWatchlistModal()}
            className={classes.modalCloseButton}
            size="small"
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContentAddToWatchList}>
          <AddToWatchlistModal
            arrWatchlistItemMenuItemRestaurantWasteAnalysis={
              arrWatchlistItemMenuItemRestaurantWasteAnalysis
            }
            arrFilteredMenuItemRestaurant={arrFilteredMenuItemRestaurant}
            searchValue={searchValue}
            arrSelectedMenuItemRestaurant={arrSelectedMenuItemRestaurant}
            onChangeSearchbar={(event) => this.onChangeSearchbar(event)}
            onClickMenuItemCard={(selectedMenuItemRestaurant, isSelected) =>
              this.onClickMenuItemCard(selectedMenuItemRestaurant, isSelected)
            }
            onClickSubmit={() => this.onClickSubmitAddToWatchlist()}
          />
        </DialogContent>
      </Dialog>
    );
  }

  renderExpansionPanelSummaryContent() {
    const { classes, theme, arrWatchlistItemMenuItemRestaurantWasteAnalysis, t } = this.props;
    const { isTooltipOpened } = this.state;

    return (
      <Grid
        container
        direction="row"
        alignItems="baseline"
        justifyContent="space-between"
        className={classes.expansionPanelHeaderGrid}
      >
        <Grid item>
          <Grid container direction="row" spacing={0} alignItems="baseline">
            <Grid item className={classes.titleContainer}>
              <Typography variant="h1" color="primary" className={classes.spacedTitle}>
                {t('watchlist.header')}
              </Typography>
            </Grid>
            <Grid item className={classes.toolTipContainer}>
              <ClickAwayListener onClickAway={() => this.onClickAwayTooltip()}>
                <Tooltip
                  open={isTooltipOpened}
                  onClose={() => this.onCloseTooltip()}
                  disableFocusListener
                  disableHoverListener
                  disableTouchListener
                  arrow
                  title={
                    <Typography variant="caption">
                      <Trans
                        i18nKey="watchlist.tooltipText"
                        values={{ watchlistMaxSize: CONSTANT.watchlistMaxSize }}
                      />
                    </Typography>
                  }
                >
                  <InfoIcon
                    onClick={(event) => this.onClickInfoIconForWatchlistInformation(event)}
                    className={classes.tooltipIcon}
                  />
                </Tooltip>
              </ClickAwayListener>
            </Grid>
          </Grid>
        </Grid>

        {/* Render 'Add' button in expansion header for desktop view */}
        {arrWatchlistItemMenuItemRestaurantWasteAnalysis.length > 0 && (
          <Media
            query={`(min-width: ${theme.breakpoints.values.md}px)`}
            render={() => <Grid item>{this.openAddToWatchlistModalButton()}</Grid>}
          />
        )}
      </Grid>
    );
  }

  render() {
    const { classes, theme, arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.props;
    const {
      expansionPanelDetailsStyle,
      isConfirmationDialogOpen,
      isExpanded,
      menuItemNameToBeDeleted,
    } = this.state;

    return (
      <>
        <Box component="div" className={classes.root}>
          {/* Mobile or desktop view of watchlist */}
          <Media
            queries={{
              mobile: `(max-width: ${theme.breakpoints.values.md - 1}px)`,
              desktop: `(min-width: ${theme.breakpoints.values.md}px)`,
            }}
          >
            {(matches) => (
              <ExpansionPanel
                className={classes.expansionPanel}
                defaultExpanded={
                  matches.desktop || arrWatchlistItemMenuItemRestaurantWasteAnalysis.length > 0
                }
                expanded={isExpanded}
                onChange={() => this.onChangeExpansionPanel()}
              >
                <ExpansionPanelSummary expandIcon={matches.mobile && <ExpandMoreIcon />}>
                  {this.renderExpansionPanelSummaryContent()}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  className={classes.tablePadding}
                  style={expansionPanelDetailsStyle}
                >
                  {this.renderWatchlistData()}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            )}
          </Media>
        </Box>

        {/* Add to watchlist modal popup */}
        {this.renderAddToWatchlistModal()}

        {/* Delete from watchlist confirmation dialog */}
        <ConfirmationDialog
          isConfirmationDialogOpen={isConfirmationDialogOpen}
          onClickCancel={() => this.onClickCancelConfirmationDialog()}
          onClickSubmit={() => this.onClickSubmitConfirmationDialog()}
          onClose={() => this.onCloseConfirmationDialog()}
          menuItemName={menuItemNameToBeDeleted}
        />
      </>
    );
  }
}

Watchlist.contextType = AppContext;

export default withTranslation()(withTheme(withStyles(styles)(Watchlist)));
