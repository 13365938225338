import axios from 'axios';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Input,
  InputAdornment,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';

import DropdownList from '../common/DropdownList';
// eslint-disable-next-line no-unused-vars
import { MenuItem } from '../typedefs';
import LoaderImg from '../../imgs/Loader3.gif';

import { AppContext, CONSTANT } from '../../AppContext';

const styles = (theme) => ({
  menuItem: {
    padding: '3px 5px',
    borderRadius: '10px',
    '&:hover': {
      backgroundColor: '#EAEAEA',
    },
  },
  menuItemText: {
    flexGrow: 1,
  },
  menuItemRadioGroup: {
    display: 'grid',
    gridTemplateColumns: '50% 50%',
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '25% 25% 25% 25%',
    },
  },
});

/**
 * This function takes in arrMenuItem and sorts them by name first, then station
 * 'Others' station is also deprioritised to the back of the array
 * @param {Array<MenuItem>} arrMenuItem - Array of menu item to be sorted
 * @returns {void} - Function has no return, arrMenuItem is sorted in-place
 */
const sortMenuItemByNameByStation = (arrMenuItem) => {
  arrMenuItem.sort((menuItemA, menuItemB) => {
    const { name: nameA, station: stationA } = menuItemA;
    if (stationA === 'Others') {
      return Infinity;
    }
    const { name: nameB, station: stationB } = menuItemB;
    return stationA.localeCompare(stationB) || nameA.localeCompare(nameB);
  });
};

class WasteInput extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedServiceId: null,
      arrServiceDropDownOption: [],
      arrMenuItem: [],
      selectedMenuItemId: null,
      inputtedWeightInKilogram: 0,
      mapMenuIdByServiceId: new Map(),
      isLoaderVisible: false,
      isFetchError: false,
    };
  }

  componentDidMount() {
    document.title = 'Waste Input | Lumitics | Towards Zero Food Waste';
    const { openSnackbar, setPageHistory, renderLoaderAnimation, isManualInputUser } = this.context;
    const { history, t } = this.props;

    if (!isManualInputUser) {
      openSnackbar(t('wasteInput.accessForbiddenSnackbarText'), 'error');
      history.push('/');
    } else {
      setPageHistory({ organisationName: '', pageNameKey: CONSTANT.wasteInputPage });
      renderLoaderAnimation(false);
      this.initialiseLocationServiceSelector();
    }
  }

  componentDidUpdate(prevProp, prevState) {
    const { selectedServiceId } = this.state;
    if (selectedServiceId && prevState.selectedServiceId !== selectedServiceId) {
      this.fetchMenu();
    }
  }

  /**
   * This function is called on selection of location service dropdown through this.componentDidUpdate
   * The fetched menu is used to populate the menu item radio group for user selection
   */
  async fetchMenu() {
    const { auth0, history } = this.props;
    const { mapMenuIdByServiceId, selectedServiceId } = this.state;
    try {
      this.setState({
        isLoaderVisible: true,
      });
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/fetch-client-menu-item',
        {
          userId,
          arrMenuId: [mapMenuIdByServiceId.get(selectedServiceId)],
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { arrClientMenuItem } = response.data;
      const arrClientMenuItemOption = arrClientMenuItem[0].arrMenuItem.slice(0, 11);
      sortMenuItemByNameByStation(arrClientMenuItemOption);
      this.setState({
        arrMenuItem: arrClientMenuItemOption,
        isFetchError: false,
        inputtedWeightInKilogram: 0,
        selectedMenuItemId: null,
      });
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        this.setState({
          isFetchError: true,
        });
      }
    } finally {
      this.setState({
        isLoaderVisible: false,
      });
    }
  }

  /**
   * This function initialises the location service selector by populating dropdown selection
   * Only services that are manual input enabled will be displayed
   * At the same time, initialise mapMenuIdByServiceId used to fetch the associated menu id when
   * service is selected
   */
  initialiseLocationServiceSelector() {
    const { arrRestaurantService } = this.props;
    const mapMenuIdByServiceId = new Map();
    const arrServiceDropDownOption = [];
    arrRestaurantService.forEach((restaurantService) => {
      const { arrLocationService } = restaurantService;
      arrLocationService.forEach((locationService) => {
        const { name: locationName, arrService } = locationService;
        arrService.forEach((service) => {
          const { name: serviceName, serviceId, menuId, isManualInputEnabled } = service;
          if (isManualInputEnabled) {
            mapMenuIdByServiceId.set(serviceId, menuId);
            arrServiceDropDownOption.push({
              name: `${locationName} - ${serviceName}`,
              value: serviceId,
            });
          }
        });
      });
    });
    this.setState({
      mapMenuIdByServiceId,
      selectedServiceId:
        arrServiceDropDownOption.length === 0 ? null : arrServiceDropDownOption[0].value,
      arrServiceDropDownOption,
    });
  }

  /**
   * This function is called on click of save changes button
   * If weight input and menu item selection is empty, then display error to user to fill them up
   * On successful waste creation, form state is reset back to blank state
   */
  async saveChanges() {
    const { inputtedWeightInKilogram, selectedMenuItemId, selectedServiceId } = this.state;
    const { openSnackbar } = this.context;
    const { auth0, history, t } = this.props;
    if (inputtedWeightInKilogram === 0 || !selectedMenuItemId) {
      openSnackbar(t('wasteInput.emptyInputErrorSnackbarText'), 'error');
    } else {
      try {
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        await axios.post(
          '/api/create-waste-without-image',
          {
            userId,
            serviceId: selectedServiceId,
            weight: inputtedWeightInKilogram * 1000,
            menuItemId: selectedMenuItemId,
            time: new Date(),
            userName: `${user.given_name} ${user.family_name}`,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        openSnackbar(t('wasteInput.saveChangesSuccessSnackbarText'), 'success');
        this.setState({
          selectedMenuItemId: null,
          inputtedWeightInKilogram: 0,
        });
      } catch (error) {
        const { response } = error;
        // Catch JWT web token error
        if (response && response.status === 401) {
          history.push('/login');
        } else {
          openSnackbar(t('wasteInput.saveChangesErrorSnackbarText'), 'error');
        }
      }
    }
  }

  render() {
    const { classes, theme, t } = this.props;
    const {
      selectedServiceId,
      arrServiceDropDownOption,
      selectedMenuItemId,
      inputtedWeightInKilogram,
      arrMenuItem,
      isLoaderVisible,
      isFetchError,
    } = this.state;

    return (
      <Box
        display="flex"
        flexDirection="column"
        padding={2}
        margin={2}
        bgcolor="white"
        borderRadius={10}
        boxShadow={2}
      >
        <Box display="flex" justifyContent="space-between" gridGap={20}>
          <Box display="flex" gridGap={10}>
            <DropdownList
              minWidth="150px"
              label={t('wasteInput.locationServiceDropdownLabel')}
              backgroundColor="white"
              selectedValue={selectedServiceId}
              arrDropdownItem={arrServiceDropDownOption}
              onChange={(event) =>
                this.setState({
                  selectedServiceId: event.target.value,
                })
              }
            />
          </Box>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<CheckCircleOutlineSharpIcon />}
              onClick={() => this.saveChanges()}
              style={{ paddingLeft: '10px', paddingRight: '10px' }}
            >
              <Typography variant="body2" style={{ fontSize: '0.7rem' }}>
                {t('common.submitButtonLabel')}
              </Typography>
            </Button>
          </Box>
        </Box>
        {isFetchError ? (
          <Box display="flex" justifyContent="center" padding={6} color="red">
            {t('wasteInput.fetchMenuItemErrorText')}
          </Box>
        ) : (
          <>
            <Box margin={2} gridGap={10} display="flex" flexDirection="column">
              {isLoaderVisible ? (
                <Box display="flex" flexGrow={1} justifyContent="center" padding={5}>
                  <img src={LoaderImg} style={{ width: '180px' }} alt="loader" />
                </Box>
              ) : null}
              {selectedServiceId === null ? (
                <Box paddingTop={6}>
                  <Typography align="center" variant="h3">
                    {t('wasteInput.noManualInputEnabledServiceFoundErrorHeader')}
                  </Typography>
                  <Typography align="center" variant="subtitle2">
                    {t('wasteInput.noManualInputEnabledServiceFoundErrorSubtitle')}
                  </Typography>
                </Box>
              ) : null}
              {!isLoaderVisible && selectedServiceId !== null ? (
                <>
                  <Box>
                    <FormControl style={{ width: '25ch', margin: theme.spacing(1) }}>
                      <InputLabel style={{ zIndex: 10, fontSize: '1.3rem' }}>
                        {t('wasteInput.inputWasteWeightHeader')}
                      </InputLabel>
                      <Input
                        value={inputtedWeightInKilogram}
                        type="number"
                        onChange={(event) =>
                          this.setState({ inputtedWeightInKilogram: event.target.value })
                        }
                        onBlur={(event) =>
                          this.setState({
                            inputtedWeightInKilogram:
                              event.target.value < 0
                                ? 0
                                : Math.round(event.target.value * 1000) / 1000,
                          })
                        }
                        inputProps={{ min: 0, step: 0.1 }}
                        endAdornment={<InputAdornment position="end">KG</InputAdornment>}
                      />
                    </FormControl>
                  </Box>
                  {arrMenuItem.length > 0 ? (
                    <Box display="flex" padding={1}>
                      <FormControl style={{ flexGrow: 1 }}>
                        <FormLabel style={{ fontSize: '1rem', marginBottom: '8px' }}>
                          {t('wasteInput.selectMenuItemHeader')}
                        </FormLabel>
                        <RadioGroup
                          value={selectedMenuItemId}
                          onChange={(event) =>
                            this.setState({ selectedMenuItemId: Number(event.target.value) })
                          }
                          classes={{
                            root: classes.menuItemRadioGroup,
                          }}
                        >
                          {arrMenuItem.map((menuItem) => (
                            <FormControlLabel
                              classes={{
                                root: classes.menuItem,
                                label: classes.menuItemText,
                              }}
                              key={menuItem.menuItemId}
                              value={menuItem.menuItemId}
                              control={<Radio />}
                              label={
                                <Box display="flex" flexDirection="column">
                                  <Typography
                                    style={{ fontSize: '0.6rem' }}
                                    variant="subtitle2"
                                    display="inline"
                                  >{`${menuItem.station}`}</Typography>
                                  <Typography
                                    style={{ fontSize: '1rem' }}
                                    variant="h6"
                                    display="inline"
                                  >{`${menuItem.name}`}</Typography>
                                </Box>
                              }
                            />
                          ))}
                        </RadioGroup>
                      </FormControl>
                    </Box>
                  ) : (
                    <Box display="flex" justifyContent="center" padding={6} color="red">
                      {t('wasteInput.noMenuItemFoundErrorText')}
                    </Box>
                  )}
                </>
              ) : null}
            </Box>
          </>
        )}
      </Box>
    );
  }
}

WasteInput.contextType = AppContext;

export default withTranslation()(withRouter(withAuth0(withTheme(withStyles(styles)(WasteInput)))));
