import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';

import CustomTable from '../common/CustomTable';

class StationsBreakdownTable extends PureComponent {
  render() {
    const {
      arrStationServiceWasteAnalysis,
      toggleValueForWeightCost,
      arrSelectedStationServiceWasteAnalysis,
      updateGraphAndTableForArrSelectedStationServiceWasteAnalysis,
      t,
    } = this.props;

    const weightCostId = toggleValueForWeightCost;
    const weightCostHeader =
      toggleValueForWeightCost === 'weight'
        ? t('breakdownByStation.weightHeader').toUpperCase()
        : t('breakdownByStation.costHeader').toUpperCase();
    const weightCostDifferenceId =
      toggleValueForWeightCost === 'weight'
        ? 'weightDifferencePercentage'
        : 'costDifferencePercentage';
    const weightCostDifferenceHeader =
      toggleValueForWeightCost === 'weight'
        ? `% ${t('breakdownByStation.changeInWeightHeader').toUpperCase()}`
        : `% ${t('breakdownByStation.changeInCostHeader').toUpperCase()}`;
    const arrTableHeader = [
      {
        id: 'station',
        label: t('breakdownByStation.stationHeader').toUpperCase(),
        iconName: 'filter',
      },
      {
        id: 'serviceName',
        label: t('breakdownByStation.serviceHeader').toUpperCase(),
        iconName: 'filter',
      },
      { id: weightCostId, label: weightCostHeader, iconName: 'sort' },
      { id: weightCostDifferenceId, label: weightCostDifferenceHeader, iconName: 'sort' },
    ];
    const arrTableAlignment = ['left', 'center', 'center', 'center'];
    const arrTableWrap = [true, true, false, false];
    const arrIdKey = ['station', 'serviceId'];
    const arrTableRow = arrStationServiceWasteAnalysis;
    const arrColumnWidth = ['40%', '30%', '15%', '15%'];

    return (
      <div>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrIdKey={arrIdKey}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          hasFilterAndSortFunctionAndHeaderIcons
          hasPagination
          hasSelectAllCheckboxInHeader
          updateArrSelectedRow={updateGraphAndTableForArrSelectedStationServiceWasteAnalysis}
          arrSelectedRow={arrSelectedStationServiceWasteAnalysis}
          rowsPerPage={5}
          hasCheckboxInBody
          arrColumnWidth={arrColumnWidth}
        />
      </div>
    );
  }
}

export default withTranslation()(StationsBreakdownTable);
