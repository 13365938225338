import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import BarChart from '../../common/chart/BarChart';
import { AppContext } from '../../../AppContext';

const styles = (theme) => ({
  title: {
    color: theme.palette.primary.main,
    paddingBottom: '12px',
  },
});

class TopThreeWastefulStations extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { currency } = this.context;
    const {
      classes,
      isCompanyUser,
      selectedLevelWasteAnalysisForHighlights,
      toggleValueForWeightCost,
      t,
    } = this.props;

    let xAxisData = [];
    let yAxisData = [];
    let labelData = [];
    // The checking of weight = 0 is if there is no waste thrown during the selected period, there should not be any axis or label shown
    if (selectedLevelWasteAnalysisForHighlights) {
      if (toggleValueForWeightCost === 'weight') {
        xAxisData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byWeight
            .arrStation;
        yAxisData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byWeight
            .arrWeight;
        labelData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byWeight
            .arrServiceName;
      } else {
        xAxisData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrStation;
        yAxisData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrCost;
        labelData =
          selectedLevelWasteAnalysisForHighlights.topThreeStationWasteAnalysisForBarChart.byCost
            .arrServiceName;
      }
    }
    return (
      <Box id="top-three-wasteful-stations-chart">
        <Grid container justifyContent="center" alignItems="center">
          <Typography className={classes.title} variant="h1">
            {t('breakdown.topWastefulStationsHeader')}
          </Typography>
        </Grid>
        <BarChart
          xAxisData={xAxisData}
          yAxisData={yAxisData}
          yAxisLabel={
            toggleValueForWeightCost === 'weight'
              ? `${t('chart.weightAxisLabel')} (KG)`
              : `${t('chart.costAxisLabel')} (${currency})`
          }
          labelData={labelData}
          hasDedicatedChartColor={!isCompanyUser}
        />
      </Box>
    );
  }
}

TopThreeWastefulStations.contextType = AppContext;

export default withTranslation()(withStyles(styles)(TopThreeWastefulStations));
