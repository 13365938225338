import axios from 'axios';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  TextField,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import { AppContext, CONSTANT } from '../../AppContext';

const styles = (theme) => ({
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '35px',
    paddingBottom: '35px',
  },

  // Submit button
  submitBtnGrid: {
    textAlign: 'center',
  },
  submitBtn: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
    borderRadius: '7px',
  },

  // Password requirements
  listItem: {
    padding: '0px',
  },
  requirementsTitle: {
    fontSize: theme.typography.h3.fontSize,
    color: '#545454',
    fontWeight: theme.typography.h3.fontWeight,
  },
  listText: {
    fontSize: theme.typography.h4.fontSize,
    color: '#545454',
  },
  checked: {
    '&$checked': {
      color: theme.palette.secondary.main,
    },
  },
});

class ChangePassword extends PureComponent {
  constructor() {
    super();

    this.state = {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      minMaxCharacters: false,
      containsLowercase: false,
      containsUppercase: false,
      containsSpecialCharacter: false,
      containsNumber: false,
      showCurrentPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  }

  componentDidMount() {
    document.title = 'Change Password | Lumitics | Towards Zero Food Waste';
    const { setPageHistory } = this.context;

    setPageHistory({ organisationName: '', pageNameKey: CONSTANT.changePasswordPage });
  }

  /**
   * Change text field input based on input. For new password, the input will go through a rigorous check for uppercase, lowercase, numbers,
   * special characters and the length of the password.
   */
  onChangeTextField(event) {
    const { name, value } = event.target;

    if (name === 'newPassword') {
      // Check if password is between 8 - 20 characters
      const minMaxCharacters = value.match(/^.{8,20}$/);

      // Check if password contains lowercase
      const containsLowercase = value.match(/^(?=.*[a-z])/);

      // Check if password contains uppercase
      const containsUppercase = value.match(/^(?=.*[A-Z])/);

      // Check if password contains special character
      const containsSpecialCharacter = value.match(/^(?=.*?[#?!@$%^&*-])/);

      // Check if password contains number
      const containsNumber = value.match(/^(?=.*?[0-9])/);

      this.setState({
        minMaxCharacters,
        containsLowercase,
        containsUppercase,
        containsSpecialCharacter,
        containsNumber,
      });
    }

    if (name === 'currentPassword') {
      this.setState({ currentPassword: value });
    } else if (name === 'newPassword') {
      this.setState({ newPassword: value });
    } else {
      this.setState({ confirmPassword: value });
    }
  }

  /**
   * To show or hide password
   */
  onClickIconButton(eventName) {
    const { showConfirmPassword, showCurrentPassword, showNewPassword } = this.state;

    if (eventName === 'currentPassword') {
      this.setState({ showCurrentPassword: !showCurrentPassword });
    } else if (eventName === 'newPassword') {
      this.setState({ showNewPassword: !showNewPassword });
    } else {
      this.setState({ showConfirmPassword: !showConfirmPassword });
    }
  }

  /**
   * Submit for the change in password. If the new password does not pass the check, open a snackbar to inform the user that the new password does not meet
   * the criteria. Else, send the data to the Backend to update in the database.
   */
  async onSubmit(event) {
    event.preventDefault();

    const { openSnackbar } = this.context;
    const { auth0, history, t } = this.props;
    const {
      currentPassword,
      newPassword,
      confirmPassword,
      minMaxCharacters,
      containsLowercase,
      containsUppercase,
      containsSpecialCharacter,
      containsNumber,
    } = this.state;
    const token = await auth0.getAccessTokenSilently();
    const { user } = auth0;
    let userId = user.datavizUserId;
    if (user.isAdmin) {
      const { impersonatorDatavizUserId } = this.context;
      userId = impersonatorDatavizUserId;
    }

    if (
      !(
        minMaxCharacters &&
        containsLowercase &&
        containsUppercase &&
        containsSpecialCharacter &&
        containsNumber
      )
    ) {
      openSnackbar(t('changePassword.isPasswordError'), 'error');
    } else if (newPassword !== confirmPassword) {
      openSnackbar(t('changePassword.isPasswordsMismatched'), 'error');
    } else {
      try {
        await axios.post(
          '/api/change-password',
          {
            auth0UserId: user.sub,
            userId,
            currentPassword,
            newPassword,
            changePasswordType: 'update',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        openSnackbar(t('changePassword.submitSuccessSnackbarText'), 'success');
        this.setState({
          currentPassword: '',
          newPassword: '',
          confirmPassword: '',
        });
      } catch (error) {
        const { response } = error;
        // Catch JWT web token error
        if (response && response.status === 401) {
          history.push('/login');
          // Status 400 is for invalid email or password
        } else if (response && response.status === 400) {
          const errorMessage = response.data.error;
          openSnackbar(errorMessage, 'error');
        } else {
          openSnackbar(t('changePassword.submitErrorSnackbarText'), 'error');
        }
      }
    }
  }

  render() {
    const { classes, t } = this.props;
    const {
      minMaxCharacters,
      containsLowercase,
      containsUppercase,
      containsSpecialCharacter,
      containsNumber,
      showCurrentPassword,
      showNewPassword,
      showConfirmPassword,
    } = this.state;

    return (
      <form onSubmit={(event) => this.onSubmit(event)}>
        <Grid container direction="column" spacing={5} className={classes.rootGridContainer}>
          <Grid item>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('changePassword.currentPasswordPlaceholderText')}
              name="currentPassword"
              type={showCurrentPassword ? 'text' : 'password'}
              onChange={(event) => this.onChangeTextField(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.onClickIconButton('currentPassword')}>
                      {showCurrentPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('changePassword.newPasswordPlaceholderText')}
              name="newPassword"
              type={showNewPassword ? 'text' : 'password'}
              onChange={(event) => this.onChangeTextField(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.onClickIconButton('newPassword')}>
                      {showNewPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <TextField
              required
              fullWidth
              variant="outlined"
              label={t('changePassword.confirmNewPasswordPlaceholderText')}
              name="confirmPassword"
              type={showConfirmPassword ? 'text' : 'password'}
              onChange={(event) => this.onChangeTextField(event)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => this.onClickIconButton('confirmPassword')}>
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item>
            <List>
              <ListItem className={classes.listItem}>
                <ListItemText>
                  <Typography className={classes.requirementsTitle}>
                    {t('changePassword.requirementsHeader')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Checkbox
                    checked={minMaxCharacters}
                    disabled
                    classes={{ checked: classes.checked }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listText}>
                    {t('changePassword.requirementsHeader')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Checkbox
                    checked={containsLowercase}
                    disabled
                    classes={{ checked: classes.checked }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listText}>
                    {t('changePassword.lowercaseCharacter')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Checkbox
                    checked={containsUppercase}
                    disabled
                    classes={{ checked: classes.checked }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listText}>
                    {t('changePassword.uppercaseCharacter')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Checkbox
                    checked={containsSpecialCharacter}
                    disabled
                    classes={{ checked: classes.checked }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listText}>
                    {t('changePassword.specialCharacter')}
                  </Typography>
                </ListItemText>
              </ListItem>

              <ListItem className={classes.listItem}>
                <ListItemIcon>
                  <Checkbox
                    checked={containsNumber}
                    disabled
                    classes={{ checked: classes.checked }}
                  />
                </ListItemIcon>
                <ListItemText>
                  <Typography className={classes.listText}>
                    {t('changePassword.numericCharacter')}
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>

          <Grid item className={classes.submitBtnGrid}>
            <Button type="submit" variant="contained" className={classes.submitBtn}>
              {t('common.submitButtonLabel')}
            </Button>
          </Grid>
        </Grid>
      </form>
    );
  }
}

ChangePassword.contextType = AppContext;

export default withTranslation()(withRouter(withAuth0(withStyles(styles)(ChangePassword))));
