import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
// Styling
import { withStyles, withTheme } from '@material-ui/core/styles';
// Chart Library
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import { ARR_PASTEL_COLOR } from './Constants';
import { AppContext, CONSTANT } from '../../../AppContext';

Chart.register(...registerables, ChartDataLabels);

const styles = (theme) => ({
  alignCenter: {
    textAlign: 'center',
  },
  legendItem: {
    paddingRight: 10,
    color: CONSTANT.defaultAxisColor,
  },
  legendColorBox: {
    marginRight: '2px',
    width: '8px',
    height: '3px',
    marginBottom: 3,
    display: 'inline-block',
  },
  legendText: {
    display: 'inline',
    ...theme.typography.tableCell,
  },
  outerBoxContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  innerBoxContainer: {
    position: 'relative',
  },
});

/**
 * Check if the two legends are equal. This function is used to check if the bar chart needs to be updated.
 * @param {string} legendA - Legend A
 * @param {string} legendB - Legend B
 * @returns {boolean} Boolean value to indicate if thw two legends are equal
 */
const checkEqualsLegend = (legendA, legendB) => {
  if (legendA.length !== legendB.length) {
    return false;
  }
  for (let i = 0; i < legendA.length; i += 1) {
    if (legendA[i] !== legendB[i]) {
      return false;
    }
  }
  return true;
};

/**
 * Gets the height of the chart based on where the chart is.
 * Returns 132 if the chart is in the Key Overview card, else returns 190.
 * @returns {number} Number - Height of the card: 132 or 190
 */
const getHeight = (isInKeyOverview) => {
  if (isInKeyOverview) {
    return CONSTANT.keyOverviewChartHeight;
  }
  return CONSTANT.defaultLineChartHeight;
};

/**
 * Returns the pastel colours based on the total number of legend keys for each bar in the bar chart.
 * The maximum number of colours available is 10.
 * @param {number} numberOfLegendKeys - Number of legend keys
 */
const getPastelColors = (numberOfLegendKeys) => {
  try {
    return ARR_PASTEL_COLOR.slice(0, numberOfLegendKeys);
  } catch (err) {
    // To do: This used to be a console log. Change to alert tentatively to retain the catch.
    alert('Too many stations.');
    return null;
  }
};

class LineChart extends PureComponent {
  constructor(props, context) {
    super(props, context);

    this.chartReference = React.createRef();
    this.state = {
      height: getHeight(props.isInKeyOverview),
      chartContainerWidth: '100%',
      chartOption: this.createChartOption(context.selectedGroupBy, props),
      chartData: this.createDataSet(props.xAxisData),
      legend: props.labelData,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateChartWidth());
    this.updateChartWidth();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateChart(prevProps, prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateChartWidth());
  }

  /**
   * Gets the colour for the labels on both the y and x axes.
   * Returns white if the chart is in the Key Overview card, else returns dark gray.
   * @returns {string} String - Colour code: white or dark gray
   */
  getTicksColor() {
    const { isInKeyOverview } = this.props;

    if (isInKeyOverview) {
      return CONSTANT.whiteColor;
    }

    return CONSTANT.defaultAxisColor;
  }

  /**
   * Get the font size for the y-axis, x-axis and scale label.
   * Returns:
   *    mobile view: 10
   *    desktop view < 1280px or in keyoverview or in station breakdown: 12
   *    else: 14
   * @returns {number} Number - Size of axis font: 10, 12 or 14
   */
  getAxisFontSize() {
    const { isInKeyOverview, isInStationBreakdown, theme } = this.props;

    if (window.innerWidth < theme.breakpoints.values.md) {
      return 10;
    }
    if (
      window.innerWidth < theme.breakpoints.values.lg ||
      isInKeyOverview ||
      isInStationBreakdown
    ) {
      return 12;
    }
    return 14;
  }

  /**
   * Gets the colour for the gridlines of the background of the charts.
   * Returns dark gray if chart is in Key Overview card, else returns white-gray.
   * @returns {string} String - Colour code: dark gray or white-gray
   */
  getGridlineColor() {
    const { isInKeyOverview } = this.props;

    if (isInKeyOverview) {
      return CONSTANT.keyOverviewGridlineColor;
    }

    return CONSTANT.defaultGridlineColor;
  }

  /**
   * Gets the colour for the x axis.
   * Returns white if chart is in Key Overview card, else returns gray.
   * @returns {string} String - Colour code: wite or gray
   */
  getZeroLineColor() {
    const { isInKeyOverview } = this.props;

    return isInKeyOverview ? CONSTANT.whiteColor : CONSTANT.defaultZerolineColor;
  }

  /**
   * Gets the axis colours based on which component called the chart.
   * Returns light gray if chart is in Key Overview card, else returns dark gray.
   * @returns {string} String - Colour code: light gray or dark gray
   */
  getAxisColor() {
    const { isInKeyOverview } = this.props;

    return isInKeyOverview ? CONSTANT.keyOverviewAxisColor : CONSTANT.defaultAxisColor;
  }

  /**
   * Gets the borderwidth of the line to be used for the graph.
   * Returns 2 if chart is in Key Overview card, else returns 3.
   * @returns {number} Number - 2 or 3 to indicate if the chart is in the KeyOverview card
   */
  getBorderWidth() {
    const { isInKeyOverview } = this.props;

    return isInKeyOverview ? 2 : 3;
  }

  /**
   * Gets the radius of the points on the graph for each data point.
   * Returns 0 (point not seen) if visibleNodes is false, else returns 3.
   * @returns {number} Number - 0 or 3
   */
  getPointRadius() {
    const { hasVisibleNodes } = this.props;
    let pointRadius = 0;

    if (hasVisibleNodes) {
      pointRadius = 3;
    }

    return pointRadius;
  }

  /**
   * Update the bar chart with the new data if there is a change in either the bar chart data
   */
  updateChart(prevProps, prevState) {
    const { selectedGroupBy } = this.context;
    const { labelData, xAxisData, yAxisData, yAxisLabel } = this.props;
    const { legend } = this.state;

    if (
      xAxisData !== prevProps.xAxisData ||
      yAxisData !== prevProps.yAxisData ||
      labelData !== prevProps.labelData ||
      yAxisLabel !== prevProps.yAxisLabel ||
      !checkEqualsLegend(legend, prevState.legend)
    ) {
      this.setState({
        chartOption: this.createChartOption(selectedGroupBy, this.props),
        chartData: this.createDataSet(xAxisData),
        legend: labelData,
      });
      this.updateChartWidth();
    }
  }

  /**
   * Creates the data needed to fill in the line chart.
   * Data includes styling for the lines as well as the data points to be populated.
   * @returns {Object[]} arrResult - Array of objects whereby each object containing data to plot one line
   */
  createMultiLineDataSet() {
    const { yAxisData, isInKeyOverview, labelData } = this.props;
    let colorsArray = [];

    // Colours used for the lines in the chart
    if (isInKeyOverview) {
      colorsArray = CONSTANT.keyOverviewLineChartColor;
    } else {
      colorsArray = getPastelColors(yAxisData.length);
    }

    // Styling and data points for the chart
    const arrResult = yAxisData.map((yAxisSet, index) => {
      const currentLine = {};
      currentLine.label = labelData.length === 0 ? '' : labelData[index];
      currentLine.data = yAxisSet;
      currentLine.fill = false;
      currentLine.tension = 0;
      currentLine.borderWidth = this.getBorderWidth();
      currentLine.pointRadius = this.getPointRadius();
      currentLine.pointHitRadius = 20;
      currentLine.pointBackgroundColor = CONSTANT.whiteColor;
      currentLine.backgroudColor = colorsArray[index];
      currentLine.borderColor = colorsArray[index];

      return currentLine;
    });

    return arrResult;
  }

  /**
   * Creates the data needed for both x and y axis of the line chart
   * @param {Object[]} xAxisData - Array of x axis data
   * @returns {Object} chartData - Object containing data to plot line chart
   */
  createDataSet(xAxisData) {
    const chartData = {
      labels: xAxisData,
      datasets: this.createMultiLineDataSet(),
    };

    return chartData;
  }

  /**
   * Creates a chartOption object which is required by ChartJS to style and configure
   * the rendered chart.
   */
  createChartOption(selectedGroupBy, props) {
    const { hasGoal, isInKeyOverview, yAxisLabel, t } = props;
    const { currency } = this.context;

    const chartOption = {
      maintainAspectRatio: false,
      responsive: true,
      // Options to style the x and y axes
      scales: {
        x: {
          ticks: {
            display: true,
            color: this.getTicksColor(),
            font: this.getAxisFontSize(),
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        y: {
          suggestedMin: 0, // removes negative y axis
          ticks: {
            display: true,
            maxTicksLimit: 6,
            color: this.getTicksColor(),
            font: this.getAxisFontSize(),
            padding: 6,
            beginAtZero: true,
          },
          grid: {
            display: true,
            color: (context) =>
              context.tick.value === 0 ? this.getZeroLineColor() : this.getGridlineColor(),
          },
          border: {
            display: false,
          },
          title: {
            display: true,
            text: yAxisLabel,
            font: this.getAxisFontSize(),
            color: this.getAxisColor(),
          },
        },
      },
      plugins: {
        // Options for styling the legend
        legend: {
          display: false,
        },
        // Options for styling the tooltips
        tooltip: {
          mode: 'index',
          bodyAlign: 'right',
          callbacks: {
            // Initialize the total for each chart
            afterTitle: () => {
              window.total = 0;
            },
            // Calculate total within each stack
            label: (context) => {
              const currentLine = context.dataset.label;
              const currentWaste = context.dataset.data[context.dataIndex];
              const currentWasteFormattedTo2dpAndWithCommaSeparation = Number(
                currentWaste.toFixed(2)
              ).toLocaleString('en-US', { minimumFractionDigits: 2 });

              window.total += context.raw;

              if (yAxisLabel === `${t('chart.weightAxisLabel')} (KG)`) {
                return `${currentLine}: ${currentWasteFormattedTo2dpAndWithCommaSeparation} KG`;
              }

              return `${currentLine}: ${currentWasteFormattedTo2dpAndWithCommaSeparation} ${currency}`;
            },
            // Returns the total at the bottom of the tooltip
            footer: () => {
              if (hasGoal || isInKeyOverview) {
                return null;
              }

              let groupByLegendText = '';
              if (selectedGroupBy === CONSTANT.groupByDay) {
                groupByLegendText = t('chart.groupByLegendTexts.day');
              } else if (selectedGroupBy === CONSTANT.groupByWeek) {
                groupByLegendText = t('chart.groupByLegendTexts.week');
              } else {
                groupByLegendText = t('chart.groupByLegendTexts.month');
              }
              const totalWasteFormattedTo2dpAndWithCommaSeparation = Number(
                window.total.toFixed(2)
              ).toLocaleString('en-US', { minimumFractionDigits: 2 });
              if (yAxisLabel === `${t('chart.weightAxisLabel')} (KG)`) {
                return `${groupByLegendText} ${t(
                  'chart.totalLegendText'
                )}: ${totalWasteFormattedTo2dpAndWithCommaSeparation} KG`;
              }

              return `${groupByLegendText} ${t(
                'chart.totalLegendText'
              )}: ${totalWasteFormattedTo2dpAndWithCommaSeparation} ${currency}`;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
    };

    return chartOption;
  }

  /**
   * Line chart becomes scrollable when there are more than 7 x axis data
   */
  updateChartWidth() {
    const { xAxisData } = this.props;

    // const estimatedNumberOfPoints = (window.innerWidth / 89) * 0.6;
    if (xAxisData && xAxisData.length > 7) {
      this.setState({ chartContainerWidth: `${89 * xAxisData.length}px` });
    } else {
      this.setState({ chartContainerWidth: '100%' });
    }
  }

  renderLegend() {
    const { classes, isInKeyOverview } = this.props;
    const { legend } = this.state;
    const colorsArray = getPastelColors(legend.length);

    if (!isInKeyOverview && legend.length > 1) {
      return (
        <Box component="div" className={classes.alignCenter}>
          {legend.length &&
            legend.map((item, index) => (
              <Box component="span" key={item} className={classes.legendItem}>
                <Box
                  component="span"
                  className={classes.legendColorBox}
                  style={{
                    backgroundColor: colorsArray[index],
                  }}
                />
                <Typography className={classes.legendText}>{item}</Typography>
              </Box>
            ))}
        </Box>
      );
    }
    return null;
  }

  render() {
    const { classes, isInKeyOverview } = this.props;
    const { chartContainerWidth, chartData, chartOption, height } = this.state;

    const chartHeight = getHeight(isInKeyOverview) + 15;

    return (
      <Box>
        <Box className={classes.outerBoxContainer} style={{ height: chartHeight }}>
          <Box className={classes.innerBoxContainer} style={{ width: chartContainerWidth }}>
            <Line
              ref={this.chartReference}
              height={height}
              data={JSON.parse(JSON.stringify(chartData))}
              options={chartOption}
            />
          </Box>
        </Box>
        {this.renderLegend()}
      </Box>
    );
  }
}

LineChart.contextType = AppContext;

export default withTranslation()(withTheme(withStyles(styles)(LineChart)));
