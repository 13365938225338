import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      common: {
        searchBarPlaceholderText: 'Search all food items',
        nextButtonLabel: 'NEXT',
        prevButtonLabel: 'PREV',
        display0TableRowText: 'Showing 0 {{rowHeader}}',
        displayNon0TableRowText:
          'Showing {{rangeStart}}-{{rangeEnd}} out of {{totalNumber}} {{rowHeader}}',
        confirmButtonLabel: 'Confirm',
        cancelButtonLabel: 'Cancel',
        selectButtonLabel: 'Select',
        submitButtonLabel: 'Submit',
      },
      navBar: {
        home: 'Home',
        coverInput: 'Cover Input',
        menuItemMapping: 'Menu Item Mapping',
        wasteInput: 'Waste Input',
        changePassword: 'Change Password',
        logout: 'Logout',
      },
      header: {
        home: 'HOME',
        highlightsPage: 'Highlights',
        breakdownFoodItemsPage: 'Breakdown By Food Items',
        breakdownStationsPage: 'Breakdown By Stations',
        settingsPage: 'Settings',
        contactUsPage: 'Contact Us',
        changePasswordPage: 'Change Password',
        coverInputPage: 'Cover Input',
        menuItemMappingPage: 'Menu Item Mapping',
        watchlistPage: 'Watchlist',
        wasteInputPage: 'Waste Input',
        dateRangeDisplayText:
          '{{selectedStartDate}} - {{selectedEndDate}} (Comparisons by: {{groupByDisplayText}})',
        lastUpdatedText: '*Last updated:',
      },
      calendarPicker: {
        groupComparisonsByHeader: 'Group comparisons by:',
        quickSelectHeader: 'Quick select:',
        quickSelectOptions: {
          day: {
            yesterday: 'Yesterday',
            lastSevenDays: 'Last 7 Days',
            lastThirtyDays: 'Last 30 Days',
            lastWeek: 'Last Week',
            lastFourWeeks: 'Last 4 Weeks',
            lastEightWeeks: 'Last 8 Weeks',
          },
          week: {
            lastWeek: 'Last Week',
            lastFourWeeks: 'Last 4 Weeks',
            lastTenWeeks: 'Last 10 Weeks',
          },
          month: {
            lastMonth: 'Last Month',
            lastThreeMonths: 'Last 3 Months',
            lastTwelveMonths: 'Last 12 Months',
          },
        },
        groupByDisplayOptions: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
        },
        start: 'START',
        end: 'END',
        daily: 'daily',
        weekly: 'weekly',
        monthly: 'monthly',
        days: 'days',
        weeks: 'weeks',
        or: 'or',
        tooltipPart1Text:
          '<p>Currently selecting dates grouped by <b>{{selectedGroupByText}}</b> comparison.</p>',
        tooltipPart2Text:
          '<p>Maximum date range selectable is <b>{{maximumSelectableDateRangeText}}</b>.</p>',
        tooltipPart3Text:
          "<p>*Note: You may select other <i>'Group comparisons by:'/i> options to view <b>{{notSelectedGroupByText}}</b> comparisons.</p>",
      },
      notification: {
        addMenuItemToWatchlistSuccessSnackbarText:
          'Successfully added 1 food item to the watchlist',
        alreadyOnWatchlistErrorSnackbarText: 'Item is already on watchlist',
        calculatorConnectionErrorSnackbarText:
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        addMenuItemToWatchlistErrorSnackbarText:
          'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin.',
        updateNumberOfUnreadNotificationsErrorSnackbarText:
          'Unknown error when updating number of unread notifications. Please notify admin.',
        header: 'NOTIFICATIONS',
        watchlist: 'Watchlist',
        markAllAsReadButtonLabel: 'MARK ALL AS READ',
      },
      chart: {
        totalLegendText: 'Total',
        weightAxisLabel: 'WEIGHT',
        costAxisLabel: 'COST',
        wastePerCoverAxisLabel: 'WASTE PER COVER',
        coverAxisLabel: 'COVER',
        weightText: 'Weight',
        costText: 'Cost',
        wastePerCoverText: 'Waste Per Cover',
        coverText: 'Cover',
        changeText: 'Change',
        groupByLegendTexts: {
          day: 'Day',
          week: 'Week',
          month: 'Month',
        },
      },
      appData: {
        fetchAppDataErrorSnackbarText: 'Unknown error when fetching app data. Please notify admin.',
      },
      home: {
        loginSuccessSnackbarText: "You've successfully logged in!",
        fetchWasteAnalysisForHighlightsNoServiceErrorSnackbarText:
          'No service(s) currently. Please inform Lumitics to validate the service(s).',
        calculatorConnectionErrorSnackbarText:
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        fetchWasteAnalysisForHighlightsErrorSnackbarText:
          'Unknown error during loading for waste analysis for home page. Please notify admin.',
      },
      menuItemMappingWarning: {
        header: 'Warning',
        text: 'Please note that there are <strong>{{menuItemMappingWarningCount}}</strong> food items to be mapped.',
        link: 'Click here to be directed to Menu Item Mapping page.',
      },
      keyOverview: {
        header: 'KEY OVERVIEW',
        subHeader: 'The total weight and cost may change as more items are mapped',
        totalWeightOfWaste: 'Total Weight of Waste',
        totalCostOfWaste: 'Total Cost of Waste',
        changeInWeight: 'Change in Weight (vs Baseline)',
        changeInCost: 'Change in Cost (vs Baseline)',
        wastePerCover: 'Waste per Cover',
        carbonDioxideEmissions: 'CO2 Emissions',
        changeInWastePerCover: 'Change in Waste per Cover (vs Baseline)',
        meals: 'Meals',
        carbonDioxideEmissionsAdditionallyEmitted: ' CO2 Emissions Additionally Emitted',
        mealsAdditionallyWasted: 'Meals Additionally Wasted',
        carbonDioxideEmissionsAvoided: 'CO2 Emissions Avoided',
        mealsSaved: 'Meals Saved',
        wastageByDayHeader: 'WASTAGE BY DAY',
        wastageByWeekHeader: 'WASTAGE BY WEEK',
        wastageByMonthHeader: 'WASTAGE BY MONTH',
        wastagePerformanceByPropertyHeader: 'WASTAGE PERFORMANCE BY PROPERTY',
        changeInWeightHeader: 'Change In Weight',
        changeInCostHeader: 'Change In Cost',
        changeInWastePerCoverHeader: 'Change In Waste Per Cover',
        weightTooltipText:
          '<p>This shows the total weight of waste thrown by all properties in the selected date range.</p><p>Percentage change values are derived from comparing the total weight of the previous date range<strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>',
        costTooltipText:
          '<p>This shows the total cost of waste thrown by all properties in the selected date range.</p><p>Percentage change values are derived from comparing the total cost of the previous date range<strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>',
        changeInWeightTooltipText:
          '<p>This shows the total weight of all properties within the selected date range, and the weight by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>',
        changeInCostTooltipText:
          '<p>This shows the total cost of all properties within the selected date range, and the cost by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>',
        changeInWastePerCoverTooltipText:
          '<p>This shows the total waste per cover of all properties within the selected date range, and the waste per cover by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>',
        weightHeader: 'Weight',
        costHeader: 'Cost',
        percentageHeader: 'Percentage',
      },
      breakdown: {
        byLocationHeader: 'BREAKDOWN BY LOCATION',
        byPropertyHeader: 'BREAKDOWN BY PROPERTY',
        locationDropdownLabel: 'Location',
        serviceGroupDropdownLabel: 'Service Group',
        weightToggleLabel: 'Weight',
        costToggleLabel: 'Cost',
        totalWasteHeader: 'TOTAL\nWASTE',
        totalCostHeader: 'TOTAL\nCOST',
        wastageByDayHeader: 'WASTAGE BY DAY',
        wastageByWeekHeader: 'WASTAGE BY WEEK',
        wastageByMonthHeader: 'WASTAGE BY MONTH',
        wastageByServiceHeader: 'WASTAGE BY SERVICE',
        wastageByLocationHeader: 'WASTAGE BY LOCATION',
        locationHeader: 'LOCATION',
        serviceHeader: 'SERVICE',
        serviceTypeHeader: 'Service Type',
        weightHeader: 'Weight',
        costHeader: 'Cost',
        topWastefulStationsHeader: 'TOP 3 WASTEFUL STATIONS',
        topWastedFoodItemsHeader: 'TOP {{numberOfWastedFoodItems}} WASTED FOOD ITEMS',
        itemHeader: 'ITEM',
        stationHeader: 'STATION',
        stationsLink: 'GO TO STATIONS BREAKDOWN',
        foodItemsLink: 'GO TO FOOD ITEMS BREAKDOWN',
        clearSelectionButtonLabel: 'CLEAR SELECTION',
        addItemsToWatchlistWithoutSelectionLabel: 'ADD ITEM(S) TO WATCHLIST',
        addItemsToWatchlistWithSelectionLabel:
          'ADD {{numberOfMenuItemsSelected}} ITEM(S) TO WATCHLIST',
        companyUserTooltipText:
          '<p>This shows the main wastage data of each restaurant.</p><ul><li>You can select a restaurant using the left/right arrow.</li><li>You can also switch between viewing weight and cost data using the toggle.</li></ul>',
        nonCompanyUserTooltipText:
          '<p>This shows the main wastage data of each location for <strong>{{restaurantName}}</strong>.</p><ul><li>You can select a location using the location dropdown.</li><li>You can also switch between viewing weight and cost data using the toggle.</li></ul>',
      },
      watchlist: {
        fetchWatchlistItemsErrorSnackbarText:
          'Unknown error during fetching watchlist. Please notify admin.',
        calculatorConnectionErrorSnackbarText:
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        deleteFromWatchlistSuccessSnackbarText:
          'Successfully deleted 1 food item from the watchlist',
        deleteFromWatchlistErrorSnackbarText:
          'Unknown error during deleting food item from watchlist. Please try again. If the problem persists, notify admin',
        maximumWatchlistItemsErrorSnackbarText:
          'You can only have a maximum of {{watchlistMaxSize}} items in your watchlist',
        alreadyOnWatchlistErrorSnackbarText: 'Selected food item(s) are already on the watchlist',
        addToWatchlistSuccessSnackbarText:
          'Successfully added {{numberOfWatchlistItemsAdded}} food item(s) to the watchlist',
        addToWatchlistErrorSnackbarText:
          'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin',
        updateWatchlistErrorSnackbarText:
          'Unknown error during updating rank for menu items on watchlist. Please try again. If the problem persists, notify admin',
        header: 'WATCHLIST',
        addButtonLabel: 'ADD',
        nameHeader: 'NAME',
        weightHeader: 'WEIGHT',
        costHeader: 'COST',
        propertyLabel: 'PROPERTY',
        locationLabel: 'LOCATION',
        serviceLabel: 'SERVICE',
        stationLabel: 'STATION',
        viewAllButtonLabel: 'VIEW ALL',
        watchlistModalHeader: 'ADD ITEM TO WATCHLIST',
        numberOfSearchResults: '{{numberOfSearchResults}} RESULTS',
        tooltipText:
          '<p>This shows data related to the food items that you want to track.</p><p>Note: You can track up to <strong>{{watchlistMaxSize}}</strong> food items.</p>',
        watchlistIsEmptyText: 'Your watchlist is empty.',
        toConfirmRemovingWatchlistItemText: 'Remove <b>{{menuItemName}}</b> from your watchlist?',
      },
      coverInput: {
        accessForbiddenSnackbarText:
          'Please note that you do not have the access to cover input page!',
        fetchCoverForInputErrorSnackbarText:
          'Unknown error during fetching cover data. Please notify admin.',
        saveChangesErrorSnackbarText:
          'Unknown error during updating cover data. Please notify admin.',
        tooltipText:
          '<p>Please input the corresponding special cover value according to the situation:</p><p>-1: Restaurant is opened but there is issue with tracking waste</p><p>0: Restaurant is closed</p><p>Else, input the correct cover for the day</p>',
        dateHeader: 'Date',
        warningHeader: 'WARNING',
        noteHeader: 'NOTE',
        saveChangesWarningText:
          "<p>Please ensure covers submitted are correct as changes are final and irreversible.</p><p>Please click '<strong>CONFIRM</strong>' if you want to submit the changes, and click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>",
        unsavedChangesWarningText:
          "<p>There are unsaved changes in this page.</p><p>Please click '<strong>CONFIRM</strong>' if you would like to discard the changes and proceed, or click '<strong>CANCEL</strong>' if you would like to review your changes again.</p>",
        noCoverFoundErrorText: 'No input for cover found!',
      },
      menuItemMapping: {
        accessForbiddenSnackbarText:
          'Please note that you do not have the access to menu item mapping page!',
        fetchMenuItemForMappingErrorSnackbarText:
          'Unknown error during fetching menu item data. Please notify admin.',
        saveChangesSuccessSnackbarText: 'Successfully saved changes to menu item mapping',
        saveChangesErrorSnackbarText:
          'Unknown error during creating menu item mapping. Please notify admin.',
        saveDraftSuccessSnackbarText: 'Successfully saved draft to menu item mapping',
        saveDraftErrorSnackbarText:
          'Unknown error when saving menu item mapping draft. Please notify admin.',
        noMenuItemsToBeMappedHeader: 'There are no menu items to be mapped at this moment 🎉',
        noMenuItemsToBeMappedSubtitle:
          'If you believe this to be an error, kindly contact the Lumitics team',
        saveDraftButtonLabel: 'Save Draft',
        menuItemName: 'Menu Item Name',
        foodStation: 'Food Station',
        costPerKilogram: 'Cost per Kilogram',
        warningHeader: 'WARNING',
        saveDraftWarningText:
          "<p>Please note that you are only saving draft for <strong>{{locationServiceString}}</strong>.</p><p>Please click '<strong>CONFIRM</strong>' if you want to save draft, or click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>",
        saveChangesWarningText:
          "<p>Please note that you are only submitting changes for <strong>{{locationServiceString}}</strong>.</p><p>Please ensure menu item mapping submitted are correct as changes are final and irreversible.</p><p>Please click '<strong>CONFIRM</strong>' if you want to submit the changes, or click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>",
        noteHeader: 'NOTE',
        saveChangesErrorText: 'Please check for any errors or partially filled menu items!',
        saveDraftErrorText: 'Please check for any errors!',
        noMappingFoundErrorText: 'No menu item mapping found!',
      },
      changePassword: {
        isPasswordError: 'New passwords do not meet the requirements',
        isPasswordsMismatched: 'New passwords do not match',
        submitSuccessSnackbarText: 'Password has been changed successfully',
        submitErrorSnackbarText: 'Unknown error during update password. Please notify admin.',
        currentPasswordPlaceholderText: 'Current Password',
        newPasswordPlaceholderText: 'New Password',
        confirmNewPasswordPlaceholderText: 'Confirm New Password',
        requirementsHeader: 'Requirements',
        lengthBetween8And20Characters: 'Length between 8 and 20 characters',
        lowercaseCharacter: 'A lowercase character',
        uppercaseCharacter: 'A uppercase character',
        specialCharacter: 'A special character',
        numericCharacter: 'A numeric character',
      },
      breakdownByStation: {
        calculatorConnectionErrorSnackbarText:
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        fetchStationBreakdownErrorSnackbarText:
          'Unknown error during fetching station waste analysis. Please notify admin.',
        fetchStationBreakdownChartsErrorSnackbarText:
          'Unknown error during fetching selected station waste analysis for charts. Please notify admin.',
        header: 'BREAKDOWN BY STATIONS',
        tooltipText:
          '<p>This shows the stations-related wastage data.</p><p>Add or remove stations from the charts by checking or unchecking the entries in the table below.</p><p>Percentage change values are derived from comparing data of the previous date range <strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>',
        downloadTableButtonLabel: 'Download Table',
        locationDropdownLabel: 'Location',
        serviceGroupDropdownLabel: 'Service Group',
        wastageByStationsHeader: 'WASTAGE BY STATIONS',
        weightHeader: 'Weight',
        costHeader: 'Cost',
        changeInWeightHeader: 'Change In Weight',
        changeInCostHeader: 'Change In Cost',
        stationWastageByDayHeader: 'STATION WASTAGE BY DAY',
        stationWastageByWeekHeader: 'STATION WASTAGE BY WEEK',
        stationWastageByMonthHeader: 'STATION WASTAGE BY MONTH',
        stationHeader: 'Station',
        serviceHeader: 'Service',
      },
      breakdownByFoodItem: {
        fetchSelectedMenuItemWasteAnalysisForChartsErrorSnackbarText:
          'Unknown error during fetching selected menu item waste analysis for charts. Please notify admin.',
        updateArrSelectedMenuItemServiceWasteAnalysisErrorSnackbarText:
          'You can only select up to {{foodItemsBreakdownSelectionMaxSize}} food items at a time',
        calculatorConnectionErrorSnackbarText:
          'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        fetchWatchlistItemsErrorSnackbarText:
          'Unknown error during loading for food items wastage. Please notify admin.',
        fetchArrLocationWithMenuItemServiceWasteAnalysisErrorSnackbarText:
          'Unknown error during fetching food items waste analysis. Please notify admin.',
        header: 'BREAKDOWN BY FOOD ITEMS',
        tooltipText:
          '<p>This shows the wastage data of food items.</p><p>You may check on multiple food items to add them to your watchlist.</p><p>Percentage change values are derived from comparing data of the previous date range <strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>',
        downloadTableButtonLabel: 'Download Table',
        locationDropdownLabel: 'Location',
        serviceGroupDropdownLabel: 'Service Group',
        foodItemsWastageByDayHeader: 'FOOD ITEMS WASTAGE BY DAY',
        foodItemsWastageByWeekHeader: 'FOOD ITEMS WASTAGE BY WEEK',
        foodItemsWastageByMonthHeader: 'FOOD ITEMS WASTAGE BY MONTH',
        weightHeader: 'Weight',
        costHeader: 'Cost',
        wastePerCoverHeader: 'Waste Per Cover',
        costPerCoverHeader: 'Cost Per Cover',
        changeInWastePerCoverHeader: 'Change In Waste Per Cover',
        changeInCostPerCoverHeader: 'Change In Cost Per Cover',
        itemHeader: 'Item',
        itemDisplayText: 'food items',
        stationHeader: 'Station',
        serviceHeader: 'Service',
        clearSelectionButtonLabel: 'Clear Selection',
        addItemsToWatchlistWithoutSelectionLabel: 'ADD ITEM(S) TO WATCHLIST',
        addItemsToWatchlistWithSelectionLabel:
          'ADD {{numberOfMenuItemsSelected}} ITEM(S) TO WATCHLIST',
      },
      menuItemImage: {
        throwsHeader: 'throw(s)',
        noPreviewForCurrentPeriod: 'No preview for this period',
        footnote:
          '*Note: Information is only valid for {{selectedStartDate}} to {{selectedEndDate}}',
      },
      wasteInput: {
        accessForbiddenSnackbarText:
          'Please note that you do not have the access to waste input page!',
        emptyInputErrorSnackbarText: 'Please ensure weight and menu item are not empty!',
        saveChangesSuccessSnackbarText: 'Successfully added waste',
        saveChangesErrorSnackbarText: 'Error when adding waste. Please notify admin.',
        fetchMenuItemErrorText: 'Unknown error when fetching menu item data. Please notify admin.',
        noManualInputEnabledServiceFoundErrorHeader: 'No waste input enabled services found',
        noManualInputEnabledServiceFoundErrorSubtitle:
          'If you believe this to be an error, kindly contact the Lumitics team',
        locationServiceDropdownLabel: 'Location - Service',
        inputWasteWeightHeader: 'Input Waste Weight',
        selectMenuItemHeader: 'Select Menu Item',
        noMenuItemFoundErrorText:
          'No menu items found. Please add menu items before inputting waste.',
      },
    },
  },
  cn: {
    translation: {
      common: {
        searchBarPlaceholderText: '搜索所有菜品',
        nextButtonLabel: '下一页',
        prevButtonLabel: '上一页',
        display0TableRowText: '显示 0 {{rowHeader}}',
        displayNon0TableRowText:
          '显示第 {{rangeStart}} - 第{{rangeEnd}} 的{{rowHeader}}， 共 {{totalNumber}} {{rowHeader}}',
        confirmButtonLabel: '确认', // 'Confirm'
        cancelButtonLabel: '取消', // 'Cancel'
        selectButtonLabel: '选择', // Select
        submitButtonLabel: '提交', // 'Submit'
      },
      navBar: {
        home: '首页',
        coverInput: '输入客数',
        menuItemMapping: '菜品识别',
        wasteInput: '输入数据', // Temporary put as such
        changePassword: '更改密码',
        logout: '登出',
      },
      header: {
        home: '首页',
        highlightsPage: '亮点',
        breakdownFoodItemsPage: '按菜品分列',
        breakdownStationsPage: '按食物区分列',
        settingsPage: '设置',
        contactUsPage: '联系我们',
        changePasswordPage: '更改密码',
        coverInputPage: '输入客数',
        menuItemMappingPage: '菜品识别',
        watchlistPage: '关注列表',
        wasteInputPage: '输入数据', // Temporary put as such
        dateRangeDisplayText:
          '{{selectedStartDate}} - {{selectedEndDate}} （时间跨度：{{groupByDisplayText}}）',
        lastUpdatedText: '*最后更新于：',
      },
      chart: {
        totalLegendText: '总计', // 'Total'
        weightAxisLabel: '重量',
        costAxisLabel: '成本',
        wastePerCoverAxisLabel: '人均食物浪费',
        coverAxisLabel: '客数',
        weightText: '重量',
        costText: '成本',
        wastePerCoverText: '人均食物浪费', // 'Waste per Cover'
        coverText: '客数', // 'Cover'
        changeText: '变化', // 'Change'
        groupByLegendTexts: {
          day: '本日',
          week: '本周',
          month: '本月',
        },
      },
      calendarPicker: {
        groupComparisonsByHeader: '时间跨度：',
        quickSelectHeader: '快捷选择：',
        quickSelectOptions: {
          day: {
            yesterday: '前 1 天',
            lastSevenDays: '前 7 天',
            lastThirtyDays: '前 30 天',
            lastWeek: '前 1 周',
            lastFourWeeks: '前 4 周',
            lastEightWeeks: '前 8 周',
          },
          week: {
            lastWeek: '前 1 周',
            lastFourWeeks: '前 4 周',
            lastTenWeeks: '前 10 周',
          },
          month: {
            lastMonth: '前 1 个月',
            lastThreeMonths: '前 3 个月',
            lastTwelveMonths: '前 12 个月',
          },
        },
        groupByDisplayOptions: {
          day: '日',
          week: '周',
          month: '月',
        },
        start: '开始日期',
        end: '结束日期',
        daily: '每日',
        weekly: '每周',
        monthly: '每月',
        days: '天',
        weeks: '周',
        or: '或',
        tooltipPart1Text: '<p>目前选择的日期跨度为<b>{{selectedGroupByText}}</b>。</p>', // '<p>Currently selecting dates grouped by <b>{{selectedGroupByText}}</b> comparison.</p>'
        tooltipPart2Text:
          '<p>可选择的日期范围限制在 <b>{{maximumSelectableDateRangeText}}</b> 内。</p>', // '<p>Maximum date range selectable is <b>{{maximumSelectableDateRangeText}}</b>.</p>'
        tooltipPart3Text:
          '<p>*注意: 您可以选择其它 <i>‘时间跨度：’</i><b>{{notSelectedGroupByText}}</b>来查看不同范围的食物浪费数据。</p>', // '<p>*Note: You may select other <i>"Group comparisons by:"</i> options to view <b>{{notSelectedGroupByText}}</b> comparisons.</p>'
      },
      notification: {
        addMenuItemToWatchlistSuccessSnackbarText: '成功添加了一个菜品到关注列表', // 'Successfully added 1 food item to the watchlist'
        alreadyOnWatchlistErrorSnackbarText: '菜品已在关注列表中', // 'Item is already on watchlist'
        calculatorConnectionErrorSnackbarText:
          '连接错误。请您稍等几分钟后再重新加载网页。若持续遇到错误，请通知Lumitics的管理员。', // 'Connection error. Please try again a few minutes later. If the problem persists, notify admin.'
        addMenuItemToWatchlistErrorSnackbarText:
          '添加菜品时遇到未知错误。请您再尝试一次。若持续遇到错误，请通知Lumitics的管理员。', //  'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin.'
        updateNumberOfUnreadNotificationsErrorSnackbarText:
          '更新未读通知数量时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error when updating number of unread notifications. Please notify admin.'
        header: '通知', // 'NOTIFICATIONS'
        watchlist: '关注列表', // 'Watchlist'
        markAllAsReadButtonLabel: '标记所有通知为已读', // 'MARK ALL AS READ'
      },
      appData: {
        fetchAppDataErrorSnackbarText: '加载数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error when fetching app data. Please notify admin.'
      },
      home: {
        loginSuccessSnackbarText: '您已成功登入！', // "You've successfully logged in!"
        fetchWasteAnalysisForHighlightsNoServiceErrorSnackbarText:
          '目前没有正在营业的餐饮时段。请通知Lumitics的管理员。', // 'No service(s) currently. Please inform Lumitics to validate the service(s).'
        calculatorConnectionErrorSnackbarText:
          '连接错误。请您稍等几分钟后再重新加载网页。若持续遇到错误，请通知Lumitics的管理员。', // 'Connection error. Please try again a few minutes later. If the problem persists, notify admin.'
        fetchWasteAnalysisForHighlightsErrorSnackbarText:
          '加载食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during loading for waste analysis for home page. Please notify admin.'
      },
      menuItemMappingWarning: {
        header: ' 注意', // 'Warning'
        text: '您有 <strong>{{menuItemMappingWarningCount}}</strong> 菜品还未识别。', // 'Please note that there are <strong>{{menuItemMappingWarningCount}}</strong> food items to be mapped.'
        link: '点击此链接打开“菜品识别”页面。', // 'Click here to be directed to Menu Item Mapping page.'
      },
      keyOverview: {
        header: '概览', // 'KEY OVERVIEW'
        subHeader: '总重量和总成本可能会因为新识别的菜品而有所变动', // 'The total weight and cost may change as more items are mapped'
        totalWeightOfWaste: '总重量', // 'Total Weight of Waste'
        totalCostOfWaste: '总成本', // 'Total Cost of Waste'
        changeInWeight: '重量变化 （与基准相比）', // 'Change in Weight (vs Baseline)'
        changeInCost: '成本变化（与基准相比）', // 'Change in Cost (vs Baseline)'
        wastePerCover: '人均食物浪费', // 'Waste per Cover'
        carbonDioxideEmissions: '二氧化碳排放量', // CO2 Emissions
        changeInWastePerCover: '人均食物浪费（与基准相比）', // 'Change in Waste per Cover (vs Baseline)'
        meals: '餐份', // 'Meals'
        carbonDioxideEmissionsAdditionallyEmitted: '增加了的二氧化碳排放量', // 'CO2 Emissions Additionally Emitted'
        mealsAdditionallyWasted: '多浪费了的餐份', // 'Meals Additionally Wasted'
        carbonDioxideEmissionsAvoided: '避免了的二氧化碳排放量', // 'CO2 Emissions Avoided'
        mealsSaved: '节省了的餐份', // 'Meals Saved'
        wastageByDayHeader: '每日食物浪费', // 'WASTAGE BY DAY'
        wastageByWeekHeader: '每周食物浪费', // 'WASTAGE BY WEEK'
        wastageByMonthHeader: '每月食物浪费', // 'WASTAGE BY MONTH'
        wastagePerformanceByPropertyHeader: '各酒店的食物浪费 （和前段期间相比）', // 'WASTAGE PERFORMANCE BY PROPERTY'
        changeInWeightHeader: '重量变化', // 'Change In Weight'
        changeInCostHeader: '成本变化', // 'Change In Cost'
        changeInWastePerCoverHeader: '人均食物浪费变化 ', // 'Change In Waste Per Cover'
        weightTooltipText:
          '<p>这里展示了这段期间内所有酒店的食物浪费的总重量。</p><p>百分比变化是根据前段期间 <strong>{{previousDateRange}}</strong> 和现段期间 <strong>{{selectedDateRange}}</strong> 的数值来计算的。</p>', // '<p>This shows the total weight of waste thrown by all properties in the selected date range.</p><p>Percentage change values are derived from comparing the total weight of the previous date range<strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>'
        costTooltipText:
          '<p>这里展示了这段期间内所有酒店的食物浪费的总成本。</p><p>百分比变化是根据前段期间 <strong>{{previousDateRange}}</strong> 和现段期间 <strong>{{selectedDateRange}}</strong> 的数值来计算的。</p>', // '<p>This shows the total cost of waste thrown by all properties in the selected date range.</p><p>Percentage change values are derived from comparing the total cost of the previous date range<strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>'
        changeInWeightTooltipText:
          '<p>这里展示了这段期间内所有酒店的食物浪费的总重量，以及各个餐厅与基准相比的重量变化。</p><p>基准时期定义为一开始追踪食物浪费的那段期间，因为在那期间里，餐厅还未实施任何减少食物浪费的解决方案。</p>', // '<p>This shows the total weight of all properties within the selected date range, and the weight by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>'
        changeInCostTooltipText:
          '<p>这里展示了这段期间内所有酒店的食物浪费的总成本，以及各个餐厅与基准相比的成本变化。</p><p>基准时期定义为一开始追踪食物浪费的那段期间，因为在那期间里，餐厅还未实施任何减少食物浪费的解决方案。</p>', // '<p>This shows the total cost of all properties within the selected date range, and the cost by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>'
        changeInWastePerCoverTooltipText:
          '<p>这里展示了这段期间内所有酒店的食物浪费的总人均食物浪费，以及各个餐厅与基准相比的人均食物浪费变化。</p><p>基准时期定义为一开始追踪食物浪费的那段期间，因为在那期间里，餐厅还未实施任何减少食物浪费的解决方案。</p>', // '<p>This shows the total waste per cover of all properties within the selected date range, and the waste per cover by each location when compared to the baseline period.</p><p>Baseline period is the period at the start of food waste tracking, when no reduction plan was implemented.</p>'
        weightHeader: '重量', // 'Weight'
        costHeader: '成本', // 'Cost'
        percentageHeader: '重量', // 'Percentage'
      },
      breakdown: {
        byLocationHeader: '按餐厅分列', // 'BREAKDOWN BY LOCATION'
        byPropertyHeader: '按酒店分列', // 'BREAKDOWN BY PROPERTY'
        locationDropdownLabel: '餐厅', // 'Location'
        serviceGroupDropdownLabel: '餐饮时段组', // 'Service Group'
        weightToggleLabel: '重量', // 'Weight'
        costToggleLabel: '成本', // 'Cost'
        totalWasteHeader: '总重量', // 'TOTAL\nWASTE'
        totalCostHeader: '总成本', // 'TOTAL\nCOST'
        wastageByDayHeader: '每日食物浪费', // 'WASTAGE BY DAY'
        wastageByWeekHeader: '每周食物浪费', // 'WASTAGE BY WEEK'
        wastageByMonthHeader: '每月食物浪费', // 'WASTAGE BY MONTH'
        wastageByServiceHeader: '餐饮时段数据细表', // 服务数据细表 // 'WASTAGE BY SERVICE'
        wastageByLocationHeader: '餐厅数据细表', // 'WASTAGE BY LOCATION',
        locationHeader: '餐厅', // 'LOCATION'
        serviceHeader: '餐饮时段', // 'SERVICE'
        serviceTypeHeader: '餐饮时段的类别', // 'Service Type'
        weightHeader: '重量', // 'Weight'
        costHeader: '成本 ', // 'Cost'
        topWastefulStationsHeader: '前 3 名最高食物浪费的食物区', // 前3名最高食物浪费的档口 //'TOP 3 WASTEFUL STATIONS'
        topWastedFoodItemsHeader: '前 {{numberOfWastedFoodItems}} 名最高食物浪费的菜品', // 'TOP {{numberOfWastedFoodItems}} WASTED FOOD ITEMS'
        itemHeader: ' 菜品', // 'ITEM'
        stationHeader: '食物区', // 'STATION'
        stationsLink: '点击此链接打开“按食物区分列” 页面', // 'GO TO STATIONS BREAKDOWN'
        foodItemsLink: '点击此链接打开“按菜品分列” 页面', // 'GO TO FOOD ITEMS BREAKDOWN'
        clearSelectionButtonLabel: '清除选择', // 'CLEAR SELECTION'
        addItemsToWatchlistWithoutSelectionLabel: '添加菜品至关注列表中', // 'ADD ITEM(S) TO WATCHLIST'
        addItemsToWatchlistWithSelectionLabel:
          '添加 {{numberOfMenuItemsSelected}} 菜品至关注列表中', // 'ADD {{numberOfMenuItemsSelected}} ITEM(S) TO WATCHLIST'
        companyUserTooltipText:
          '<p>这里展示了各个酒店的食物浪费数据。</p><ul><li>您可以使用左右箭头来选择您想查看的酒店</li><li>您也可以使用“重量”及“成本”的切换按钮来查看以不同单位计算的食物浪费数据</li></ul>', // '<p>This shows the main wastage data of each restaurant.</p><ul><li>You can select a restaurant using the left/right arrow.</li><li>You can also switch between viewing weight and cost data using the toggle.</li></ul>',
        nonCompanyUserTooltipText:
          '<p>这里展示了 <strong>{{restaurantName}}</strong> 各个餐厅的食物浪费数据。</p><ul><li>您可以使用左右箭头来选择您想查看的餐厅</li><li>您也可以使用“重量”及“成本”的切换按钮来查看以不同单位计算的食物浪费数据</li></ul>', // '<p>This shows the main wastage data of each location for <strong>{{restaurantName}}</strong>.</p><ul><li>You can select a location using the location dropdown.</li><li>You can also switch between viewing weight and cost data using the toggle.</li></ul>',
      },
      watchlist: {
        fetchWatchlistItemsErrorSnackbarText:
          '加载关注列表时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching watchlist. Please notify admin.',
        calculatorConnectionErrorSnackbarText:
          '连接错误。请您稍等几分钟后再重新加载网页。若持续遇到错误，请通知Lumitics的管理员。', // 'Connection error. Please try again a few minutes later. If the problem persists, notify admin.',
        deleteFromWatchlistSuccessSnackbarText: '成功从关注列表中删除 1 菜品', // 'Successfully deleted 1 food item from the watchlist',
        deleteFromWatchlistErrorSnackbarText:
          '从关注列表中删除菜品时遇到未知错误。请您再尝试一次。若持续遇到错误，请通知Lumitics的管理员。', // 'Unknown error during deleting food item from watchlist. Please try again. If the problem persists, notify admin'
        maximumWatchlistItemsErrorSnackbarText: '您最多可以同时关注 {{watchlistMaxSize}} 个菜品', // 'You can only have a maximum of {{watchlistMaxSize}} items in your watchlist'
        alreadyOnWatchlistErrorSnackbarText: '选中的菜品已在关注列表中', // 'Selected food item(s) are already on the watchlist'
        addToWatchlistSuccessSnackbarText:
          '成功添加了 {{numberOfWatchlistItemsAdded}} 菜品至关注列表中', // 'Successfully added {{numberOfWatchlistItemsAdded}} food item(s) to the watchlist'
        addToWatchlistErrorSnackbarText:
          '添加菜品至关注列表时遇到未知错误。请您再尝试一次。若持续遇到错误，请通知Lumitics的管理员。', // 'Unknown error during adding food item to watchlist. Please try again. If the problem persists, notify admin'
        updateWatchlistErrorSnackbarText:
          '编辑关注列表的顺序时遇到未知错误。请您再尝试一次。若持续遇到错误，请通知Lumitics的管理员。', // 'Unknown error during updating rank for menu items on watchlist. Please try again. If the problem persists, notify admin'
        header: '关注列表', // 'WATCHLIST'
        addButtonLabel: '添加', // 'ADD'
        nameHeader: '菜品', // 'NAME'
        weightHeader: '重量', // 'WEIGHT'
        costHeader: '成本', // 'COST'
        propertyLabel: '酒店', // 'PROPERTY'
        locationLabel: '餐厅', // 'LOCATION'
        serviceLabel: '餐饮时段', // 'SERVICE'
        stationLabel: '食物区', // 'STATION'
        viewAllButtonLabel: '查看全部', // 'VIEW ALL'
        watchlistModalHeader: '添加菜品至关注列表中', // 'ADD ITEM TO WATCHLIST'
        numberOfSearchResults: '{{numberOfSearchResults}} 搜寻结果', // '{{numberOfSearchResults}} RESULTS'
        tooltipText:
          '<p>这里展示了您想关注的菜品的食物浪费数据。</p><p>*注意：您最多可以同时关注 <strong>{{watchlistMaxSize}}</strong> 菜品。</p>', // '<p>This shows data related to the food items that you want to track.</p><p>Note: You can track up to <strong>{{watchlistMaxSize}}</strong> food items.</p>'
        watchlistIsEmptyText: '您目前没有关注任何菜品。',
        toConfirmRemovingWatchlistItemText: '确定从关注列表中删除 <b>{{menuItemName}}</b> 吗?',
      },
      coverInput: {
        accessForbiddenSnackbarText: '请注意， 您没有权限访问“输入客数”页面!', // 'Please note that you do not have the access to cover input page!'
        fetchCoverForInputErrorSnackbarText: '加载客数数值时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching cover data. Please notify admin.'
        saveChangesErrorSnackbarText: '更改客数数值时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during updating cover data. Please notify admin.'
        tooltipText:
          '<p>请根据以下情况来输入正确的客数数值：</p><p>-1: 餐厅有营业但因某些原因无法追踪食物浪费</p><p>0: 餐厅当日休息</p><p>若没有以上特殊情况， 请输入当天确切的客数数值</p>', // '<p>Please input the corresponding special cover value according to the situation:</p><p>-1: Restaurant is opened but there is issue with tracking waste</p><p>0: Restaurant is closed</p><p>Else, input the correct cover for the day</p>'
        dateHeader: '日期', // 'Date'
        warningHeader: '警告', // 'WARNING'
        noteHeader: '注意', // 'NOTE'
        saveChangesWarningText:
          "<p>请务必确认更改后的客数都是正确的。一旦成功提交，客数数值将不无法再作更改。</p><p>若您已确定提交，点击 '<strong>确认</strong>'。否则，点击 '<strong>取消</strong>' 关闭弹窗返回页面再次核对客数数值。</p>", // "<p>Please ensure covers submitted are correct as changes are final and irreversible.</p><p>Please click '<strong>CONFIRM</strong>' if you want to submit the changes, and click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>"
        unsavedChangesWarningText:
          "<p>此页面有未提交的变更。</p><p>若您想放弃此页面的更改前往另一页，点击 '<strong>确认</strong>'。否则，点击 '<strong>取消</strong>' 关闭弹窗。</p>", // "<p>There are unsaved changes in this page.</p><p>Please click '<strong>CONFIRM</strong>' if you would like to discard the changes and proceed, or click '<strong>CANCEL</strong>' if you would like to review your changes again.</p>"
        noCoverFoundErrorText: '没有需要输入的客数!', // 'No input for cover found!'
      },
      menuItemMapping: {
        accessForbiddenSnackbarText: '请注意， 您没有权限访问“菜品识别”页面!', // 'Please note that you do not have the access to menu item mapping page!'
        fetchMenuItemForMappingErrorSnackbarText:
          '加载菜品识别数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching menu item data. Please notify admin.'
        saveChangesSuccessSnackbarText: '成功提交菜品资料', // 'Successfully saved changes to menu item mapping'
        saveChangesErrorSnackbarText: '提交菜品资料时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during creating menu item mapping. Please notify admin.'
        saveDraftSuccessSnackbarText: '成功保存菜品资料的草稿', // 'Successfully saved draft to menu item mapping'
        saveDraftErrorSnackbarText: '保存菜品资料的草稿时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error when saving menu item mapping draft. Please notify admin.'
        noMenuItemsToBeMappedHeader: '目前没有需要识别的菜品 🎉', // 'There are no menu items to be mapped at this moment 🎉'
        noMenuItemsToBeMappedSubtitle: '若您认为此页有误, 请联系Lumitics的管理员。', // 'If you believe this to be an error, kindly contact the Lumitics team'
        saveDraftButtonLabel: '保存草稿', // 'Save Draft'
        menuItemName: '菜品名称', // 'Menu Item Name'
        foodStation: '食物区', // 'Food Station'
        costPerKilogram: '成本（每公斤）', // 'Cost per Kilogram'
        warningHeader: '警告', // 'WARNING'
        saveDraftWarningText:
          "<p>请注意，这只会保存 <strong>{{locationServiceString}}</strong> 的菜品资料的草稿.</p><p>若您已确定要保存，点击 '<strong>确认</strong>'。否则，点击 '<strong>取消</strong>' 关闭弹窗返回页面再次核对菜品资料。</p>", //  "<p>Please note that you are only saving draft for <strong>{{locationServiceString}}</strong>.</p><p>Please click '<strong>CONFIRM</strong>' if you want to save draft, or click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>"
        saveChangesWarningText:
          "<p>请注意，这只会提交 <strong>{{locationServiceString}}</strong> 的菜品资料.</p><p>请务必确认新菜品的资料都是正确的。一旦成功提交，这些资料将无法再作更改。</p><p>若您已确定提交，点击 '<strong>确认</strong>'。否则，点击 '<strong>取消</strong>' 关闭弹窗返回页面再次核对菜品资料.</p>", // "<p>Please note that you are only submitting changes for <strong>{{locationServiceString}}</strong>.</p><p>Please ensure menu item mapping submitted are correct as changes are final and irreversible.</p><p>Please click '<strong>CONFIRM</strong>' if you want to submit the changes, or click '<strong>CANCEL</strong>' if you would like to check the changes again.</p>"
        noteHeader: '注意', // 'NOTE'
        saveChangesErrorText: '请确保您所填写的菜品资料没有任何的错误，并且是完整的!', // 'Please check for any errors or partially filled menu items!'
        saveDraftErrorText: '请确保您所填写的菜品资料没有任何的错误！', // 'Please check for any errors!'
        noMappingFoundErrorText: '没有需要识别的菜品!', // 'No menu item mapping found!'
      },
      changePassword: {
        isPasswordError: '新密码不符合规则', // 'New passwords do not meet the requirements'
        isPasswordsMismatched: '新密码与确认密码不一致', // 'New passwords do not match'
        submitSuccessSnackbarText: '成功更改密码', // 'Password has been changed successfully'
        submitErrorSnackbarText: '更改密码时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during update password. Please notify admin.'
        currentPasswordPlaceholderText: '旧密码', // 'Current Password'
        newPasswordPlaceholderText: '新密码', // 'New Password'
        confirmNewPasswordPlaceholderText: '确认新密码', // 'Confirm New Password'
        requirementsHeader: '密码规则设置', // 'Requirements'
        lengthBetween8And20Characters: '密码长度为8至20位', // 'Length between 8 and 20 characters'
        lowercaseCharacter: '至少一个小写字母', // 'A lowercase character'
        uppercaseCharacter: '至少一个大写字母', // 'A uppercase character'
        specialCharacter: '至少一个特殊字符', // 'A special character'
        numericCharacter: '至少一个数字', // 'A numeric character'
      },
      breakdownByStation: {
        calculatorConnectionErrorSnackbarText:
          '连接错误。请您稍等几分钟后再重新加载网页。若持续遇到错误，请通知Lumitics的管理员。', // 'Connection error. Please try again a few minutes later. If the problem persists, notify admin.'
        fetchStationBreakdownErrorSnackbarText:
          '加载全部的食物区食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching station waste analysis. Please notify admin.'
        fetchStationBreakdownChartsErrorSnackbarText:
          '加载选中的食物区食物浪费数据图时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching selected station waste analysis for charts. Please notify admin.'
        header: '按食物区分列', // 'BREAKDOWN BY STATIONS'
        tooltipText:
          '<p>这里展示了各个食物区的食物浪费数据。</p><p>您可以点击下方表格里的食物区来改变上方图表展示的食物区。</p><p>百分比变化是根据前段期间 <strong>{{previousDateRange}}</strong> 和现段期间 <strong>{{selectedDateRange}}</strong> 的数值来计算的。</p>', // '<p>This shows the stations-related wastage data.</p><p>Add or remove stations from the charts by checking or unchecking the entries in the table below.</p><p>Percentage change values are derived from comparing data of the previous date range <strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>'
        downloadTableButtonLabel: '下载数据表格', // 'Download Table'
        locationDropdownLabel: '餐厅', // 'Location'
        serviceGroupDropdownLabel: '餐饮时段组', // 'Service Group'
        wastageByStationsHeader: '食物区总食物浪费', // 'WASTAGE BY STATIONS'
        weightHeader: '重量', // 'Weight'
        costHeader: '成本', // 'Cost'
        changeInWeightHeader: '成本变化', // 'Change In Weight'
        changeInCostHeader: '重量变化', // 'Change In Cost'
        stationWastageByDayHeader: '每日食物区食物浪费', // 'STATION WASTAGE BY DAY'
        stationWastageByWeekHeader: '每周食物区食物浪费', // 'STATION WASTAGE BY WEEK'
        stationWastageByMonthHeader: '每年食物区食物浪费', // 'STATION WASTAGE BY MONTH'
        stationHeader: '食物区', // 'Station'
        serviceHeader: '餐饮时段', // 'Service'
      },
      breakdownByFoodItem: {
        fetchSelectedMenuItemWasteAnalysisForChartsErrorSnackbarText:
          '加载选中的菜品食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching selected menu item waste analysis for charts. Please notify admin.'
        updateArrSelectedMenuItemServiceWasteAnalysisErrorSnackbarText:
          '您最多可以同时选择 {{foodItemsBreakdownSelectionMaxSize}} 菜品', // 'You can only select up to {{foodItemsBreakdownSelectionMaxSize}} food items at a time'
        calculatorConnectionErrorSnackbarText:
          '连接错误。请您稍等几分钟后再重新加载网页。若持续遇到错误，请通知Lumitics的管理员。', // 'Connection error. Please try again a few minutes later. If the problem persists, notify admin.'
        fetchWatchlistItemsErrorSnackbarText:
          '加载全部的菜品食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during loading for food items wastage. Please notify admin.'
        fetchArrLocationWithMenuItemServiceWasteAnalysisErrorSnackbarText:
          '加载全部的菜品食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error during fetching food items waste analysis. Please notify admin.'
        header: '按菜品分列', // 'BREAKDOWN BY FOOD ITEMS'
        tooltipText:
          '<p>这里展示了各个菜品的食物浪费数据。</p><p>您可以添加菜品至关注列表中以更容易地观察该菜品食物浪费数据的变化。</p><p>百分比变化是根据前段期间 <strong>{{previousDateRange}}</strong> 和现段期间 <strong>{{selectedDateRange}}</strong> 的数值来计算的。</p>', // '<p>This shows the wastage data of food items.</p><p>You may check on multiple food items to add them to your watchlist.</p><p>Percentage change values are derived from comparing data of the previous date range <strong>{{previousDateRange}}</strong> and the current selected date range <strong>{{selectedDateRange}}</strong>.</p>'
        downloadTableButtonLabel: '下载数据表格', // 'Download Table'
        locationDropdownLabel: '餐厅', // 'Location'
        serviceGroupDropdownLabel: '餐饮时段组', // 'Service Group'
        foodItemsWastageByDayHeader: '每日菜品食物浪费', // 'FOOD ITEMS WASTAGE BY DAY'
        foodItemsWastageByWeekHeader: '每周菜品食物浪费', // 'FOOD ITEMS WASTAGE BY WEEK'
        foodItemsWastageByMonthHeader: '每月菜品食物浪费', // 'FOOD ITEMS WASTAGE BY MONTH'
        weightHeader: '重量', // 'Weight'
        costHeader: '成本', // 'Cost'
        wastePerCoverHeader: '人均食物浪费重量', // 'Waste Per Cover'
        costPerCoverHeader: '人均食物浪费成本', // 'Cost Per Cover'
        changeInWastePerCoverHeader: '人均食物浪费重量变化', // 'Change In Waste Per Cover'
        changeInCostPerCoverHeader: '人均食物浪费成本变化', // 'Change In Cost Per Cover'
        itemHeader: '菜品', // 'Item'
        itemDisplayText: '菜品', // 'food items'
        stationHeader: '食物区', // 'Station'
        serviceHeader: '餐饮时段', // 'Service'
        clearSelectionButtonLabel: '清除选择', // 'Clear Selection'
        addItemsToWatchlistWithoutSelectionLabel: '添加菜品至关注列表中', // 'ADD ITEM(S) TO WATCHLIST'
        addItemsToWatchlistWithSelectionLabel:
          '添加了 {{numberOfMenuItemsSelected}} 菜品至关注列表中', // 'ADD {{numberOfMenuItemsSelected}} ITEM(S) TO WATCHLIST'
      },
      menuItemImage: {
        throwsHeader: '丢弃次数', // 'throw(s)'
        noPreviewForCurrentPeriod: '此期间没有预览数据', // 'No preview for this period'
        footnote: '*注意：这里只展示了 {{selectedStartDate}} 至 {{selectedEndDate}} 的数据', // '*Note: Information is only valid for {{selectedStartDate}} to {{selectedEndDate}}'
      },
      wasteInput: {
        accessForbiddenSnackbarText: '请注意， 您没有权限访问“输入数据”页面!', //  'Please note that you do not have the access to waste input page!'
        emptyInputErrorSnackbarText: '请确保您已输入了重量，并且选择了菜品!', // 'Please ensure weight and menu item are not empty!'
        saveChangesSuccessSnackbarText: '成功添加了食物浪费数据', // 'Successfully added waste'
        saveChangesErrorSnackbarText: '添加食物浪费数据时遇到未知错误。请通知Lumitics的管理员。', // 'Error when adding waste. Please notify admin.'
        fetchMenuItemErrorText: '加载菜品数据时遇到未知错误。请通知Lumitics的管理员。', // 'Unknown error when fetching menu item data. Please notify admin.'
        noManualInputEnabledServiceFoundErrorHeader: '没有找到任何需要手动输入食物浪费的餐饮时段', // 'No waste input enabled services found'
        noManualInputEnabledServiceFoundErrorSubtitle: '若您认为此页有误, 请联系Lumitics的管理员。', // 'If you believe this to be an error, kindly contact the Lumitics team'
        locationServiceDropdownLabel: '餐厅 - 服务时段', // 'Location - Service'
        inputWasteWeightHeader: '输入重量', // 'Input Waste Weight'
        selectMenuItemHeader: '选择菜品', // 'Select Menu Item'
        noMenuItemFoundErrorText: '没有找到任何菜品. 请先添加菜品再来输入食物浪费数据.', //  'No menu items found. Please add menu items before inputting waste.'
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // default language 'en'
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
