import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import BarChart from '../../common/chart/BarChart';
import { CONSTANT } from '../../../AppContext';

class WastePerCoverShowMore extends PureComponent {
  render() {
    const {
      classes,
      isCompanyUser,
      imageIcon,
      wasteReductionAnalysisAndWastePerCoverForKeyOverview,
      t,
    } = this.props;
    return (
      <Grid container alignItems="center" spacing={2} className={classes.showMoreCard}>
        <Grid item xs={12}>
          <Typography variant="h1">{t('keyOverview.wastePerCover')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <img src={imageIcon} className={classes.imageWeightCost} alt="wastePerCoverIcon" />
          <Typography variant="subtitle1" display="inline" className="wastePerCoverValue">
            {wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForCompany === '-'
              ? '  -'
              : Math.round(
                  wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForCompany
                )}
          </Typography>
          {wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForCompany !== '-' && (
            <Typography
              variant="h6"
              display="inline"
              className={`wastePerCoverValueUnit ${classes.totalUnitGridItem}`}
            >
              {CONSTANT.weightGUnit}
            </Typography>
          )}
        </Grid>
        <Grid item xs className={classes.wastePerCoverChartGrid}>
          <BarChart
            xAxisData={
              wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForBarChart
                .arrLocationName
            }
            yAxisData={
              wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForBarChart
                .arrArrLocationWasteByService
            }
            yAxisLabel={`${t('chart.wastePerCoverText')} (${` ${CONSTANT.weightGUnit}`})`}
            labelData={
              wasteReductionAnalysisAndWastePerCoverForKeyOverview.wastePerCoverForBarChart
                .arrServiceName
            }
            isWastePerCover
            hasDedicatedChartColor={!isCompanyUser}
          />
        </Grid>
      </Grid>
    );
  }
}

export default withTranslation()(WastePerCoverShowMore);
