import axios from 'axios';
import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Button,
  Drawer,
  Badge,
  AppBar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import NotificationsIcon from '@material-ui/icons/Notifications';
import CircleIcon from '@material-ui/icons/FiberManualRecord';
import AddIcon from '@material-ui/icons/Add';

import { AppContext, CONSTANT } from '../../AppContext';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';

/*
 * Styling
 */
const styles = (theme) => ({
  // Black translucent area
  '@global': {
    '.MuiBackdrop-root': {
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
  },

  // General styling
  paperAnchorRight: {
    backgroundImage: theme.palette.primaryGradient,
    color: 'white',
    width: '75%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },

  // Notifications header
  appBar: {
    backgroundImage: theme.palette.primaryGradient,
  },
  headerContainer: {
    paddingTop: '10px',
    paddingBottom: '0px',
  },
  header: {
    ...theme.typography.h2,
    letterSpacing: '2px',
    textAlign: 'center',
    paddingLeft: '10px',
  },

  // Notification badge
  notifItem: {
    alignSelf: 'center',
    marginRight: '8px',
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      marginRight: '18px',
    },
  },
  bell: {
    color: 'white',
    cursor: 'pointer',
  },
  badgeStyle: {
    transform: 'scale(0.8) translate(30%, -30%)',
    backgroundColor: theme.palette.info.main,
    color: 'white',
  },

  // Divider
  divider: {
    backgroundColor: 'darkgrey',
    opacity: 0.2,
    margin: '0px 25px 16px 25px',
  },

  // Notification
  circleIcon: {
    color: theme.palette.info.main,
    fontSize: '10px',
    paddingBottom: '1px',
    paddingRight: '8px',
  },
  title: {
    padding: '0px 25px',
  },
  description: {
    textAlign: 'justify',
    padding: '0px 25px',
  },
  date: {
    textAlign: 'right',
    padding: '0px 25px',
  },
  readNotif: {
    color: 'darkgrey',
  },
  unreadNotif: {
    fontWeight: 600,
  },
  addBtn: {
    ...theme.typography.notificationBtn,
    textTransform: 'uppercase',
    padding: '3px 5px 3px 2px',
    backgroundColor: 'white',
    color: theme.palette.primary.main,
  },
  addIcon: {
    color: theme.palette.primary.main,
    fontSize: '13px',
    paddingRight: '2px',
  },

  // Footer
  bottomBar: {
    top: 'auto',
    bottom: 0,
    backgroundImage: theme.palette.primaryGradient,
    paddingBottom: '5px',
  },
  markAllReadBtn: {
    padding: '3px 5px 3px 2px',
    backgroundColor: 'lightgrey',
    color: theme.palette.primary.main,
  },
});

/**
 * Check if a menu item to be added is already on the watchlist
 * @param {number} menuItemId - Id of menu item to be to be added to the watchlist
 * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrWatchlistItemMenuItemRestaurantWasteAnalysis - Array of existing watchlist item and its array of menuItemRestaurantWasteAnalysis
 * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
 */
const checkIfMenuItemToBeAddedAlreadyOnWatchlist = (
  menuItemId,
  arrWatchlistItemMenuItemRestaurantWasteAnalysis
) => {
  let isMenuItemToBeAddedAlreadyOnWatchlist = false;
  arrWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
    (watchlistItemMenuItemRestaurantWasteAnalysis) => {
      watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.forEach(
        (menuItemRestaurantWasteAnalysis) => {
          if (menuItemRestaurantWasteAnalysis.menuItemId === menuItemId) {
            isMenuItemToBeAddedAlreadyOnWatchlist = true;
          }
        }
      );
    }
  );
  return isMenuItemToBeAddedAlreadyOnWatchlist;
};
class NotificationBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      arrNotification: [],
    };
  }

  componentDidMount() {
    const { arrNotification } = this.props;
    this.setState({ arrNotification });
  }

  onClickSetUnreadNotificationToRead(index) {
    this.setUnreadNotificationToRead(index);
  }

  /**
   * Check if a menu item to be added is already on the watchlist. If no, send the data to the Backend to add it to the watchlist. If yes,
   * open a snackbar to inform the user that the item is already on the watchlist.
   * ToDo: This function is different from the add function in WatchlistClickThrough.js and Home.js because there is no selection
   * of menu items that are already filtered (those already on watchlist are already filtered out) so the checking if the menu item
   * is already on the watchlist is done in this function. (Ref: #86)
   * @param {number} menuItemId - Id of menu item to be to be added to the watchlist
   * @param {number} index - Index of the notification where the add menu item button is clicked
   */
  async onClickAddMenuItemToWatchlist(menuItemId, index) {
    const { selectedStartDate, selectedEndDate, selectedGroupBy, openSnackbar } = this.context;
    const { arrRestaurantService, auth0, t } = this.props;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      // ToDo: Optimization -> should not be calling two HTTP request but leaving it as it is for now cos in future this will not be
      // required when optimization is done. (Ref: #87)
      const response = await axios.post(
        '/api/fetch-arr-menu-item-restaurant-waste-analysis-and-arr-watchlist-item-menu-item-restaurant-waste-analysis',
        {
          userId,
          arrRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const {
        arrMenuItemRestaurantWasteAnalysis,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      } = response.data;
      const isMenuItemToBeAddedAlreadyOnWatchlist = checkIfMenuItemToBeAddedAlreadyOnWatchlist(
        menuItemId,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis
      );
      if (!isMenuItemToBeAddedAlreadyOnWatchlist) {
        const matchedMenuItemRestaurantWasteAnalysis = arrMenuItemRestaurantWasteAnalysis.find(
          (menuItemRestaurantWasteAnalysis) =>
            menuItemRestaurantWasteAnalysis.menuItemId === menuItemId
        );
        await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantWasteAnalysisToBeAdded: [matchedMenuItemRestaurantWasteAnalysis],
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurantWasteAnalysis: arrMenuItemRestaurantWasteAnalysis,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        openSnackbar(t('notification.addMenuItemToWatchlistSuccessSnackbarText'), 'success');
        // Set notification to read
        this.setUnreadNotificationToRead(index);
        window.location.reload();
      } else {
        openSnackbar(t('notification.alreadyOnWatchlistErrorSnackbarText'), 'error');
      }
    } catch (error) {
      if (error?.response?.data.error === CONSTANT.calculatorConnectionError) {
        openSnackbar(t('notification.calculatorConnectionErrorSnackbarText'), 'error');
      } else {
        openSnackbar(t('notification.addMenuItemToWatchlistErrorSnackbarText'), 'error');
      }
    }
  }

  /**
   * Mark all notifications as read. To improve on the efficiency, only notifications where there is a change in status from  unread to read
   * will be sent to the Backend to update in the database.
   */
  onClickMarkAllAsRead() {
    const { arrNotification } = this.state;
    const { numberOfUnreadNotifications, updateNumberOfUnreadNotifications } = this.context;
    // This check is to reduce redundancy call to the backend if all notifications are already updated
    if (numberOfUnreadNotifications !== 0) {
      const arrNotificationToBeUpdated = [];

      const arrReadNotification = arrNotification.map((notification) => {
        const readNotification = { ...notification };
        if (!readNotification.isRead) {
          readNotification.isRead = true;
          arrNotificationToBeUpdated.push(readNotification);
        }
        return readNotification;
      });

      this.setState({ arrNotification: arrReadNotification });
      updateNumberOfUnreadNotifications(0, arrNotificationToBeUpdated);
    }
  }

  /**
   * Change the status of a notification from unread to read
   */
  setUnreadNotificationToRead(index) {
    const { arrNotification } = this.state;
    const { numberOfUnreadNotifications, updateNumberOfUnreadNotifications } = this.context;

    // This check is to reduce redundancy call to the backend if the clicked message is already read
    if (!arrNotification[index].isRead) {
      arrNotification[index].isRead = true;
      this.setState({ arrNotification });

      updateNumberOfUnreadNotifications(numberOfUnreadNotifications - 1, [arrNotification[index]]);
    }
  }

  render() {
    const { classes, isNotificationBarOpen, onCloseNotificationBar, onClickNotificationBar, t } =
      this.props;
    const { arrNotification } = this.state;
    const { numberOfUnreadNotifications } = this.context;

    return (
      <Drawer
        anchor="right"
        open={isNotificationBarOpen}
        onClose={onCloseNotificationBar}
        classes={{ paperAnchorRight: classes.paperAnchorRight }}
        width="80%"
      >
        {/* TITLE */}
        <AppBar position="sticky" elevation={0} className={classes.appBar}>
          <List>
            <ListItem className={classes.headerContainer}>
              <ListItemText>
                <Typography className={classes.header}>{t('notification.header')}</Typography>
              </ListItemText>
              <ListItemSecondaryAction className={classes.notifItem}>
                <Badge
                  badgeContent={numberOfUnreadNotifications}
                  classes={{
                    anchorOriginTopRightRectangle: classes.badgeStyle,
                  }}
                  onClick={onClickNotificationBar}
                >
                  <NotificationsIcon className={classes.bell} />
                </Badge>
              </ListItemSecondaryAction>
            </ListItem>
          </List>
        </AppBar>

        {/* NOTIFICATIONS */}

        {arrNotification.map((notification, index) => (
          <List
            key={notification.datavizNotificationId}
            onClick={
              notification.isRead ? null : () => this.onClickSetUnreadNotificationToRead(index)
            }
          >
            <Divider className={classes.divider} />

            <ListItem className={classes.title}>
              <ListItemText>
                <Typography
                  variant="h4"
                  className={notification.isRead ? classes.readNotif : classes.unreadNotif}
                >
                  {notification.title}
                </Typography>
              </ListItemText>
              {notification.isRead ? (
                ''
              ) : (
                <ListItemSecondaryAction>
                  <CircleIcon className={classes.circleIcon} />
                </ListItemSecondaryAction>
              )}
            </ListItem>

            <ListItem className={classes.description}>
              <ListItemText>
                <Typography
                  variant="h6"
                  className={notification.isRead ? classes.readNotif : classes.unreadNotif}
                >
                  {notification.message}
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem className={classes.date}>
              {!notification.isRead && notification.menuItemId && (
                <Button
                  className={classes.addBtn}
                  size="small"
                  variant="contained"
                  onClick={() => this.onClickAddMenuItemToWatchlist(notification.menuItemId, index)}
                >
                  <AddIcon className={classes.addIcon} />
                  {t('notification.watchlist')}
                </Button>
              )}

              <ListItemText>
                <Typography
                  variant="body2"
                  className={notification.isRead ? classes.readNotif : ''}
                >
                  {notification.date}
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
        ))}

        <AppBar position="sticky" elevation={0} className={classes.bottomBar}>
          <Button
            className={classes.markAllReadBtn}
            variant="contained"
            onClick={() => this.onClickMarkAllAsRead()}
          >
            {t('notification.markAllAsReadButtonLabel')}
          </Button>
        </AppBar>
      </Drawer>
    );
  }
}

NotificationBar.contextType = AppContext;

export default withTranslation()(withAuth0(withStyles(styles)(NotificationBar)));
