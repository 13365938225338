import React, { PureComponent } from 'react';
import { AppContext, CONSTANT } from '../../AppContext';

const token = localStorage.getItem('token');

class ContactUs extends PureComponent {
  componentDidMount() {
    const { setPageHistory } = this.context;
    const { history } = this.props;

    if (token === null) {
      history.push('/login');
    }

    setPageHistory({ organisationName: '', pageNameKey: CONSTANT.contactUsPage });
  }

  render() {
    return <div>This is contact us</div>;
  }
}
ContactUs.contextType = AppContext;

export default ContactUs;
