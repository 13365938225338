import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController,
} from 'chart.js';
import React, { PureComponent } from 'react';
import { Chart } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';

import {
  ARR_PASTEL_COLOR,
  ARR_RANDOM_COLOR,
  BAR_CHART_COLORS_FOR_ALL_SERVICES,
  INVALID_WASTE_PER_COVER_VALUE,
  LINE_CHART_COLOR,
} from './Constants';
import { AppContext, CONSTANT } from '../../../AppContext';

const INVALID_DATA_REPRESENTATION = '-';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  LineController,
  BarController
);

const styles = (theme) => ({
  outerBoxContainer: {
    width: '100%',
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  innerBoxContainer: {
    position: 'relative',
  },
  legendBox: {
    padding: '0px 60px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  legendItem: {
    paddingRight: 10,
    color: CONSTANT.defaultAxisColor,
  },
  legendColorBox: {
    marginRight: '4px',
    width: '8px',
    height: '8px',
    display: 'inline-block',
  },
  legendText: {
    display: 'inline',
    ...theme.typography.body2,
  },
});

/**
 * Returns the chart colors for the corresponding legend (serviceName)
 * @param arrLegend - Array of legend keys
 * @returns arrChartColor - Array of chart colors for the legends
 */
const getArrChartColor = (arrLegend) => {
  const arrChartColor = [];

  // This is for the location's total graph where the arrLegend is a one element array and the element is ''
  if (arrLegend.length === 1 && arrLegend[0] === '') {
    arrChartColor.push(ARR_RANDOM_COLOR[0]);
  } else {
    let iterationForUnfoundLegend = 1;
    arrLegend.forEach((legend) => {
      let barChartColorsForLegend = BAR_CHART_COLORS_FOR_ALL_SERVICES[legend.toLowerCase()];
      if (!barChartColorsForLegend) {
        barChartColorsForLegend =
          BAR_CHART_COLORS_FOR_ALL_SERVICES[`option ${iterationForUnfoundLegend}`];
        // Maximum number of colors catered for unfound services is 5
        iterationForUnfoundLegend += iterationForUnfoundLegend === 5 ? 0 : 1;
      }
      arrChartColor.push(barChartColorsForLegend.default);
    });
  }

  return arrChartColor;
};

/**
 * Returns the pastel colours based on the total number of legend keys for each bar in the bar char
 * @param {string[]} legendData - Array of legend
 * @param {Map<string, string>} mapPastelColorByLocationName - Map consisting of location name and its corresponding pastel color
 * @returns {string[]} numberOfLegendKeys - Number of legend keys
 */
const getPastelColors = (legendData, mapPastelColorByLocationName) => {
  return legendData.map((legend) => mapPastelColorByLocationName.get(legend));
};

class BarLineChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: CONSTANT.defaultBarLineChartHeight,
      chartContainerWidth: '100%',
      chartOption: this.createChartOption(),
      chartData: this.createDataSet(),
    };

    this.chartReference = React.createRef();
  }

  componentDidMount() {
    window.addEventListener('resize', () => this.updateChartWidth());
    this.updateChartWidth();
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateChart(prevProps, prevState);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateChartWidth());
  }

  /**
   * Get the font size for the y-axis, x-axis and scale label.
   * Returns:
   *    mobile view: 10
   *    desktop view < 1280px: 12
   *    else: 14
   * @returns {number} Number - Size of axis font: 10, 12 or 14
   */
  getAxisFontSize() {
    const { theme } = this.props;

    if (window.innerWidth < theme.breakpoints.values.md) {
      return 10;
    }
    if (window.innerWidth < theme.breakpoints.values.lg) {
      return 12;
    }
    return 14;
  }

  /**
   * Update the bar chart with the new data if there is a change in either the bar line chart data
   */
  updateChart(prevProps) {
    const { xAxisData, yAxisBarData, yAxisLineData, yAxisBarLabel, yAxisLineLabel } = this.props;

    if (
      xAxisData !== prevProps.xAxisData ||
      yAxisBarData !== prevProps.yAxisBarData ||
      yAxisBarLabel !== prevProps.yAxisBarLabel ||
      yAxisLineData !== prevProps.yAxisLineData ||
      yAxisLineLabel !== prevProps.yAxisLineLabel
    ) {
      this.setState({
        chartOption: this.createChartOption(),
        chartData: this.createDataSet(),
      });
      this.updateChartWidth();
    }
  }

  /**
   * Creates the data needed for both x and y axis of the bar line chart
   * @returns {Object} chartData - Object containing data to plot bar line chart
   */
  createDataSet() {
    const {
      barLabelData,
      mapPastelColorByLocationName,
      lineLabelData,
      legendData,
      xAxisData,
      yAxisBarData,
      yAxisLineData,
      t,
    } = this.props;

    const isDisplayLineChart = barLabelData !== t('chart.costText');
    // Note that yAxisBarData is an array of bar data of each legend and arranged in the order of legendData
    const arrChartColor = mapPastelColorByLocationName
      ? getPastelColors(legendData, mapPastelColorByLocationName)
      : getArrChartColor(legendData);
    const arrBarChartData = yAxisBarData.map((barData, barDataIndex) => {
      return {
        stack: 'stack',
        type: 'bar',
        label: legendData ? legendData[barDataIndex] : barLabelData,
        backgroundColor: arrChartColor[barDataIndex],
        data: barData,
        yAxisID: 'yBar',
        pointStyle: 'rect', // Include intentionally so that this point style can be applied to the tooltip
      };
    });
    const chartData = {
      labels: xAxisData,
      datasets: [
        {
          type: 'line',
          label: lineLabelData,
          borderColor: legendData ? LINE_CHART_COLOR : ARR_PASTEL_COLOR[1],
          borderWidth: 2,
          fill: false,
          data: yAxisLineData,
          yAxisID: 'yLine',
          showLine: isDisplayLineChart,
          pointStyle: 'circle', // Include intentionally so that this point style can be applied to the tooltip
          pointRadius: isDisplayLineChart ? 3 : 0,
        },
        ...arrBarChartData,
      ],
    };
    return chartData;
  }

  /**
   * Line bar chart becomes scrollable when there are more than 7 x axis data
   */
  updateChartWidth() {
    const { xAxisData } = this.props;
    if (xAxisData && xAxisData.length > 7) {
      this.setState({ chartContainerWidth: `${89 * xAxisData.length}px` });
    } else {
      this.setState({ chartContainerWidth: '100%' });
    }
  }

  /**
   * Calculates the maximum value of the y-axis tick for the bar line chart. The max value is found based on the
   * highest individual data points multiplied by 1.05 to pad the top of the axes.
   * @returns {number} Number - Maximum value of the y-axis tick
   */
  calculateMaxYTickValue(chartType) {
    const { yAxisBarData, yAxisLineData } = this.props;
    const isBarChart = chartType === 'bar';
    const yAxisData = isBarChart ? yAxisBarData : yAxisLineData;
    if (yAxisData.length === 0) {
      return 0;
    }
    if (isBarChart) {
      // The filtering of the arrWasteValue to remove the null values is because typescript
      // throws error when pass in array of (number | null) to Math.max
      const arrTotalWasteValue = new Array(yAxisData[0].length).fill(0);
      yAxisData.forEach((arrWasteValue) => {
        arrWasteValue.forEach((wasteValue, index) => {
          if (wasteValue !== null) {
            arrTotalWasteValue[index] += wasteValue;
          }
        });
      });
      return 1.05 * Math.max(...arrTotalWasteValue);
    }
    return 1.05 * Math.max(...yAxisData);
  }

  /**
   * Create data structure required by the bar line chart to display it correctly
   * @returns {Object} chartOption - Object containing chart options for the bar line chart
   */
  createChartOption() {
    const { barLabelData, yAxisBarLabel, yAxisLineLabel, yAxisTotalBarData, t } = this.props;
    // eslint-disable-next-line react/destructuring-assignment
    const currency = this.context && this.context.currency;
    const isDisplayLineChart = barLabelData !== t('chart.cost');
    const chartOption = {
      maintainAspectRatio: false,
      responsive: true,
      // Options to style the x and y axes
      scales: {
        x: {
          ticks: {
            display: true,
            color: CONSTANT.defaultAxisColor,
            font: this.getAxisFontSize(),
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
        },
        yBar: {
          suggestedMax: this.calculateMaxYTickValue('bar'),
          ticks: {
            display: true,
            maxTicksLimit: 6,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
            padding: 6,
            precision: 0,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          title: {
            display: true,
            text: yAxisBarLabel,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
          },
          stacked: true,
        },
        yLine: {
          suggestedMax: this.calculateMaxYTickValue('line'),
          ticks: {
            display: isDisplayLineChart,
            maxTicksLimit: 6,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
            padding: 6,
            precision: 0,
            beginAtZero: true,
          },
          grid: {
            display: false,
          },
          border: {
            display: false,
          },
          title: {
            display: isDisplayLineChart,
            text: yAxisLineLabel,
            font: this.getAxisFontSize(),
            color: CONSTANT.defaultAxisColor,
          },
          position: 'right',
        },
      },
      layout: {
        autoPadding: true,
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          mode: 'index',
          bodyAlign: 'right',
          footerAlign: 'right',
          usePointStyle: true,
          callbacks: {
            label: (context) => {
              const currentStackLabel = context.dataset.label;
              const currentStackType = context.dataset.type;
              const currentValue = context.dataset.data[context.dataIndex];
              if (currentStackType === 'bar') {
                const currentValueFormattedTo2dpAndWithCommaSeparation =
                  currentValue !== INVALID_WASTE_PER_COVER_VALUE
                    ? Number(currentValue.toFixed(2)).toLocaleString('en-US', {
                        minimumFractionDigits: 2,
                      })
                    : INVALID_DATA_REPRESENTATION;
                if (barLabelData === t('chart.wastePerCoverText')) {
                  return `${currentStackLabel}: ${currentValueFormattedTo2dpAndWithCommaSeparation} grams`;
                }
                if (barLabelData === t('chart.weightText')) {
                  return `${currentStackLabel}: ${currentValueFormattedTo2dpAndWithCommaSeparation} KG`;
                }
                if (barLabelData === t('chart.costText')) {
                  return `${currentStackLabel}: ${currentValueFormattedTo2dpAndWithCommaSeparation} ${currency}`;
                }
              } else if (currentStackType === 'line' && isDisplayLineChart) {
                const currentValueFormattedToWholeNumberAndWithCommaSeparation =
                  currentValue !== INVALID_WASTE_PER_COVER_VALUE
                    ? Number(currentValue).toLocaleString('en-US')
                    : INVALID_DATA_REPRESENTATION;
                return `${currentStackLabel}: ${currentValueFormattedToWholeNumberAndWithCommaSeparation}`;
              }
              return null;
            },
            // Returns the total at the bottom of the tooltip
            footer: (context) => {
              const totalValue = yAxisTotalBarData[context[0].dataIndex];
              const totalValueFormattedTo2dpAndWithCommaSeparation =
                totalValue !== INVALID_WASTE_PER_COVER_VALUE
                  ? Number(totalValue.toFixed(2)).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                    })
                  : INVALID_DATA_REPRESENTATION;
              if (barLabelData === t('chart.wastePerCoverText')) {
                return `${t(
                  'chart.totalLegendText'
                )}: ${totalValueFormattedTo2dpAndWithCommaSeparation} grams`;
              }
              if (barLabelData === t('chart.weightText')) {
                return `${t(
                  'chart.totalLegendText'
                )}: ${totalValueFormattedTo2dpAndWithCommaSeparation} KG`;
              }
              if (barLabelData === t('chart.costText')) {
                return `${t(
                  'chart.totalLegendText'
                )}: ${totalValueFormattedTo2dpAndWithCommaSeparation} ${currency}`;
              }

              return null;
            },
          },
        },
        datalabels: {
          display: false,
        },
      },
    };

    return chartOption;
  }

  renderLegend() {
    const { classes, mapPastelColorByLocationName, legendData } = this.props;
    if (legendData) {
      const arrChartColor = mapPastelColorByLocationName
        ? getPastelColors(legendData, mapPastelColorByLocationName)
        : getArrChartColor(legendData);
      return (
        <Box component="div" className={classes.legendBox}>
          {legendData.length !== 0 &&
            legendData.map((item, index) => (
              <Box component="span" key={item} className={classes.legendItem}>
                <Box
                  component="span"
                  className={classes.legendColorBox}
                  style={{
                    backgroundColor: arrChartColor[index],
                  }}
                />
                <Typography className={classes.legendText}>{item}</Typography>
              </Box>
            ))}
        </Box>
      );
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const { chartData, chartOption, height, chartContainerWidth } = this.state;

    return (
      <Box
        className={classes.outerBoxContainer}
        style={{
          height: CONSTANT.defaultBarLineChartHeight + CONSTANT.defaultBarLineChartLegendHeight,
        }}
      >
        <Box className={classes.innerBoxContainer} style={{ width: chartContainerWidth }}>
          <Chart
            ref={this.chartReference}
            type="bar"
            data={JSON.parse(JSON.stringify(chartData))}
            height={height}
            options={chartOption}
          />
        </Box>
        {this.renderLegend()}
      </Box>
    );
  }
}

BarLineChart.contextType = AppContext;

export default withTranslation()(withTheme(withStyles(styles)(BarLineChart)));
