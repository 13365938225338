import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Card,
  ClickAwayListener,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowLeft, ArrowRight, Info } from '@material-ui/icons';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

import TopFoodItemsTable from './TopFoodItemsTable';
import TopThreeWastefulStations from './TopThreeWastefulStations';
import WastageByLevelTable from './WastageByLevelTable';
import WastageOverTimeLineChart from './WastageOverTimeLineChart';
import WeightCostSummary from './WeightCostSummary';
import DropdownList from '../../common/DropdownList';
import ToggleWeightCostButton from '../../common/ToggleWeightCostButton';
import { AppContext } from '../../../AppContext';

/**
 * Custom styles for Restaurant breakdown component
 */
const styles = (theme) => ({
  rootCard: {
    ...theme.card.home,
    minWidth: 275,
  },
  headingContainer: {
    paddingLeft: '16px',
    paddingTop: '15px',
    alignItems: 'center',
  },
  spacedTitle: theme.typography.spacedTitle,
  // Tooltip
  tooltipIcon: {
    ...theme.typography.h4,
    color: theme.palette.gray3,
  },
  tooltipText: {
    textAlign: 'justify',
  },
  // Tabs
  tabContainer: {
    boxShadow: 'none',
    borderBottomStyle: 'solid',
    borderBottomWidth: 'thin',
    borderBottomColor: 'lightgrey',
  },
  tabStyle: {
    ...theme.typography.h4,
    padding: '0px',
    width: '50%', // To show only 2 restaurant tabs
    textTransform: 'none',
    fontWeight: 600,
    color: theme.palette.gray3, // For higher constrast between selected and unselected tab
  },
  tabSelectedIndicatorLine: {
    backgroundColor: '#4477A0',
  },
  wrapper: {
    marginBottom: '-10px',
  },
  arrowIcon: {
    padding: '13px 0px 5px 0px',
  },
  tabPanelStyle: {
    padding: '0px',
  },
  // Tab panel
  gridRootTabPanel: {
    paddingLeft: '16px',
    paddingRight: '16px',
    flexWrap: 'nowrap',
  },
  gridItemDropdownToggle: {
    paddingTop: '18px',
  },
  gridItemChart: {
    paddingBottom: '20px',
  },
  // Clickthrough links
  linkToStationBreakdownBox: {
    textAlign: 'right',
    paddingTop: '35px',
  },
  linkToBreakdownBox: {
    textAlign: 'right',
  },
  linkToBreakdown: {
    color: theme.palette.primary3,
    ...theme.typography.clickthroughLink,
  },
});

class RestaurantBreakdown extends PureComponent {
  /**
   * Render tooltip text differently for company or non-company users
   * @param {Object} classes - Classes object
   * @param {boolean} isCompanyUser - Indicates if the user is a company user
   */
  renderTooltipText(classes, isCompanyUser) {
    const { selectedRestaurantName } = this.props;

    return isCompanyUser ? (
      <Typography variant="caption" className={classes.tooltiptext}>
        <Trans i18nKey="breakdown.companyUserTooltipText" components={{ ul: <ul />, li: <li /> }} />
      </Typography>
    ) : (
      <Typography variant="caption" className={classes.tooltiptext}>
        <Trans
          i18nKey="breakdown.nonCompanyUserTooltipText"
          values={{
            restaurantName: selectedRestaurantName || '',
          }}
          components={{ ul: <ul />, li: <li /> }}
        />
      </Typography>
    );
  }

  render() {
    const {
      classes,
      auth0,
      arrRestaurantService,
      arrSelectedMenuItemServiceWasteAnalysis,
      arrDropdownItem,
      selectedLocationName,
      selectedLocationWasteAnalysisForHighlights,
      selectedServiceGroup,
      selectedGroupedServiceWasteAnalysisForHighlights,
      tabValue,
      isTooltipOpened,
      isRightArrowDisabled,
      isLeftArrowDisabled,
      isWastageOverTimeLineChartLoading,
      selectedToggleValueForWeightCost,
      mapPastelColorByLocationName,
      arrAllServiceType,
      arrSelectedServiceType,
      onClickAwayTooltip,
      onClickInfoIcon,
      onCloseTooltip,
      onClickAdd,
      onClickClearSelection,
      onClickLeftArrowIcon,
      onClickRightArrowIcon,
      onChangeTabList,
      onChangeDropdownList,
      onChangeToggleWeightCost,
      updateArrSelectedMenuItemServiceWasteAnalysis,
      updateSelectedServiceTypes,
      t,
    } = this.props;
    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;

    const isCompanyUser =
      (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);
    const arrLevelService = isCompanyUser
      ? arrRestaurantService
      : arrRestaurantService[0].arrLocationService;
    const selectedLevelWasteAnalysisForHighlights = isCompanyUser
      ? selectedLocationWasteAnalysisForHighlights
      : selectedGroupedServiceWasteAnalysisForHighlights;

    // The arrLevelName is used to ensure that the location/service groups are listed in the same order as the dropdown list on the Wastage By Location/Service, with the exception of the Plate Waste service that is pushed to the
    // end of the table
    let arrLevelName = [];
    if (isCompanyUser) {
      arrLevelName =
        selectedLocationName === 'All Locations'
          ? arrDropdownItem.slice(1).map((dropdownItem) => dropdownItem.value)
          : [selectedLocationName];
    } else {
      arrLevelName =
        selectedServiceGroup === 'All Services'
          ? arrDropdownItem.slice(1).map((dropdownItem) => dropdownItem.value)
          : [selectedServiceGroup];
    }
    return (
      <Card className={classes.rootCard}>
        {/* Title and tooltip container */}
        <Grid
          container
          direction="row"
          className={classes.headingContainer}
          spacing={1}
          alignItems="baseline"
        >
          <Grid item>
            <Typography variant="h1" color="primary" className={classes.spacedTitle}>
              {isCompanyUser ? t('breakdown.byPropertyHeader') : t('breakdown.byLocationHeader')}
            </Typography>
          </Grid>
          <Grid item>
            <ClickAwayListener onClickAway={onClickAwayTooltip}>
              <Tooltip
                arrow
                onClose={onCloseTooltip}
                open={isTooltipOpened}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={this.renderTooltipText(classes, isCompanyUser)}
              >
                <Info onClick={onClickInfoIcon} className={classes.tooltipIcon} />
              </Tooltip>
            </ClickAwayListener>
          </Grid>
        </Grid>
        <TabContext value={tabValue.toString()}>
          {/* Restaurant/Location tabs and the left/right arrow button */}
          <Paper className={classes.tabContainer}>
            <Grid container direction="row" alignItems="center">
              <Grid item xs={1}>
                <IconButton
                  className={classes.arrowIcon}
                  onClick={onClickLeftArrowIcon}
                  color="primary"
                  disabled={isLeftArrowDisabled}
                >
                  <ArrowLeft />
                </IconButton>
              </Grid>
              <Grid item xs={10}>
                <TabList
                  onChange={(event, newValue) => onChangeTabList(event, newValue)}
                  indicatorColor="primary"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="off"
                  classes={{ indicator: classes.tabSelectedIndicatorLine }}
                >
                  {arrLevelService.map((tabElement, index) => (
                    <Tab
                      className={classes.tabStyle}
                      label={tabElement.name}
                      value={index.toString()}
                      key={`${tabElement.restaurantId || tabElement.locationId}${tabElement.name}`}
                      classes={{
                        wrapper: classes.wrapper,
                      }}
                    />
                  ))}
                </TabList>
              </Grid>
              <Grid item xs={1}>
                <IconButton
                  className={classes.arrowIcon}
                  onClick={onClickRightArrowIcon}
                  color="primary"
                  disabled={
                    (isCompanyUser && arrRestaurantService.length <= 1) ||
                    (!isCompanyUser && arrRestaurantService[0].arrLocationService.length <= 1)
                      ? true
                      : isRightArrowDisabled
                  }
                >
                  <ArrowRight />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
          <Grid container direction="column" className={classes.gridRootTabPanel}>
            {/* Location/Service group dropdown and weightCost toggle */}
            <Grid item className={`dropdownToggle ${classes.gridItemDropdownToggle}`}>
              <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <Grid item>
                  <DropdownList
                    label={
                      isCompanyUser
                        ? t('breakdown.locationDropdownLabel')
                        : t('breakdown.serviceGroupDropdownLabel')
                    }
                    backgroundColor="white"
                    selectedValue={isCompanyUser ? selectedLocationName : selectedServiceGroup}
                    arrDropdownItem={arrDropdownItem}
                    onChange={onChangeDropdownList}
                  />
                </Grid>
                <Grid item>
                  <ToggleWeightCostButton
                    selectedToggleValue={selectedToggleValueForWeightCost}
                    onChange={onChangeToggleWeightCost}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* Total weight and cost */}
            <Grid item>
              <WeightCostSummary
                selectedLevelWasteAnalysisForHighlights={selectedLevelWasteAnalysisForHighlights}
              />
            </Grid>
            {/* Charts */}
            <Grid item>
              {arrLevelService.map((levelService, index) => (
                <TabPanel
                  className={classes.tabPanelStyle}
                  value={index.toString()}
                  key={isCompanyUser ? levelService.restaurantId : levelService.locationId}
                >
                  <Grid container direction="column" wrap="nowrap">
                    {/* Wastage Over Time Line Chart */}
                    <Grid item className={classes.gridItemChart}>
                      <WastageOverTimeLineChart
                        selectedLevelWasteAnalysisForHighlights={
                          selectedLevelWasteAnalysisForHighlights
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                        mapPastelColorByLocationName={mapPastelColorByLocationName}
                        isWastageOverTimeLineChartLoading={isWastageOverTimeLineChartLoading}
                        arrAllServiceType={arrAllServiceType}
                        arrSelectedServiceType={arrSelectedServiceType}
                        updateSelectedServiceTypes={updateSelectedServiceTypes}
                      />
                    </Grid>

                    {/* Wastage By Level */}
                    <Grid item className={classes.gridItemChart}>
                      <WastageByLevelTable
                        selectedLevelWasteAnalysisForHighlights={
                          selectedLevelWasteAnalysisForHighlights
                        }
                        toggleValueForWeightCost={selectedToggleValueForWeightCost}
                        isCompanyUser={isCompanyUser}
                        arrLevelName={arrLevelName}
                      />
                    </Grid>

                    {/* Top 3 Wasteful Station Bar Chart */}
                    {!isCompanyUser && (
                      <Grid item className={classes.gridItemChart}>
                        <TopThreeWastefulStations
                          selectedLevelWasteAnalysisForHighlights={
                            selectedLevelWasteAnalysisForHighlights
                          }
                          toggleValueForWeightCost={selectedToggleValueForWeightCost}
                          isCompanyUser={isCompanyUser}
                        />
                        <Box className={classes.linkToStationBreakdownBox}>
                          <Typography>
                            <Link
                              to={`/stations/${levelService.restaurantId}/${levelService.locationId}`}
                              className={classes.linkToBreakdown}
                            >
                              {t('breakdown.stationsLink')} &rarr;
                            </Link>
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {/* Top 6 Wasted Menu Items Table */}
                    {!isCompanyUser && (
                      <Grid item className={classes.gridItemChart}>
                        <TopFoodItemsTable
                          selectedLevelWasteAnalysisForHighlights={
                            selectedLevelWasteAnalysisForHighlights
                          }
                          arrSelectedMenuItemServiceWasteAnalysis={
                            arrSelectedMenuItemServiceWasteAnalysis
                          }
                          toggleValueForWeightCost={selectedToggleValueForWeightCost}
                          onClickClearSelection={onClickClearSelection}
                          onClickAdd={onClickAdd}
                          updateArrSelectedMenuItemServiceWasteAnalysis={
                            updateArrSelectedMenuItemServiceWasteAnalysis
                          }
                        />
                        <Box className={classes.linkToBreakdownBox}>
                          <Typography>
                            <Link
                              to={`/items/${levelService.restaurantId}/${levelService.locationId}`}
                              className={classes.linkToBreakdown}
                            >
                              {t('breakdown.foodItemsLink')} &rarr;
                            </Link>
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </TabPanel>
              ))}
            </Grid>
          </Grid>
        </TabContext>
      </Card>
    );
  }
}

RestaurantBreakdown.contextType = AppContext;

export default withTranslation()(withAuth0(withStyles(styles)(RestaurantBreakdown)));
