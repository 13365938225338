import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import CustomTable from '../common/CustomTable';
import { AppContext } from '../../AppContext';

const styles = (theme) => ({
  buttonRowContainer: {
    marginTop: 15,
    marginBottom: 15,
  },
  enabledAddButton: {
    color: theme.palette.primary.contrastText,
    background: theme.palette.primaryGradient,
    borderRadius: '7px',
  },
  enabledCancelSelectionButton: {
    color: theme.palette.info.main,
    borderColor: theme.palette.info.main,
    borderRadius: '7px',
  },
  button: {
    borderRadius: '7px',
  },
});

class FoodItemsBreakdownTable extends PureComponent {
  render() {
    const {
      classes,
      arrMenuItemServiceWasteAnalysis,
      arrSelectedMenuItemServiceWasteAnalysis,
      toggleValueForWeightCost,
      onClickClearSelection,
      onClickAdd,
      updateGraphAndTableForArrSelectedMenuItemServiceWasteAnalysis,
      t,
    } = this.props;

    const { impersonatorDatavizUserId } = this.context;

    const weightCostId = toggleValueForWeightCost;
    const weightCostHeader =
      toggleValueForWeightCost === 'weight'
        ? t('breakdownByStation.weightHeader').toUpperCase()
        : t('breakdownByStation.costHeader').toUpperCase();
    const weightCostPerCoverId =
      toggleValueForWeightCost === 'weight' ? 'weightPerCover' : 'costPerCover';
    const weightCostPerCoverHeader =
      toggleValueForWeightCost === 'weight'
        ? t('breakdownByFoodItem.wastePerCoverHeader').toUpperCase()
        : t('breakdownByFoodItem.costPerCoverHeader').toUpperCase();
    const weightCostPerCoverDifferenceId =
      toggleValueForWeightCost === 'weight'
        ? 'weightPerCoverDifferencePercentage'
        : 'costPerCoverDifferencePercentage';
    const weightCostDifferenceHeader =
      toggleValueForWeightCost === 'weight'
        ? `% ${t('breakdownByFoodItem.changeInWastePerCoverHeader').toUpperCase()}`
        : `% ${t('breakdownByFoodItem.changeInCostPerCoverHeader').toUpperCase()}`;
    const arrTableHeader = [
      { id: 'menuItemName', label: t('breakdownByFoodItem.itemHeader').toUpperCase() },
      {
        id: 'serviceName',
        label: t('breakdownByFoodItem.serviceHeader').toUpperCase(),
        iconName: 'filter',
      },
      {
        id: 'station',
        label: t('breakdownByFoodItem.stationHeader').toUpperCase(),
        iconName: 'filter',
      },
      { id: weightCostId, label: weightCostHeader, iconName: 'sort' },
      { id: weightCostPerCoverId, label: weightCostPerCoverHeader, iconName: 'sort' },
      { id: weightCostPerCoverDifferenceId, label: weightCostDifferenceHeader, iconName: 'sort' },
    ];
    const arrTableRow = arrMenuItemServiceWasteAnalysis;
    const arrTableAlignment = ['left', 'center', 'center', 'center', 'center', 'center'];
    const arrTableWrap = [true, true, true, false, false];
    const arrColumnWidth = ['30%', '15%', '20%', '10%', '10%', '15%'];

    const arrIdKey = ['menuItemId', 'serviceId'];

    const numberOfMenuItemsSelected = arrSelectedMenuItemServiceWasteAnalysis.length;
    const isAtLeastOneMenuItemSelected = numberOfMenuItemsSelected > 0;

    return (
      <div>
        <CustomTable
          arrTableHeader={arrTableHeader}
          arrTableRow={arrTableRow}
          arrTableAlignment={arrTableAlignment}
          arrTableWrap={arrTableWrap}
          toggleValueForWeightCost={toggleValueForWeightCost}
          hasFilterAndSortFunctionAndHeaderIcons
          hasPagination
          hasSearchbar
          updateArrSelectedRow={updateGraphAndTableForArrSelectedMenuItemServiceWasteAnalysis}
          arrSelectedRow={arrSelectedMenuItemServiceWasteAnalysis}
          arrIdKey={arrIdKey}
          rowsPerPage={10}
          rowHeader={t('breakdownByFoodItem.itemDisplayText')}
          hasCheckboxInBody
          isFoodItemsBreakdown
          arrColumnWidth={arrColumnWidth}
        />
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          className={classes.buttonRowContainer}
        >
          <Grid item>
            <Button
              variant="outlined"
              size="small"
              className={
                isAtLeastOneMenuItemSelected ? classes.enabledCancelSelectionButton : classes.button
              }
              disabled={!isAtLeastOneMenuItemSelected}
              onClick={onClickClearSelection}
            >
              <Typography variant="h6">
                {t('breakdownByFoodItem.clearSelectionButtonLabel')}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            {impersonatorDatavizUserId !== -1 && (
              <Button
                variant="outlined"
                size="small"
                className={isAtLeastOneMenuItemSelected ? classes.enabledAddButton : classes.button}
                disabled={!isAtLeastOneMenuItemSelected}
                onClick={onClickAdd}
              >
                <Typography variant="h6">
                  {numberOfMenuItemsSelected === 0
                    ? t('breakdownByFoodItem.addItemsToWatchlistWithoutSelectionLabel')
                    : t('breakdownByFoodItem.addItemsToWatchlistWithSelectionLabel', {
                        numberOfMenuItemsSelected,
                      })}
                </Typography>
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

FoodItemsBreakdownTable.contextType = AppContext;

export default withTranslation()(withStyles(styles)(FoodItemsBreakdownTable));
