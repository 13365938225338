import { format } from 'date-fns';
import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Grid from '@material-ui/core/Grid';
import { withStyles, withTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Pagination from '@material-ui/lab/Pagination';

import KeyOverviewValueCard from './KeyOverview/KeyOverviewValueCard';
import ReductionShowMore from './KeyOverview/ReductionShowMore';
import WastePerCoverShowMore from './KeyOverview/WastePerCoverShowMore';
import WeightCostShowMore from './KeyOverview/WeightCostShowMore';
import { AppContext, CONSTANT } from '../../AppContext';
import CommonFunctions from '../common/CommonFunctions';
import CostReductionImg from '../../imgs/cost_reduction.png';
import FoodWasteImg from '../../imgs/food_waste.png';
import CostImg from '../../imgs/pricedecrease.png';
import WeightImg from '../../imgs/trash.png';
import WastePerCoverReductionImg from '../../imgs/waste_per_cover_reduction.png';
import WeightReductionImg from '../../imgs/weight_reduction.png';

const { convertToAbbreviatedNumber, formatWastePerCoverValue, getPreviousDateRangeString } =
  CommonFunctions;

const CustomPagination = withStyles(() => ({
  root: {
    margin: 'auto',
    marginBottom: '6px',
  },
}))(Pagination);

const CustomExpansionSummary = withStyles((theme) => ({
  root: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      height: '22rem',
    },
    height: '21rem',
  },
}))(ExpansionPanelSummary);

/*
 * Custom styles for KeyOverview component
 */
const styles = (theme) => ({
  // General card
  root: {
    ...theme.card.home,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.cardBackground,
  },
  spacedTitle: theme.typography.spacedTitle,

  keyOverviewCard: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    margin: 'auto',
  },

  keyOverviewCardGrid: {
    margin: 0,
    marginBottom: '0.8rem',
    display: 'flex',
    justifyContent: 'flex-start',
    height: '14rem',
    width: '100%',
  },

  pagination: {
    margin: 'auto',
  },

  // Weight and cost images
  imageWeightCost: {
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
      width: '25px',
      paddingRight: '8px',
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
      width: '29px',
      paddingRight: '8px',
    },
  },

  // Percentage comparison
  trendIncrease: {
    backgroundColor: theme.palette.info.main,
    padding: '2px 5px 3px 5px',
    marginLeft: '7px',
    borderRadius: '5px',
  },
  trendDecrease: {
    backgroundColor: theme.palette.success.main,
    padding: '2px 5px 3px 5px',
    marginLeft: '7px',
    borderRadius: '5px',
  },

  // Show More Card
  showMoreCard: {
    padding: '0px 30px 30px 30px',
  },
  tabPanel: {
    padding: '2rem',
    paddingTop: '0',
  },

  // Trendline graph
  trendlineGridItem: {
    // Not sure why, but need to set a random fixed 'px' width for
    // trendline grid item for it to work with Trendline properly
    // to fill available space
    width: '1px',
    display: 'inline',
    marginTop: '15px',
    position: 'relative',
  },

  // Tabs
  wrapper: {
    textTransform: 'capitalize',
    padding: '0px 10px',
    marginBottom: '-15px',
  },
  indicator: {
    backgroundColor: 'white',
    height: '1px',
    boxShadow: '0px 5px 10px 1px white',
  },
  textColorInherit: {
    ...theme.typography.h3,
    borderBottom: '1px solid #B0B0AF',
    opacity: 0.5,
  },
  selected: {
    color: 'white',
    borderBottom: '1px solid white',
    fontWeight: 600,
  },

  chartTitle: {
    ...theme.typography.keyOverviewChartTitle,
    textAlign: 'center',
  },
  wastageChartGrid: {
    marginTop: '20px',
    marginBottom: '4px',
  },
  wastagePerfChartGrid: {
    marginTop: '20px',
    marginBottom: '6px',
  },
  wastePerCoverChartGrid: {
    marginTop: '20px',
    width: '100%',
    overflow: 'auto',
    whiteSpace: 'nowrap',
  },

  // Tooltip
  toolTipContainer: {
    padding: '4px 4px 0px 4px',
  },
  tooltipText: {
    textAlign: 'left',
  },
  tooltipIcon: {
    ...theme.typography.h4,
    position: 'relative',
    top: '2px',
  },

  keyOverviewSubtext: {
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
      fontSize: '12px',
    },
    fontSize: '14px',
  },
});

const SHOW_MORE_VALUE = {
  totalWeight: 'totalWeight',
  totalCost: 'totalCost',
  reductionInWeight: 'reductionInWeight',
  reductionInCost: 'reductionInCost',
  wastePerCover: 'wastePerCover',
  reductionInWastePerCover: 'reductionInWastePerCover',
  co2Emissions: 'co2Emissions',
  mealsSaved: 'mealsSaved',
};

/**
 * This function formats the rendered values such that positive values are prepended with a '+' and values that can be large
 * are further formatted by convertToAbbreviatedNumber or formatWastePerCoverValue function.
 * As the reduction terminology used is "change in xxx", this formatting reduces confusion when reading the values where
 * a positive reduction is undesirable (denoted with a "+" in red text) and a negative reduction is desirable
 * (denoted with a "-" in green text).
 * @param {string} showMoreValue - Type of value to be formatted
 * @param {number} valueForCompany - Value to be formatted
 * @returns String - Formatted string to be displayed
 */
const formatValue = (showMoreValue, value) => {
  if (value === '-' || value === 'NA') {
    return value;
  }
  if (showMoreValue === SHOW_MORE_VALUE.wastePerCover) {
    return formatWastePerCoverValue(value);
  }
  if (showMoreValue === SHOW_MORE_VALUE.reductionInWastePerCover) {
    return `${value > 0 ? '+' : ''}${formatWastePerCoverValue(value)}`;
  }
  if (
    showMoreValue === SHOW_MORE_VALUE.reductionInWeight ||
    showMoreValue === SHOW_MORE_VALUE.reductionInCost
  ) {
    return `${value > 0 ? '+' : ''}${convertToAbbreviatedNumber(value)}`;
  }
  if (
    showMoreValue === SHOW_MORE_VALUE.co2Emissions ||
    showMoreValue === SHOW_MORE_VALUE.mealsSaved
  ) {
    return convertToAbbreviatedNumber(Math.abs(value));
  }
  return convertToAbbreviatedNumber(value);
};

class KeyOverview extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      showMoreValue: '',
    };
  }

  /**
   * This function retrieves keyOverviewValueCardDataTemplate that has been translated
   * This function used to be a constant variable, however due to requirement to re-render translations,
   * it has been converted to a function called on manual language change
   * @returns template of Key Overview Value Card Data used for rendering Key Overview Card data
   */
  getKeyOverviewValueCardDataTemplate() {
    const { t } = this.props;

    return {
      totalWeightOfWaste: {
        dataBackUnit: ` ${CONSTANT.weightKGUnit}`,
        dataFrontUnit: '',
        dataName: `*${t('keyOverview.totalWeightOfWaste')}`,
        isButtonDisabled: false,
        showMoreValue: 'totalWeight',
      },
      totalCostOfWaste: {
        dataBackUnit: '',
        dataFrontUnit: '',
        dataName: `*${t('keyOverview.totalCostOfWaste')}`,
        isButtonDisabled: false,
        showMoreValue: 'totalCost',
      },
      reductionInWeight: {
        dataBackUnit: ` ${CONSTANT.weightKGUnit}`,
        dataFrontUnit: '',
        dataName: `${t('keyOverview.changeInWeight')}`,
        isButtonDisabled: false,
        showMoreValue: 'reductionInWeight',
      },
      reductionInCost: {
        dataBackUnit: '',
        dataFrontUnit: '',
        dataName: `${t('keyOverview.changeInCost')}`,
        isButtonDisabled: false,
        showMoreValue: 'reductionInCost',
      },
      wastePerCover: {
        dataBackUnit: ` ${CONSTANT.weightGUnit}`,
        dataFrontUnit: '',
        dataName: `${t('keyOverview.wastePerCover')}`,
        isButtonDisabled: false,
        showMoreValue: 'wastePerCover',
      },
      co2Emissions: {
        dataBackUnit: ` ${CONSTANT.weightKGUnit}`,
        dataFrontUnit: '',
        dataName: `${t('keyOverview.carbonDioxideEmissions')}`,
        isButtonDisabled: true,
        showMoreValue: 'co2Emissions',
      },
      reductionInWastePerCover: {
        dataBackUnit: ` ${CONSTANT.weightGUnit}`,
        dataFrontUnit: '',
        dataName: `${t('keyOverview.changeInWastePerCover')}`,
        isButtonDisabled: false,
        showMoreValue: 'reductionInWastePerCover',
      },
      mealsSaved: {
        dataBackUnit: '',
        dataFrontUnit: '',
        dataName: `${t('keyOverview.meals')}`,
        isButtonDisabled: true,
        showMoreValue: 'mealsSaved',
      },
    };
  }

  /**
   * Get the corresponding image icon for the respective reduction values
   * @returns {Image} Reduction image icon
   */
  getReductionImageIcon() {
    const { showMoreValue } = this.state;

    if (showMoreValue === SHOW_MORE_VALUE.reductionInWeight) {
      return WeightReductionImg;
    }
    if (showMoreValue === SHOW_MORE_VALUE.reductionInCost) {
      return CostReductionImg;
    }
    return WastePerCoverReductionImg;
  }

  /**
   * Tooltip text for more information on total wastage (weight or cost) values. Text will include either 'weight' or 'cost',
   * depending on the which total wastage value the text is for
   * @param {string} value - 'weight' or 'cost'
   * @returns {Typography} Typography for tooltip
   */
  displayTotalWastageTooltipText(value) {
    const { classes } = this.props;
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;

    return (
      <Typography variant="caption" className={classes.tooltipText}>
        <Trans
          i18nKey={
            value === 'weight' ? 'keyOverview.weightTooltipText' : 'keyOverview.costTooltipText'
          }
          values={{
            previousDateRange: getPreviousDateRangeString(
              selectedStartDate,
              selectedEndDate,
              selectedGroupBy
            ),
            selectedDateRange: `${format(new Date(selectedStartDate), 'dd-MM-yyyy')} — ${format(
              new Date(selectedEndDate),
              'dd-MM-yyyy'
            )}`,
          }}
        />
      </Typography>
    );
  }

  /**
   * Changes the currently set page in state. Used to control the pages of the KeyOverviewValueCard grid display
   * @param {event} event
   * @param {number} newPage - Newly selected page for pagination component
   */
  changePagination(event, newPage) {
    this.setState({
      page: newPage,
      showMoreValue: '',
    });
  }

  /**
   * Function sets the new 'showMoreValue'. If the new selected 'showMoreValue' is the same as the current 'showMoreValue', set 'showMoreValue'
   * to '' to indicate that the current 'showMoreValue' component should be collapsed.
   * @param {Object} event
   * @param {string} newShowMoreValue - Newly selected 'showMoreValue'
   */
  changeShowMoreValue(newShowMoreValue) {
    const { showMoreValue } = this.state;
    if (newShowMoreValue === showMoreValue) {
      this.setState({ showMoreValue: '' });
    } else {
      this.setState({ showMoreValue: newShowMoreValue });
    }
  }

  /**
   * Adds data variables to the key overview object template used to render the variables on the key overview card.
   * @param {Object} wasteAnalysisForKeyOverview - Waste analysis for key overview
   * @param {Object} wasteReductionAnalysisAndWastePerCoverForKeyOverview - Waste reduction analysis for key overview
   */
  addDataToKeyOverviewValueCard(
    wasteAnalysisForKeyOverview,
    wasteReductionAnalysisAndWastePerCoverForKeyOverview
  ) {
    const { currency } = this.context;
    const newKeyOverviewValueCardData = JSON.parse(
      JSON.stringify(this.getKeyOverviewValueCardDataTemplate())
    );
    const { weight, cost } = wasteAnalysisForKeyOverview;
    const {
      reductionInWeight,
      reductionInCost,
      reductionInWastePerCover,
      wastePerCoverForCompany,
      carbonDioxideEmissionAvoided,
      numberOfMealsSaved,
    } = wasteReductionAnalysisAndWastePerCoverForKeyOverview;

    newKeyOverviewValueCardData.totalWeightOfWaste.dataVariable = weight;
    newKeyOverviewValueCardData.totalCostOfWaste.dataVariable = cost;
    newKeyOverviewValueCardData.totalCostOfWaste.dataBackUnit = ` ${currency}`;
    newKeyOverviewValueCardData.reductionInWeight.dataVariable = reductionInWeight.valueForCompany;
    newKeyOverviewValueCardData.reductionInCost.dataVariable = reductionInCost.valueForCompany;
    newKeyOverviewValueCardData.reductionInCost.dataBackUnit = ` ${currency}`;
    newKeyOverviewValueCardData.wastePerCover.dataVariable = wastePerCoverForCompany;
    newKeyOverviewValueCardData.reductionInWastePerCover.dataVariable =
      reductionInWastePerCover.valueForCompany;
    newKeyOverviewValueCardData.co2Emissions.dataVariable = carbonDioxideEmissionAvoided;
    newKeyOverviewValueCardData.mealsSaved.dataVariable = numberOfMealsSaved;

    return Object.values(newKeyOverviewValueCardData);
  }

  render() {
    const {
      classes,
      onChangeExpansionPanel,
      wasteAnalysisForKeyOverview,
      wasteReductionAnalysisAndWastePerCoverForKeyOverview,
      isCompanyUser,
      isTooltipOpened,
      onClickInfoIcon,
      onClickAwayTooltip,
      onCloseTooltip,
      tabValue,
      t,
    } = this.props;
    const { page, showMoreValue } = this.state;

    // For pagination
    let arrKeyOverviewValueCardToRender = this.addDataToKeyOverviewValueCard(
      wasteAnalysisForKeyOverview,
      wasteReductionAnalysisAndWastePerCoverForKeyOverview
    );

    arrKeyOverviewValueCardToRender = arrKeyOverviewValueCardToRender.slice(
      (page - 1) * 4,
      (page - 1) * 4 + 4
    );

    return (
      <ExpansionPanel
        id="key-overview-card"
        className={classes.root}
        expanded
        onChange={onChangeExpansionPanel}
      >
        <CustomExpansionSummary>
          <div className={classes.keyOverviewCard}>
            <Typography variant="h1" className={classes.spacedTitle}>
              {t('keyOverview.header')}
            </Typography>
            <Typography variant="subtitle2" className={classes.keyOverviewSubtext}>
              {`* ${t('keyOverview.subHeader')}`}
            </Typography>
            <Grid container spacing={2} className={classes.keyOverviewCardGrid}>
              {arrKeyOverviewValueCardToRender.map((keyOverviewValueCard) => (
                <KeyOverviewValueCard
                  key={keyOverviewValueCard.dataName}
                  dataFrontUnit={keyOverviewValueCard.dataFrontUnit}
                  dataBackUnit={
                    typeof keyOverviewValueCard.dataVariable === 'number'
                      ? keyOverviewValueCard.dataBackUnit
                      : ''
                  }
                  dataName={keyOverviewValueCard.dataName}
                  dataVariable={keyOverviewValueCard.dataVariable}
                  isButtonDisabled={keyOverviewValueCard.isButtonDisabled}
                  showMoreOnClick={(newShowMoreValue) => this.changeShowMoreValue(newShowMoreValue)}
                  selector={keyOverviewValueCard.showMoreValue}
                  SHOW_MORE_VALUE={SHOW_MORE_VALUE}
                  formatValue={formatValue}
                />
              ))}
            </Grid>
            <CustomPagination
              count={2}
              onChange={(event, newPage) => this.changePagination(event, newPage)}
            />
          </div>
        </CustomExpansionSummary>
        {/* Show More: Total Weight / Total Cost */}
        {(showMoreValue === SHOW_MORE_VALUE.totalWeight ||
          showMoreValue === SHOW_MORE_VALUE.totalCost) && (
          <WeightCostShowMore
            classes={classes}
            displayTotalWastageTooltipText={(value) => this.displayTotalWastageTooltipText(value)}
            imageIcon={showMoreValue === SHOW_MORE_VALUE.totalWeight ? WeightImg : CostImg}
            isTooltipOpened={isTooltipOpened}
            onClickAwayTooltip={onClickAwayTooltip}
            onClickInfoIcon={onClickInfoIcon}
            onCloseTooltip={onCloseTooltip}
            showMoreValue={showMoreValue}
            SHOW_MORE_VALUE={SHOW_MORE_VALUE}
            tabValue={tabValue}
            wasteAnalysisForKeyOverview={wasteAnalysisForKeyOverview}
          />
        )}
        {/* Show More: Reduction in Weight / Reduction In Cost / Reduction in Waste per Cover */}
        {(showMoreValue === SHOW_MORE_VALUE.reductionInWeight ||
          showMoreValue === SHOW_MORE_VALUE.reductionInCost ||
          showMoreValue === SHOW_MORE_VALUE.reductionInWastePerCover) && (
          <ReductionShowMore
            classes={classes}
            imageIcon={this.getReductionImageIcon()}
            isTooltipOpened={isTooltipOpened}
            onClickAwayTooltip={onClickAwayTooltip}
            onClickInfoIcon={onClickInfoIcon}
            onCloseTooltip={onClickAwayTooltip}
            showMoreValue={showMoreValue}
            SHOW_MORE_VALUE={SHOW_MORE_VALUE}
            formatValue={formatValue}
            wasteReductionAnalysisForKeyOverview={
              wasteReductionAnalysisAndWastePerCoverForKeyOverview[showMoreValue]
            }
          />
        )}
        {/* Show More: Waste Per Cover */}
        {showMoreValue === SHOW_MORE_VALUE.wastePerCover && (
          <WastePerCoverShowMore
            classes={classes}
            imageIcon={FoodWasteImg}
            wasteReductionAnalysisAndWastePerCoverForKeyOverview={
              wasteReductionAnalysisAndWastePerCoverForKeyOverview
            }
            isCompanyUser={isCompanyUser}
          />
        )}
      </ExpansionPanel>
    );
  }
}

KeyOverview.contextType = AppContext;

export default withTranslation()(withTheme(withStyles(styles)(KeyOverview)));
