import axios from 'axios';
import React, { PureComponent } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { withAuth0 } from '@auth0/auth0-react';
import { Box, Grid } from '@material-ui/core';
import { withStyles, withTheme } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';

import KeyOverview from './KeyOverview';
import RestaurantBreakdown from './restaurant-breakdown/RestaurantBreakdown';
import Watchlist from './watchlist/Watchlist';
// eslint-disable-next-line no-unused-vars
import typedefs from '../typedefs';
import { ARR_PASTEL_COLOR, ARR_SERVICE_ORDER } from '../common/chart/Constants';
import { AppContext, CONSTANT } from '../../AppContext';

const styles = (theme) => ({
  rootGridContainer: {
    paddingLeft: theme.main.paddingLeftRight,
    paddingRight: theme.main.paddingLeftRight,
    paddingTop: '5px',
    paddingBottom: '5px',
  },
  rootGridItem: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  leftColumnContainer: {
    [theme.breakpoints.up('md')]: {
      paddingRight: '15px',
    },
  },
  rightColumnContainer: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: '15px',
    },
  },
  stickyWatchlist: {
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  menuItemMappingWarning: {
    borderRadius: '10px',
  },
});

/**
 * Get the following arrays:
 * - An array of watchlist items where their ranks are to be updated, with the new ranks reflected, to be sent to Backend to be updated in the backend
 * - An array of all watchlist items with the affected ones updated with the new ranks, for the set state in the Frontend
 * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
 * @returns {Object} Object - Object containing arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend and arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
 * @returns {typedefs.WatchlistItemMenuItemRestaurant[]} arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend - Array of watchlist items with the updated rank and an empty array of menuItemRestaurant
 * @returns {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend - Array of watchlist items with the updated rank (those affected) and its array of menuItemRestaurantWasteAnalysis
 */
const getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend = (
  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
) => {
  const arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend = [];
  const arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend = [];

  arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis.forEach(
    (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, index) => {
      const newRank = index + 1;
      const watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend =
        reorderedWatchlistItemMenuItemRestaurantWasteAnalysis;
      if (reorderedWatchlistItemMenuItemRestaurantWasteAnalysis.rank !== newRank) {
        // Note that the update of rank has to be done within the if condition because watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
        // is a shallow copy of reorderedWatchlistItemMenuItemRestaurantWasteAnalysis, hence changing the rank out of the if condition will render the if
        // condition ineffective
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.rank = newRank;
        // This watchlistItemMenuItemRestaurantForRankToBeUpdated object contains weight, cost and arrMenuItemRestaurantWasteAnalysis
        // which are not required for the updating of ranks in database, but kept in the object as it does not cause any other
        // complications so there is no need for extra code to remove them
        const watchlistItemMenuItemRestaurantForRankToBeUpdated = {
          ...watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
          arrMenuItemRestaurantWasteAnalysis: [],
        };
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.push(
          watchlistItemMenuItemRestaurantForRankToBeUpdated
        );
      }
      arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend.push(
        watchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend
      );
    }
  );
  return {
    arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
    arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
  };
};

/**
 * Check if there is any data for the line chart to be display on the Key Overview
 * @param {typedefs.WasteAnalysisForKeyOverview} wasteAnalysisForKeyOverview - Waste analysis for key overview
 */
const checkShouldKeyOverviewLineChart = (wasteAnalysisForKeyOverview) => {
  return wasteAnalysisForKeyOverview.wasteAnalysisForLineChart.arrDate.length > 1;
};

/**
 * Check if there is any data for the bar charts for restaurants to be display on the Key Overview
 * @param {typedefs.WasteAnalysisForKeyOverview} wasteAnalysisForKeyOverview - Waste analysis for key overview
 * @param {boolean} isCompanyUser - Indicates if user is company user
 */
const checkShouldDisplayKeyOverviewBarChartForRestaurants = (
  wasteAnalysisForKeyOverview,
  isCompanyUser
) => {
  return (
    wasteAnalysisForKeyOverview.wasteAnalysisForBarChart.xAxisArrRestaurantName.length > 1 &&
    isCompanyUser
  );
};

/**
 * Loop through the array of location name to assign a color for each location and store the data as a map. The array contains all the location name of the restaurant.
 * The pastel colors are used when generating the graphs in the Wastage By Day section.
 * The maximum number of colours available is 20.
 * @param {typedefs.string[]} arrLocationName - Array of location name
 * @returns {Map<string, string} - Map consisting of location name and its correspsonding pastel color
 */
const getMapPastelColorByLocationName = (arrLocationName) => {
  const mapPastelColorByLocationName = new Map();
  arrLocationName.forEach((locationName, locationNameIndex) => {
    mapPastelColorByLocationName.set(locationName, ARR_PASTEL_COLOR[locationNameIndex]);
  });
  return mapPastelColorByLocationName;
};

/**
 * Sort service types in ascending order by the service type id
 * @param {typedefs.ServiceType[]} arrServiceType - Array of serviceType to be sorted
 */
const sortByServiceTypeIdInAscendingOrder = (arrServiceType) => {
  arrServiceType.sort(
    (serviceTypeA, serviceTypeB) => serviceTypeA.serviceTypeId - serviceTypeB.serviceTypeId
  );
};

/**
 * Initialise a map of restaurant/location with the map that contains the array of all service types and the array of the selected service types for all location/service group. The selected
 * service type will be the same as all the service types of the restaurant/location since the user has yet to make any change. Whether it is for restaurant and location or location and service
 * group depends on the type of user. If it is a company user, it will be a map by restaurant name by location name, where there is a location name called "All Locations" to hold all the different
 * service types of the location.. Else, it will be a map by location name by service group, where there is a service group called "All Services" to hold all the different service types of the location.
 * @param {typedefs.RestaurantService[]} arrRestaurantService - Array of restaurantService to get the service types from
 * @param {boolean} isCompanyUser - Indicates if the user is a company user or not
 * @returns {Map<string, Map<string, { arrAllServiceType: typedefs.ServiceType[], arrSelectedServiceType: typedefs.ServiceType[] }>>} mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName - Map containing restaurant/location name with its corresponding object of arrAllServiceType and arrSelectedServiceType
 */
const initialiseMapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName = (
  arrRestaurantService,
  isCompanyUser
) => {
  const mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName = new Map();
  if (isCompanyUser) {
    arrRestaurantService.forEach((restaurantService) => {
      const { name: restaurantName, arrLocationService } = restaurantService;
      const mapServiceTypeByServiceTypeIdForAllLocations = new Map();
      const mapMapServiceTypeByServiceTypeIdByLocationName = new Map();
      arrLocationService.forEach((locationService) => {
        const { name: locationName } = locationService;
        locationService.arrService.forEach((service) => {
          const { serviceType } = service;
          mapServiceTypeByServiceTypeIdForAllLocations.set(serviceType.serviceTypeId, serviceType);
          if (mapMapServiceTypeByServiceTypeIdByLocationName.has(locationName)) {
            mapMapServiceTypeByServiceTypeIdByLocationName
              .get(locationName)
              .set(serviceType.serviceTypeId, serviceType);
          } else {
            mapMapServiceTypeByServiceTypeIdByLocationName.set(
              locationName,
              new Map([[serviceType.serviceTypeId, serviceType]])
            );
          }
        });
      });
      // For 'All Locations'
      const arrServiceTypeForAllLocations = Array.from(
        mapServiceTypeByServiceTypeIdForAllLocations.values()
      );
      sortByServiceTypeIdInAscendingOrder(arrServiceTypeForAllLocations);
      mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName.set(
        restaurantName,
        new Map([
          [
            'All Locations',
            {
              arrAllServiceType: arrServiceTypeForAllLocations,
              arrSelectedServiceType: arrServiceTypeForAllLocations,
            },
          ],
        ])
      );
      // For each location
      mapMapServiceTypeByServiceTypeIdByLocationName.forEach(
        (mapServiceTypeByServiceTypeId, locationName) => {
          const arrServiceTypeForLocation = Array.from(mapServiceTypeByServiceTypeId.values());
          sortByServiceTypeIdInAscendingOrder(arrServiceTypeForLocation);
          mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName
            .get(restaurantName)
            .set(locationName, {
              arrAllServiceType: arrServiceTypeForLocation,
              arrSelectedServiceType: arrServiceTypeForLocation,
            });
        }
      );
    });
  } else {
    arrRestaurantService.forEach((restaurantService) => {
      restaurantService.arrLocationService.forEach((locationService) => {
        const { name: locationName, arrService } = locationService;
        const mapServiceTypeByServiceTypeIdForAllServiceGroups = new Map();
        const mapMapServiceTypeByServiceTypeIdByGroup = new Map();
        arrService.forEach((service) => {
          const { serviceType, group, name: serviceName } = service;
          const presumedGroup = group || serviceName;
          mapServiceTypeByServiceTypeIdForAllServiceGroups.set(
            serviceType.serviceTypeId,
            serviceType
          );
          if (mapMapServiceTypeByServiceTypeIdByGroup.has(presumedGroup)) {
            mapMapServiceTypeByServiceTypeIdByGroup
              .get(presumedGroup)
              .set(serviceType.serviceTypeId, serviceType);
          } else {
            mapMapServiceTypeByServiceTypeIdByGroup.set(
              presumedGroup,
              new Map([[serviceType.serviceTypeId, serviceType]])
            );
          }
        });
        // For 'All Services'
        const arrServiceTypeForAllServiceGroups = Array.from(
          mapServiceTypeByServiceTypeIdForAllServiceGroups.values()
        );
        sortByServiceTypeIdInAscendingOrder(arrServiceTypeForAllServiceGroups);
        mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName.set(
          locationName,
          new Map([
            [
              'All Services',
              {
                arrAllServiceType: arrServiceTypeForAllServiceGroups,
                arrSelectedServiceType: arrServiceTypeForAllServiceGroups,
              },
            ],
          ])
        );
        // For each service group
        mapMapServiceTypeByServiceTypeIdByGroup.forEach((mapServiceTypeByServiceTypeId, group) => {
          const arrServiceTypeForServiceGroup = Array.from(mapServiceTypeByServiceTypeId.values());
          sortByServiceTypeIdInAscendingOrder(arrServiceTypeForServiceGroup);
          mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName
            .get(locationName)
            .set(group, {
              arrAllServiceType: arrServiceTypeForServiceGroup,
              arrSelectedServiceType: arrServiceTypeForServiceGroup,
            });
        });
      });
    });
  }
  return mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName;
};
class Home extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      // If fetching data is done
      isWasteAnalysisForHighlightsFetched: false,

      // Waste Analysis For Highlights
      arrRestaurantWasteAnalysisForHighlights: [],
      wasteAnalysisForKeyOverview: {},
      arrMenuItemRestaurantWasteAnalysis: [],
      arrWatchlistItemMenuItemRestaurantWasteAnalysis: [],

      // To store previous start date and end date for condition for componentDidUpdate
      previousSelectedStartDate: '',
      previousSelectedEndDate: '',
      previousSelectedGroupBy: '',

      // Parameters for Restaurant Breakdown
      isTooltipForRestaurantBreakdownOpened: false,
      arrSelectedMenuItemServiceWasteAnalysis: [],
      levelTabValue: 0,
      isRightArrowDisabled: false,
      isLeftArrowDisabled: true,
      isWastageOverTimeLineChartLoading: false,
      selectedRestaurantService: null,
      selectedRestaurantWasteAnalysisForHighlights: null,
      selectedLocationWasteAnalysisForHighlights: null,
      selectedGroupedServiceWasteAnalysisForHighlights: null,
      selectedLocationName: '',
      selectedServiceGroup: '',
      selectedToggleValueForWeightCostForRestaurantBreakdown: 'weight', // weight or cost
      mapPastelColorByLocationName: null,
      mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName: new Map(),

      // Key Overview
      isKeyOverviewPanelExpanded: true,
      isTooltipForKeyOverviewOpened: false,
      shouldDisplayKeyOverviewLineChart: false,
      shouldDisplayKeyOverviewBarChartForRestaurants: false,

      // Styles used to update sticky Watchlist
      stickyWatchlistWidth: '100%',
      stickyWatchlistTransform: null,
      stickyWatchlistTransition: null,

      // Menu item mapping warning
      isMenuItemMappingWarningOpened: false,
      menuItemMappingWarningCount: 0,
    };

    // Create a ref assigned to a component in render()
    this.rightColumnContainerRef = React.createRef();
  }

  componentDidMount() {
    document.title = 'Lumitics | Towards Zero Food Waste';
    const { organisationName, setPageHistory } = this.context;

    setPageHistory({ organisationName, pageNameKey: CONSTANT.highlightsPage });
    this.setPreviousSelectedStartEndDateAndGroupBy();
    this.fetchWasteAnalysisForHighlights();
    // Handle window resize events
    window.addEventListener('resize', () => this.updateWatchlistStyle());
    this.updateWatchlistStyle();
  }

  componentDidUpdate() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    const { previousSelectedStartDate, previousSelectedEndDate, previousSelectedGroupBy } =
      this.state;
    /* 
      Comparing the context directly in the following way:
      prevProps.context !== this.context 
      prevProps.context.selectedStartDate !== this.context.selectedStartDate
      somehow cause infinite loop and multiple calls to fetchWasteAnalysisForHighlights()
     */
    if (
      previousSelectedStartDate !== selectedStartDate ||
      previousSelectedEndDate !== selectedEndDate ||
      previousSelectedGroupBy !== selectedGroupBy
    ) {
      // Update previousSelectedStartDate, previousSelectedEndDate and previousSelectedGroupBy
      // with new context ones
      this.setPreviousSelectedStartEndDateAndGroupBy();

      this.fetchWasteAnalysisForHighlights();
    }

    this.updateWatchlistStyle();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.updateWatchlistStyle());
  }

  onCloseRestaurantBreakdownTooltip() {
    this.closeRestaurantBreakdownTooltip();
  }

  onClickAwayRestaurantBreakdownTooltip() {
    this.closeRestaurantBreakdownTooltip();
  }

  onClickRestaurantBreakdownInfoIcon() {
    const { isTooltipForRestaurantBreakdownOpened } = this.state;

    this.setState({
      isTooltipForRestaurantBreakdownOpened: !isTooltipForRestaurantBreakdownOpened,
    });
  }

  onClickClearSelectionButton() {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: [],
    });
  }

  /**
   * This function filters out menu item(s) that is already on the watchlist from arrSelectedMenuItemServiceWasteAnalysis and passes it to
   * the addToWatchlist function. Also set state to unselect the menu item(s).
   */
  onClickAddMenuItemToWatchlist() {
    const { arrMenuItemRestaurantWasteAnalysis, arrSelectedMenuItemServiceWasteAnalysis } =
      this.state;
    const arrMenuItemRestaurantWasteAnalysisToBeAdded = [];

    arrSelectedMenuItemServiceWasteAnalysis.forEach((selectedMenuItemServiceWasteAnalysis) => {
      const matchedMenuItemRestaurantWasteAnalysis = arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurant) =>
          menuItemRestaurant.menuItemId === selectedMenuItemServiceWasteAnalysis.menuItemId &&
          menuItemRestaurant.serviceId === selectedMenuItemServiceWasteAnalysis.serviceId
      );
      if (!this.isMenuItemAlreadyOnWatchlist(matchedMenuItemRestaurantWasteAnalysis)) {
        arrMenuItemRestaurantWasteAnalysisToBeAdded.push(matchedMenuItemRestaurantWasteAnalysis);
      }
    });

    this.addToWatchlist(arrMenuItemRestaurantWasteAnalysisToBeAdded);
    this.setState({ arrSelectedMenuItemServiceWasteAnalysis: [] });
  }

  /**
   * Change restaurant/location tab when left arrow button is clicked. If leftmost tab is reached, disable the left arrow button. Else, set state with the
   * newly selected restaurant.location and the first location/service group on the its array.
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  onClickLeftArrowIconButton(isCompanyUser) {
    const { arrRestaurantService } = this.props;
    const { levelTabValue } = this.state;
    const newTabValue = levelTabValue - 1;

    if (newTabValue >= 0) {
      if (isCompanyUser) {
        const { arrRestaurantWasteAnalysisForHighlights } = this.state;
        const selectedRestaurantService = arrRestaurantService[newTabValue];
        const selectedRestaurantWasteAnalysisForHighlights =
          arrRestaurantWasteAnalysisForHighlights.find(
            (restaurantWasteAnalysisForHighlights) =>
              restaurantWasteAnalysisForHighlights.restaurantId ===
              selectedRestaurantService.restaurantId
          );
        const selectedLocationName =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0]
            .name;
        const selectedLocationWasteAnalysisForHighlights =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0];
        const mapPastelColorByLocationName = getMapPastelColorByLocationName(
          selectedRestaurantService.arrLocationService.map(
            (locationService) => locationService.name
          )
        );
        this.setState({
          selectedRestaurantService,
          selectedRestaurantWasteAnalysisForHighlights,
          levelTabValue: newTabValue,
          selectedLocationName,
          selectedLocationWasteAnalysisForHighlights,
          mapPastelColorByLocationName,
        });
      } else {
        const { selectedRestaurantService, selectedRestaurantWasteAnalysisForHighlights } =
          this.state;
        const selectedLocationService = selectedRestaurantService.arrLocationService[newTabValue];
        const selectedLocationWasteAnalysisForHighlights =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
            (locationWasteAnalysisForHighlights) =>
              locationWasteAnalysisForHighlights.locationId === selectedLocationService.locationId
          );
        const selectedGroupedServiceWasteAnalysisForHighlights =
          selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights[0];
        this.setState({
          selectedLocationWasteAnalysisForHighlights,
          levelTabValue: newTabValue,
          selectedServiceGroup: selectedGroupedServiceWasteAnalysisForHighlights.name,
          selectedLocationName: selectedLocationService.name,
          selectedGroupedServiceWasteAnalysisForHighlights,
        });
      }
      this.disableArrowButton(newTabValue, isCompanyUser);
    } else {
      this.setState({ isLeftArrowDisabled: true }); // Reached leftmost tab
    }
  }

  /**
   * Change restaurant/location tab when right arrow button is clicked. If rightmost tab is reached, disable the right arrow button. Else, set state with the
   * newly selected restaurant/location and the first location/service group on the its array.
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  onClickRightArrowIconButton(isCompanyUser) {
    const { arrRestaurantService } = this.props;
    const { levelTabValue } = this.state;
    const newTabValue = levelTabValue + 1;

    if (
      (isCompanyUser && newTabValue < arrRestaurantService.length) ||
      (!isCompanyUser && newTabValue < arrRestaurantService[0].arrLocationService.length)
    ) {
      if (isCompanyUser) {
        const { arrRestaurantWasteAnalysisForHighlights } = this.state;
        const selectedRestaurantService = arrRestaurantService[newTabValue];
        const selectedRestaurantWasteAnalysisForHighlights =
          arrRestaurantWasteAnalysisForHighlights.find(
            (restaurantWasteAnalysisForHighlights) =>
              restaurantWasteAnalysisForHighlights.restaurantId ===
              selectedRestaurantService.restaurantId
          );
        const selectedLocationName =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0]
            .name;
        const selectedLocationWasteAnalysisForHighlights =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0];
        const mapPastelColorByLocationName = getMapPastelColorByLocationName(
          selectedRestaurantService.arrLocationService.map(
            (locationService) => locationService.name
          )
        );
        this.setState({
          selectedRestaurantService,
          selectedRestaurantWasteAnalysisForHighlights,
          levelTabValue: newTabValue,
          selectedLocationName,
          selectedLocationWasteAnalysisForHighlights,
          mapPastelColorByLocationName,
        });
      } else {
        const { selectedRestaurantService, selectedRestaurantWasteAnalysisForHighlights } =
          this.state;
        const selectedLocationService = selectedRestaurantService.arrLocationService[newTabValue];
        const selectedLocationWasteAnalysisForHighlights =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
            (locationWasteAnalysisForHighlights) =>
              locationWasteAnalysisForHighlights.locationId === selectedLocationService.locationId
          );
        const selectedGroupedServiceWasteAnalysisForHighlights =
          selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights[0];
        this.setState({
          selectedLocationWasteAnalysisForHighlights,
          levelTabValue: newTabValue,
          selectedServiceGroup: selectedGroupedServiceWasteAnalysisForHighlights.name,
          selectedLocationName: selectedLocationService.name,
          selectedGroupedServiceWasteAnalysisForHighlights,
        });
      }
      this.disableArrowButton(newTabValue, isCompanyUser);
    } else {
      this.setState({ isRightArrowDisabled: true }); // Reached rightmost tab
    }
  }

  /**
   * Change restaurant/location tab when the tab is clicked on the tab panel. Set state with the newly selected restaurant/location and the first location/service group on the its array.
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  onChangeTabList(event, newValue, isCompanyUser) {
    const { arrRestaurantService } = this.props;
    const newTabValue = parseInt(newValue, 10);

    if (isCompanyUser) {
      const { arrRestaurantWasteAnalysisForHighlights } = this.state;
      const selectedRestaurantService = arrRestaurantService[newTabValue];
      const selectedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysisForHighlights) =>
            restaurantWasteAnalysisForHighlights.restaurantId ===
            selectedRestaurantService.restaurantId
        );
      const selectedLocationName =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0].name;
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[0];
      const mapPastelColorByLocationName = getMapPastelColorByLocationName(
        selectedRestaurantService.arrLocationService.map((locationService) => locationService.name)
      );
      this.setState({
        selectedRestaurantService,
        selectedRestaurantWasteAnalysisForHighlights,
        levelTabValue: newTabValue,
        selectedLocationName,
        selectedLocationWasteAnalysisForHighlights,
        mapPastelColorByLocationName,
      });
    } else {
      const { selectedRestaurantService, selectedRestaurantWasteAnalysisForHighlights } =
        this.state;
      const selectedLocationService = selectedRestaurantService.arrLocationService[newTabValue];
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) =>
            locationWasteAnalysisForHighlights.locationId === selectedLocationService.locationId
        );
      const selectedGroupedServiceWasteAnalysisForHighlights =
        selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights[0];
      this.setState({
        selectedLocationWasteAnalysisForHighlights,
        levelTabValue: newTabValue,
        selectedServiceGroup: selectedGroupedServiceWasteAnalysisForHighlights.name,
        selectedLocationName: selectedLocationService.name,
        selectedGroupedServiceWasteAnalysisForHighlights,
      });
    }
    this.disableArrowButton(newTabValue, isCompanyUser);
  }

  onChangeToggleWeightCostButtonForRestaurantBreakdown(event, newSelectedToggleValue) {
    this.setState({
      selectedToggleValueForWeightCostForRestaurantBreakdown: newSelectedToggleValue,
    });
  }

  /**
   * Change selected location/service group
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  onChangeDropdownList(event, isCompanyUser) {
    const { value } = event.target;

    if (isCompanyUser) {
      const { selectedRestaurantWasteAnalysisForHighlights } = this.state;
      const selectedLocationWasteAnalysisForHighlights =
        selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) => locationWasteAnalysisForHighlights.name === value
        );
      this.setState({
        selectedLocationName: value,
        selectedLocationWasteAnalysisForHighlights,
      });
    } else {
      const { selectedLocationWasteAnalysisForHighlights } = this.state;
      const selectedGroupedServiceWasteAnalysisForHighlights =
        selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights.find(
          (groupedServiceWasteAnalysisForHighlights) =>
            groupedServiceWasteAnalysisForHighlights.name === value
        );
      this.setState({
        selectedServiceGroup: value,
        selectedGroupedServiceWasteAnalysisForHighlights,
      });
    }
  }

  onClickKeyOverviewInfoIcon(event) {
    const { isTooltipForKeyOverviewOpened } = this.state;

    this.setState({ isTooltipForKeyOverviewOpened: !isTooltipForKeyOverviewOpened });
    event.stopPropagation(); // Don't propagate click to expansion panel
  }

  onCloseKeyOverviewTooltip() {
    this.closeKeyOverviewTooltip();
  }

  onClickAwayKeyOverviewTooltip() {
    this.closeKeyOverviewTooltip();
  }

  /**
   * Check if the app is run on desktop, and change teh overview expansion panel accordingly
   */
  onChangeKeyOverviewExpansionPanel() {
    const { isKeyOverviewPanelExpanded } = this.state;

    if (this.checkIsDesktopView()) {
      this.setState({ isKeyOverviewPanelExpanded: true });
    } else {
      this.setState({ isKeyOverviewPanelExpanded: !isKeyOverviewPanelExpanded });
    }
  }

  setIsWastageOverTimeLineChartLoading(boolean) {
    this.setState({
      isWastageOverTimeLineChartLoading: boolean,
    });
  }

  setPreviousSelectedStartEndDateAndGroupBy() {
    const { selectedStartDate, selectedEndDate, selectedGroupBy } = this.context;
    this.setState({
      previousSelectedStartDate: selectedStartDate,
      previousSelectedEndDate: selectedEndDate,
      previousSelectedGroupBy: selectedGroupBy,
    });
  }

  /**
   * Get an array of location/service group options for dropdown. 'All Locations' / 'All Service Groups' will always be at the top of the dropdown list.
   * The rest of the items will be ordered by alphabetical order if the list is for location (user is a company user) or by the ARR_SERVICE_ORDER if the
   * list is for service group (user is a non-company user).
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  getArrDropdownItem(isCompanyUser) {
    const {
      selectedRestaurantWasteAnalysisForHighlights,
      selectedLocationWasteAnalysisForHighlights,
    } = this.state;

    let arrDropdownItem = [];

    if (isCompanyUser) {
      if (selectedRestaurantWasteAnalysisForHighlights) {
        const arrUnorderedDropdownItem =
          selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.map(
            (locationWasteAnalysisForHighlights) => {
              return {
                value: locationWasteAnalysisForHighlights.name,
                name: locationWasteAnalysisForHighlights.name,
              };
            }
          );
        const firstDropdownItem = arrUnorderedDropdownItem[0];
        const arrDropdownItemWithoutFirstItem = arrUnorderedDropdownItem.slice(1);
        arrDropdownItemWithoutFirstItem.sort((dropdownItemA, dropdownItemB) => {
          return dropdownItemA.name > dropdownItemB.name;
        });
        arrDropdownItem = [firstDropdownItem, ...arrDropdownItemWithoutFirstItem];
      }
    } else if (selectedLocationWasteAnalysisForHighlights) {
      const arrUnorderedDropdownItem =
        selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights.map(
          (groupedServiceWasteAnalysisForHighlights) => {
            return {
              value: groupedServiceWasteAnalysisForHighlights.name,
              name: groupedServiceWasteAnalysisForHighlights.name,
            };
          }
        );
      const firstDropdownItem = arrUnorderedDropdownItem[0];
      const arrDropdownItemWithoutFirstItem = arrUnorderedDropdownItem.slice(1);
      arrDropdownItemWithoutFirstItem.sort((dropdownItemA, dropdownItemB) => {
        const dropdownItemAIndex = ARR_SERVICE_ORDER.indexOf(dropdownItemA.name.toLowerCase());
        const dropdownItemBIndex = ARR_SERVICE_ORDER.indexOf(dropdownItemB.name.toLowerCase());
        return dropdownItemAIndex > dropdownItemBIndex;
      });
      arrDropdownItem = [firstDropdownItem, ...arrDropdownItemWithoutFirstItem];
    }

    return arrDropdownItem;
  }

  /**
   * Fetch the bar line chart data based on the selected service types. Only services of the selected service types will be sent to the Backend for waste analysis.
   * There is different handling between company and non-company user due to the former having grouped service as the lowest level of analysis for the charts
   * while the latter having location as the lowest level of analysis for the charts. Hence For company user, selectedGroupedServiceWasteAnalysisForHighlights
   * will be sent to the Backend while for for non-company user, the parameter will be selectedLocationWasteAnalysisForHighlights.
   *
   * Upon receiving the response from the Backend, replace only the bar line chart data portion from arrRestaurantWasteAnalysisForHighlights as the remaining data
   * should remain unchange.
   * @param {Set<number>} setUpdatedSelectedServiceTypeId - Set of selected serviceTypeId
   */
  async updateSelectedServiceTypes(setUpdatedSelectedServiceTypeId) {
    const { impersonatorIsCompanyUser, openSnackbar, selectedGroupBy } = this.context;
    const { auth0, history } = this.props;
    const {
      arrRestaurantWasteAnalysisForHighlights,
      mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName,
      selectedLocationWasteAnalysisForHighlights,
      selectedGroupedServiceWasteAnalysisForHighlights,
      selectedRestaurantService,
      selectedLocationName,
      selectedServiceGroup,
    } = this.state;
    const { user } = auth0;
    const { datavizUserId: userId } = user;
    const isCompanyUser =
      (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);
    const arrSelectedGroupedServiceWasteAnalysisForBarLineChart = [];
    const arrSelectedLocationGroupedServiceWasteAnalysisForBarLineChart = [];
    if (isCompanyUser) {
      selectedLocationWasteAnalysisForHighlights.arrLocationGroupedServiceWasteAnalysisForBarLineChart.forEach(
        (locationGroupedServiceWasteAnalysisForBarLineChart) => {
          const arrSelectedGroupedServiceWasteAnalysisForBarLineChartForLocation = [];
          locationGroupedServiceWasteAnalysisForBarLineChart.arrGroupedServiceWasteAnalysisForBarLineChart.forEach(
            (groupedServiceWasteAnalysisForBarLineChart) => {
              const arrSelectedServiceWasteAnalysisForBarLineChart =
                groupedServiceWasteAnalysisForBarLineChart.arrServiceWasteAnalysisForBarLineChart.filter(
                  (serviceWasteAnalysisForBarLineChart) =>
                    setUpdatedSelectedServiceTypeId.has(
                      serviceWasteAnalysisForBarLineChart.serviceType.serviceTypeId
                    )
                );
              if (arrSelectedServiceWasteAnalysisForBarLineChart.length !== 0) {
                arrSelectedGroupedServiceWasteAnalysisForBarLineChartForLocation.push({
                  ...groupedServiceWasteAnalysisForBarLineChart,
                  arrServiceWasteAnalysisForBarLineChart:
                    arrSelectedServiceWasteAnalysisForBarLineChart,
                });
              }
            }
          );
          if (arrSelectedGroupedServiceWasteAnalysisForBarLineChartForLocation.length !== 0) {
            arrSelectedLocationGroupedServiceWasteAnalysisForBarLineChart.push({
              ...locationGroupedServiceWasteAnalysisForBarLineChart,
              arrGroupedServiceWasteAnalysisForBarLineChart:
                arrSelectedGroupedServiceWasteAnalysisForBarLineChartForLocation,
            });
          }
        }
      );
    } else {
      selectedGroupedServiceWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForBarLineChart.forEach(
        (groupedServiceWasteAnalysisForBarLineChart) => {
          const arrSelectedServiceWasteAnalysisForBarLineChart =
            groupedServiceWasteAnalysisForBarLineChart.arrServiceWasteAnalysisForBarLineChart.filter(
              (serviceWasteAnalysisForBarLineChart) =>
                setUpdatedSelectedServiceTypeId.has(
                  serviceWasteAnalysisForBarLineChart.serviceType.serviceTypeId
                )
            );
          if (arrSelectedServiceWasteAnalysisForBarLineChart.length !== 0) {
            arrSelectedGroupedServiceWasteAnalysisForBarLineChart.push({
              ...groupedServiceWasteAnalysisForBarLineChart,
              arrServiceWasteAnalysisForBarLineChart:
                arrSelectedServiceWasteAnalysisForBarLineChart,
            });
          }
        }
      );
    }
    try {
      this.setIsWastageOverTimeLineChartLoading(true);
      const token = await auth0.getAccessTokenSilently();
      const response = await axios.post(
        '/api/fetch-selected-service-type-waste-analysis-for-charts',
        {
          userId,
          groupBy: selectedGroupBy,
          ...(isCompanyUser
            ? { arrSelectedLocationGroupedServiceWasteAnalysisForBarLineChart }
            : { arrSelectedGroupedServiceWasteAnalysisForBarLineChart }),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { selectedServiceTypeWasteAnalysisForCharts } = response.data;
      const arrUpdatedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights;
      let updatedSelectedRestaurantWasteAnalysisForHighlights = null;
      let updatedSelectedLocationWasteAnalysisForHighlights = null;
      let updatedSelectedGroupedServiceWasteAnalysisForHighlights = null;

      updatedSelectedRestaurantWasteAnalysisForHighlights =
        arrUpdatedRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysis) =>
            restaurantWasteAnalysis.restaurantId === selectedRestaurantService.restaurantId
        );
      updatedSelectedLocationWasteAnalysisForHighlights =
        updatedSelectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
          (locationWasteAnalysisForHighlights) =>
            locationWasteAnalysisForHighlights.name === selectedLocationName
        );
      if (isCompanyUser) {
        updatedSelectedLocationWasteAnalysisForHighlights.wasteAnalysisForBarLineChart =
          selectedServiceTypeWasteAnalysisForCharts;
      } else {
        updatedSelectedGroupedServiceWasteAnalysisForHighlights =
          updatedSelectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights.find(
            (groupedServiceWasteAnalysisForHighlights) =>
              groupedServiceWasteAnalysisForHighlights.name === selectedServiceGroup
          );
        updatedSelectedGroupedServiceWasteAnalysisForHighlights.wasteAnalysisForBarLineChart =
          selectedServiceTypeWasteAnalysisForCharts;
      }

      const parentNameKey = isCompanyUser ? selectedRestaurantService.name : selectedLocationName;
      const nameKey = isCompanyUser ? selectedLocationName : selectedServiceGroup;
      const { arrAllServiceType } =
        mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName
          .get(parentNameKey)
          .get(nameKey);
      const arrUpdatedSelectedServiceType = arrAllServiceType.filter((serviceType) =>
        setUpdatedSelectedServiceTypeId.has(serviceType.serviceTypeId)
      );
      mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName
        .get(parentNameKey)
        .set(nameKey, {
          arrAllServiceType,
          arrSelectedServiceType: arrUpdatedSelectedServiceType,
        });

      this.setIsWastageOverTimeLineChartLoading(false);
      this.setState({
        arrRestaurantWasteAnalysisForHighlights: arrUpdatedRestaurantWasteAnalysisForHighlights,
        selectedRestaurantWasteAnalysisForHighlights:
          updatedSelectedRestaurantWasteAnalysisForHighlights,
        selectedLocationWasteAnalysisForHighlights:
          updatedSelectedLocationWasteAnalysisForHighlights,
        selectedGroupedServiceWasteAnalysisForHighlights:
          updatedSelectedGroupedServiceWasteAnalysisForHighlights,
        mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName,
      });
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(
          'Unknown error during fetching selected service types for charts. Please notify admin.',
          'error'
        );
      }
    }
  }

  checkIsDesktopView() {
    const { theme } = this.props;

    return window.matchMedia(`(min-width: ${theme.breakpoints.values.md}px)`).matches;
  }

  closeRestaurantBreakdownTooltip() {
    this.setState({ isTooltipForRestaurantBreakdownOpened: false });
  }

  closeKeyOverviewTooltip() {
    this.setState({ isTooltipForKeyOverviewOpened: false });
  }

  /**
   * Check if a menu item is already on the watchlist
   * @param {typedefs.MenuItemServiceWasteAnalysis} menuItemServiceWasteAnalysis - Menu item to be verified if it is already on the watchlist
   * @returns {boolean} Boolean value to indicate if menu item is already on the watchlist
   */
  isMenuItemAlreadyOnWatchlist(menuItemServiceWasteAnalysis) {
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    const matchedWatchlistItemMenuItemRestaurantWasteAnalysis =
      arrWatchlistItemMenuItemRestaurantWasteAnalysis.find(
        (watchlistItemMenuItemRestaurantWasteAnalysis) =>
          menuItemServiceWasteAnalysis.menuItemName ===
          watchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis[0]
            .menuItemName
      );

    if (!matchedWatchlistItemMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    const matchedMenuItemRestaurantWasteAnalysis =
      matchedWatchlistItemMenuItemRestaurantWasteAnalysis.arrMenuItemRestaurantWasteAnalysis.find(
        (menuItemRestaurantWasteAnalysis) =>
          menuItemRestaurantWasteAnalysis.menuItemId === menuItemServiceWasteAnalysis.menuItemId
      );

    if (!matchedMenuItemRestaurantWasteAnalysis) {
      return false;
    }

    return true;
  }

  updateArrSelectedMenuItemServiceWasteAnalysis(arrNewlySelectedMenuItemServiceWasteAnalysis) {
    this.setState({
      arrSelectedMenuItemServiceWasteAnalysis: arrNewlySelectedMenuItemServiceWasteAnalysis,
    });
  }

  /**
   * Disable left/right arrow button when leftmost/rightmost tab is selected
   * @param {number} levelTabValue - Current selected tab value
   * @param {boolean} isCompanyUser - Indicates if user is company user
   */
  disableArrowButton(levelTabValue, isCompanyUser) {
    const { arrRestaurantService } = this.props;
    const { selectedRestaurantService } = this.state;

    const arrLevelService = isCompanyUser
      ? arrRestaurantService
      : selectedRestaurantService.arrLocationService;
    if (arrLevelService.length === 1) {
      // Edge case: when there's only 1 restaurant tab
      this.setState({ isRightArrowDisabled: true, isLeftArrowDisabled: true });
    } else if (levelTabValue >= arrLevelService.length - 1) {
      // Rightmost tab
      this.setState({ isRightArrowDisabled: true, isLeftArrowDisabled: false });
    } else if (levelTabValue <= 0) {
      // leftmost tab
      this.setState({ isLeftArrowDisabled: true, isRightArrowDisabled: false });
    } else {
      this.setState({ isRightArrowDisabled: false, isLeftArrowDisabled: false });
    }
  }

  /**
   * Update sticky watchlist style based on scrollable header's style
   */
  updateWatchlistStyle() {
    const { scrollableHeaderTransform, scrollableHeaderTransition, theme } = this.props;

    const isDesktopView = window.matchMedia(
      `(min-width: ${theme.breakpoints.values.md}px)`
    ).matches;
    if (isDesktopView) {
      // Calculate watchlist after its parent container has rendered
      const rightColumnContainer = this.rightColumnContainerRef.current;
      const watchlistWidth = rightColumnContainer ? rightColumnContainer.offsetWidth - 15 : '100%';
      // Desktop view
      this.setState({
        stickyWatchlistWidth: watchlistWidth,
        stickyWatchlistTransform: scrollableHeaderTransform,
        stickyWatchlistTransition: scrollableHeaderTransition,
      });
    } else {
      // Mobile view
      this.setState({
        stickyWatchlistWidth: '100%',
        stickyWatchlistTransform: null,
        stickyWatchlistTransition: null,
      });
    }
  }

  /**
   * Fetch waste analysis for highlights from the backend in one API call. Set state the first restaurant on arrRestaurantWasteAnalysisForHighlights as the
   * the selected restaurant as first location on the arrLocationWasteAnalysisForHighlights as the selected location.
   */
  async fetchWasteAnalysisForHighlights() {
    const {
      impersonatorIsCompanyUser,
      selectedStartDate,
      selectedEndDate,
      renderLoaderAnimation,
      openSnackbar,
      selectedGroupBy,
    } = this.context;
    const { auth0, arrRestaurantService, history, t } = this.props;
    const { levelTabValue, selectedLocationName, selectedServiceGroup } = this.state;
    const { user } = auth0;
    try {
      if (arrRestaurantService.length === 0) {
        throw new Error('No Service');
      }
      renderLoaderAnimation(true);
      let { isCompanyUser, datavizUserId: userId } = user;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
        isCompanyUser = impersonatorIsCompanyUser;
      }
      const token = await auth0.getAccessTokenSilently();
      const response = await axios.post(
        '/api/fetch-waste-analysis-for-highlights',
        {
          userId,
          arrRestaurantService,
          startDate: selectedStartDate,
          endDate: selectedEndDate,
          groupBy: selectedGroupBy,
          isCompanyUser,
          numberOfTopWastedFoodItemsToDisplay: CONSTANT.numberOfTopWastedFoodItemsToDisplay,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { wasteAnalysisForHighlights } = response.data;

      const {
        arrRestaurantWasteAnalysisForHighlights,
        wasteAnalysisForKeyOverview,
        arrMenuItemRestaurantWasteAnalysis,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        wasteReductionAnalysisAndWastePerCoverForKeyOverview,
        menuItemMappingWarningCount,
      } = wasteAnalysisForHighlights;

      const restaurantIndex = isCompanyUser ? levelTabValue : 0;
      const locationIndex = isCompanyUser ? 0 : levelTabValue;
      const selectedRestaurantService = arrRestaurantService[restaurantIndex];
      const selectedRestaurantWasteAnalysisForHighlights =
        arrRestaurantWasteAnalysisForHighlights.find(
          (restaurantWasteAnalysisForHighlights) =>
            restaurantWasteAnalysisForHighlights.restaurantId ===
            selectedRestaurantService.restaurantId
        );
      // Get the locationWasteAnalysisForHighlights based on the levelTabValue and selectedLocationName so that when the user changes a date range, selected location is retained
      const selectedLocationWasteAnalysisForHighlights = selectedLocationName
        ? selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights.find(
            (locationWasteAnalysisForHighlights) =>
              locationWasteAnalysisForHighlights.name === selectedLocationName
          )
        : selectedRestaurantWasteAnalysisForHighlights.arrLocationWasteAnalysisForHighlights[
            locationIndex
          ];
      const updatedSelectedLocationName = selectedLocationWasteAnalysisForHighlights.name;

      // selectedGroupedServiceWasteAnalysisForHighlights and selectedServiceGroup are required for non-company user only
      // Get the groupedServiceWasteAnalysisForHighlights based on the selectedServiceGroup so that when the user changes a date range, selected service group is retained
      let selectedGroupedServiceWasteAnalysisForHighlights = null;
      let updatedSelectedServiceGroup = null;
      if (
        selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights[0]
      ) {
        selectedGroupedServiceWasteAnalysisForHighlights = selectedServiceGroup
          ? selectedLocationWasteAnalysisForHighlights.arrGroupedServiceWasteAnalysisForHighlights.find(
              (groupedServiceWasteAnalysisForHighlights) =>
                groupedServiceWasteAnalysisForHighlights.name === selectedServiceGroup
            )
          : selectedLocationWasteAnalysisForHighlights
              .arrGroupedServiceWasteAnalysisForHighlights[0];
        updatedSelectedServiceGroup = selectedGroupedServiceWasteAnalysisForHighlights.name;
      }

      const shouldDisplayKeyOverviewBarChartForRestaurants =
        checkShouldDisplayKeyOverviewBarChartForRestaurants(
          wasteAnalysisForKeyOverview,
          isCompanyUser
        );
      const shouldDisplayKeyOverviewLineChart = checkShouldKeyOverviewLineChart(
        wasteAnalysisForKeyOverview
      );

      const mapPastelColorByLocationName = isCompanyUser
        ? getMapPastelColorByLocationName(
            selectedRestaurantService.arrLocationService.map(
              (locationService) => locationService.name
            )
          )
        : null;
      const mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName =
        initialiseMapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName(
          arrRestaurantService,
          isCompanyUser
        );
      this.setState({
        isMenuItemMappingWarningOpened: menuItemMappingWarningCount > 0,
        menuItemMappingWarningCount,
        isWasteAnalysisForHighlightsFetched: true,
        shouldDisplayKeyOverviewBarChartForRestaurants,
        shouldDisplayKeyOverviewLineChart,
        arrRestaurantWasteAnalysisForHighlights,
        wasteAnalysisForKeyOverview,
        arrMenuItemRestaurantWasteAnalysis,
        arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        selectedRestaurantService,
        selectedRestaurantWasteAnalysisForHighlights,
        selectedLocationName: updatedSelectedLocationName,
        selectedLocationWasteAnalysisForHighlights,
        selectedGroupedServiceWasteAnalysisForHighlights,
        selectedServiceGroup: updatedSelectedServiceGroup,
        wasteReductionAnalysisAndWastePerCoverForKeyOverview,
        mapPastelColorByLocationName,
        mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName,
      });
      renderLoaderAnimation(false);
    } catch (error) {
      renderLoaderAnimation(false);
      // Catch JWT web token error
      if (user.isAdmin) {
        history.push('/impersonator');
      } else {
        history.push('/login');
      }

      if (error.message === 'No Service') {
        openSnackbar(t('home.fetchWasteAnalysisForHighlightsNoServiceErrorSnackbarText'), 'error');
      } else if (error?.response?.data.error === CONSTANT.calculatorConnectionError) {
        openSnackbar(t('home.calculatorConnectionErrorSnackbarText'), 'error');
      } else {
        openSnackbar(t('home.fetchWasteAnalysisForHighlightsErrorSnackbarText'), 'error');
      }
    }
  }

  // ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
  async deleteFromWatchlist(menuItemId, watchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history, t } = this.props;
    const { arrWatchlistItemMenuItemRestaurantWasteAnalysis } = this.state;

    try {
      const token = await auth0.getAccessTokenSilently();
      const { user } = auth0;
      let userId = user.datavizUserId;
      if (user.isAdmin) {
        const { impersonatorDatavizUserId } = this.context;
        userId = impersonatorDatavizUserId;
      }
      const response = await axios.post(
        '/api/delete-menu-item-from-watchlist',
        {
          userId,
          menuItemIdToBeDeleted: menuItemId,
          watchlistItemMenuItemRestaurantWasteAnalysisContainingMenuItemToBeDeleted:
            watchlistItemMenuItemRestaurantWasteAnalysis,
          arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data } = response;
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      });
      openSnackbar(t('watchlist.deleteFromWatchlistSuccessSnackbarText'), 'success');
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(t('watchlist.deleteFromWatchlistErrorSnackbarText'), 'error');
      }
    }
  }

  /**
   * To send the menu item(s) to be added to the watchlist to the Backend for adding to the database. If the number of watchlist items is already at its maximum watchlist
   * size of 20, a snackbar will apear to inform the user and the menu item(s) will not be sent to the Backend.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.MenuItemRestaurant[]} arrMenuItemRestaurantWasteAnalysisToBeAdded - Array of menu item(s) to be added to watchlist
   */
  async addToWatchlist(arrMenuItemRestaurantWasteAnalysisToBeAdded) {
    const {
      selectedStartDate,
      selectedEndDate,
      selectedGroupBy,
      renderLoaderAnimation,
      openSnackbar,
    } = this.context;
    const { auth0, history, t } = this.props;
    const { arrMenuItemRestaurantWasteAnalysis, arrWatchlistItemMenuItemRestaurantWasteAnalysis } =
      this.state;

    if (arrWatchlistItemMenuItemRestaurantWasteAnalysis.length >= 20) {
      openSnackbar(
        t('watchlist.maximumWatchlistItemsErrorSnackbarText', {
          watchlistMaxSize: CONSTANT.watchlistMaxSize,
        }),
        'error'
      );
    } else if (arrMenuItemRestaurantWasteAnalysisToBeAdded.length === 0) {
      openSnackbar(t('watchlist.alreadyOnWatchlistErrorSnackbarText'), 'error');
    } else {
      try {
        renderLoaderAnimation(true);
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        const response = await axios.post(
          '/api/add-menu-items-to-watchlist',
          {
            userId,
            arrMenuItemRestaurantWasteAnalysisToBeAdded,
            arrWatchlistItemMenuItemRestaurantWasteAnalysis,
            arrAllMenuItemRestaurantWasteAnalysis: arrMenuItemRestaurantWasteAnalysis,
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            groupBy: selectedGroupBy,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const { data } = response;
        this.setState({
          arrWatchlistItemMenuItemRestaurantWasteAnalysis:
            data.arrWatchlistItemMenuItemRestaurantWasteAnalysis,
        });
        renderLoaderAnimation(false);
        openSnackbar(
          t('watchlist.addToWatchlistSuccessSnackbarText', {
            numberOfWatchlistItemsAdded: arrMenuItemRestaurantWasteAnalysisToBeAdded.length,
          }),
          'success'
        );
      } catch (error) {
        const { response } = error;
        // Catch JWT web token error
        if (response && response.status === 401) {
          history.push('/login');
        } else {
          openSnackbar(t('watchlist.addToWatchlistErrorSnackbarText'), 'error');
        }
      }
    }
  }

  /**
   * To send the watchlist items that have a change in rank the Backend for updating in the database. Only the watchlist items with a change in the rank will be sent to the Backend.
   * To reduce the waiting time for the updated display of watchlist items, the new array of watchlist items will be set state first, before axios.post is done.
   * ToDo: Shift this function to the child component during optimization (to consider) (Ref: #85)
   * @param {typedefs.WatchlistItemMenuItemRestaurantWasteAnalysis[]} arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis - Array of watchlist items with its menuItemRestaurantWasteAnalysis arranged according to the new order
   */
  async updateWatchlist(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) {
    const { openSnackbar } = this.context;
    const { auth0, history, t } = this.props;

    try {
      const {
        arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
        arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      } = getArrWatchlistItemMenuItemRestaurantWithUpdatedRankForFrontendAndBackend(
        arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
      );
      // This setState is done out before the axios.post because the errors in updating the ranks in the database does not affect the the Frontend
      // display and setting the state only if the update of ranks in the database is successfully delays the display of the updated ranks
      this.setState({
        arrWatchlistItemMenuItemRestaurantWasteAnalysis:
          arrWatchlistItemMenuItemRestaurantWasteAnalysisWithUpdatedRankForFrontend,
      });
      if (arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend.length !== 0) {
        const token = await auth0.getAccessTokenSilently();
        const { user } = auth0;
        let userId = user.datavizUserId;
        if (user.isAdmin) {
          const { impersonatorDatavizUserId } = this.context;
          userId = impersonatorDatavizUserId;
        }
        await axios.post(
          'api/update-ranks-for-watchlist-items',
          {
            userId,
            arrWatchlistItemMenuItemRestaurantToBeUpdated:
              arrWatchlistItemMenuItemRestaurantWithUpdatedRankForBackend,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
      }
    } catch (error) {
      const { response } = error;
      // Catch JWT web token error
      if (response && response.status === 401) {
        history.push('/login');
      } else {
        openSnackbar(t('watchlist.updateWatchlistErrorSnackbarText'), 'error');
      }
    }
  }

  render() {
    const { classes, auth0, arrRestaurantService, t } = this.props;
    const { impersonatorDatavizUserId } = this.context;
    const {
      isMenuItemMappingWarningOpened,
      menuItemMappingWarningCount,
      isWasteAnalysisForHighlightsFetched,
      arrMenuItemRestaurantWasteAnalysis,
      wasteAnalysisForKeyOverview,
      wasteReductionAnalysisAndWastePerCoverForKeyOverview,
      arrWatchlistItemMenuItemRestaurantWasteAnalysis,
      stickyWatchlistWidth,
      stickyWatchlistTransform,
      stickyWatchlistTransition,

      selectedRestaurantService,
      selectedLocationName,
      selectedLocationWasteAnalysisForHighlights,
      selectedServiceGroup,
      selectedGroupedServiceWasteAnalysisForHighlights,
      levelTabValue,
      isTooltipForRestaurantBreakdownOpened,
      isRightArrowDisabled,
      isLeftArrowDisabled,
      isWastageOverTimeLineChartLoading,
      selectedToggleValueForWeightCostForRestaurantBreakdown,
      arrSelectedMenuItemServiceWasteAnalysis,
      mapPastelColorByLocationName,
      mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName,

      isKeyOverviewPanelExpanded,
      isTooltipForKeyOverviewOpened,
      shouldDisplayKeyOverviewLineChart,
      shouldDisplayKeyOverviewBarChartForRestaurants,
    } = this.state;

    const { user } = auth0;
    const { impersonatorIsCompanyUser } = this.context;
    const isCompanyUser =
      (!user.isAdmin && user.isCompanyUser) || (user.isAdmin && impersonatorIsCompanyUser);

    let arrAllServiceTypeAndArrSelectedServiceType = {
      arrAllServiceType: [],
      arrSelectedServiceType: [],
    };
    const parentNameKey = isCompanyUser ? selectedRestaurantService?.name : selectedLocationName;
    const nameKey = isCompanyUser ? selectedLocationName : selectedServiceGroup;
    if (mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName.has(parentNameKey)) {
      arrAllServiceTypeAndArrSelectedServiceType =
        mapMapArrAllServiceTypeAndArrSelectedServiceTypeByNameByParentName
          .get(parentNameKey)
          .get(nameKey);
    }

    return (
      <Box>
        {isWasteAnalysisForHighlightsFetched && (
          <>
            {isMenuItemMappingWarningOpened && menuItemMappingWarningCount && (
              <Grid
                container
                className={classes.rootGridContainer}
                style={{ paddingTop: 0, paddingBottom: 0 }}
              >
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    onClose={() => this.setState({ isMenuItemMappingWarningOpened: false })}
                    className={classes.menuItemMappingWarning}
                  >
                    <AlertTitle>
                      <strong>{t('menuItemMappingWarning.header')}</strong>
                    </AlertTitle>
                    <Trans
                      i18nKey="menuItemMappingWarning.text"
                      values={{ menuItemMappingWarningCount }}
                    />{' '}
                    <Link to="/menu-item-mapping" style={{ color: 'inherit', fontWeight: 'bold' }}>
                      {t('menuItemMappingWarning.link')}
                    </Link>
                  </Alert>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              className={classes.rootGridContainer}
              justifyContent={
                impersonatorDatavizUserId !== -1 && !isCompanyUser ? 'flex-start' : 'center'
              }
            >
              {/* Key Overview */}
              <Grid
                item
                xs={12}
                md={7}
                className={`${classes.rootGridItem} ${classes.leftColumnContainer}`}
              >
                <KeyOverview
                  wasteAnalysisForKeyOverview={wasteAnalysisForKeyOverview}
                  wasteReductionAnalysisAndWastePerCoverForKeyOverview={
                    wasteReductionAnalysisAndWastePerCoverForKeyOverview
                  }
                  onCloseTooltip={() => this.onCloseKeyOverviewTooltip()}
                  onClickAwayTooltip={() => this.onClickAwayKeyOverviewTooltip()}
                  onClickInfoIcon={(event) => this.onClickKeyOverviewInfoIcon(event)}
                  onChangeExpansionPanel={() => this.onChangeKeyOverviewExpansionPanel()}
                  isExpanded={isKeyOverviewPanelExpanded}
                  isTooltipOpened={isTooltipForKeyOverviewOpened}
                  shouldDisplayLineChart={shouldDisplayKeyOverviewLineChart}
                  shouldDisplayBarChartForRestaurants={
                    shouldDisplayKeyOverviewBarChartForRestaurants
                  }
                  shouldShowMore={
                    shouldDisplayKeyOverviewLineChart ||
                    shouldDisplayKeyOverviewBarChartForRestaurants
                  }
                  isCompanyUser={isCompanyUser}
                />
              </Grid>

              {/* Watchlist */}
              {impersonatorDatavizUserId !== -1 && !isCompanyUser && (
                <Grid
                  item
                  xs={12}
                  md={5}
                  className={`${classes.rootGridItem} ${classes.rightColumnContainer}`}
                  ref={this.rightColumnContainerRef}
                >
                  <Box
                    className={classes.stickyWatchlist}
                    style={{
                      width: stickyWatchlistWidth,
                      transform: stickyWatchlistTransform,
                      transition: stickyWatchlistTransition,
                    }}
                  >
                    <Watchlist
                      arrWatchlistItemMenuItemRestaurantWasteAnalysis={
                        arrWatchlistItemMenuItemRestaurantWasteAnalysis
                      }
                      arrMenuItemRestaurantWasteAnalysis={arrMenuItemRestaurantWasteAnalysis}
                      deleteFromWatchlist={(
                        menuItemId,
                        watchlistItemMenuItemRestaurantWasteAnalysis
                      ) =>
                        this.deleteFromWatchlist(
                          menuItemId,
                          watchlistItemMenuItemRestaurantWasteAnalysis
                        )
                      }
                      addToWatchlist={(arrMenuItemRestaurantWasteAnalysisToBeAdded) =>
                        this.addToWatchlist(arrMenuItemRestaurantWasteAnalysisToBeAdded)
                      }
                      updateWatchlist={(arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis) =>
                        this.updateWatchlist(
                          arrReorderedWatchlistItemMenuItemRestaurantWasteAnalysis
                        )
                      }
                    />
                  </Box>
                </Grid>
              )}

              {/* Restaurant Breakdown */}
              <Grid
                item
                xs={12}
                md={7}
                className={`${classes.rootGridItem} ${classes.leftColumnContainer}`}
              >
                <RestaurantBreakdown
                  arrRestaurantService={arrRestaurantService}
                  arrDropdownItem={this.getArrDropdownItem(isCompanyUser)}
                  selectedRestaurantName={
                    selectedRestaurantService ? selectedRestaurantService.name : ''
                  }
                  selectedLocationName={selectedLocationName}
                  selectedLocationWasteAnalysisForHighlights={
                    selectedLocationWasteAnalysisForHighlights
                  }
                  selectedServiceGroup={selectedServiceGroup}
                  selectedGroupedServiceWasteAnalysisForHighlights={
                    selectedGroupedServiceWasteAnalysisForHighlights
                  }
                  arrSelectedMenuItemServiceWasteAnalysis={arrSelectedMenuItemServiceWasteAnalysis}
                  tabValue={levelTabValue}
                  isRightArrowDisabled={isRightArrowDisabled}
                  isLeftArrowDisabled={isLeftArrowDisabled}
                  isTooltipOpened={isTooltipForRestaurantBreakdownOpened}
                  isWastageOverTimeLineChartLoading={isWastageOverTimeLineChartLoading}
                  selectedToggleValueForWeightCost={
                    selectedToggleValueForWeightCostForRestaurantBreakdown
                  }
                  mapPastelColorByLocationName={mapPastelColorByLocationName}
                  arrAllServiceType={arrAllServiceTypeAndArrSelectedServiceType.arrAllServiceType}
                  arrSelectedServiceType={
                    arrAllServiceTypeAndArrSelectedServiceType.arrSelectedServiceType
                  }
                  onChangeDropdownList={(event) => this.onChangeDropdownList(event, isCompanyUser)}
                  onChangeTabList={(event, newValue) =>
                    this.onChangeTabList(event, newValue, isCompanyUser)
                  }
                  onChangeToggleWeightCost={(event, newSelectedToggleValue) =>
                    this.onChangeToggleWeightCostButtonForRestaurantBreakdown(
                      event,
                      newSelectedToggleValue
                    )
                  }
                  onChangeServiceType={this.onChangeServiceType}
                  onClickAdd={() => this.onClickAddMenuItemToWatchlist()}
                  onClickAwayTooltip={() => this.onClickAwayRestaurantBreakdownTooltip()}
                  onClickClearSelection={() => this.onClickClearSelectionButton()}
                  onClickInfoIcon={() => this.onClickRestaurantBreakdownInfoIcon()}
                  onClickLeftArrowIcon={() => this.onClickLeftArrowIconButton(isCompanyUser)}
                  onClickRightArrowIcon={() => this.onClickRightArrowIconButton(isCompanyUser)}
                  onCloseTooltip={() => this.onCloseRestaurantBreakdownTooltip()}
                  updateArrSelectedMenuItemServiceWasteAnalysis={(
                    arrNewlySelectedMenuItemServiceWasteAnalysis
                  ) =>
                    this.updateArrSelectedMenuItemServiceWasteAnalysis(
                      arrNewlySelectedMenuItemServiceWasteAnalysis
                    )
                  }
                  updateSelectedServiceTypes={(setUpdatedSelectedServiceTypeId) =>
                    this.updateSelectedServiceTypes(setUpdatedSelectedServiceTypeId)
                  }
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    );
  }
}

Home.contextType = AppContext;

export default withTranslation()(withRouter(withAuth0(withTheme(withStyles(styles)(Home)))));
